/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { callGetUser } from '../api/kerb-construction';
import useHandleErrorResponse from '../hooks/useHandleErrorResponse';
import {
  getJWTTokenDecoded,
  getCookie,
  getCurrentUserIdFromStore,
} from '../utils/functions';
import LoadingCircle from '../shared/LoadingCircle';

// a component that checks if the current user belongs to an organisation,
// and redirects them to adding an organisation if not. Otherwise, renders children.
const CurrentUserContainer = ({ children }) => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const dispatch = useDispatch();
  const organisationDetailsDataExists = !!currentUserStore.organisation.id;

  const [
    currentUserOrganisationDetailsLoaded,
    setCurrentUserOrganisationDetailsLoaded,
  ] = useState(organisationDetailsDataExists);

  const history = useHistory();
  const handleError = useHandleErrorResponse();

  useEffect(() => {
    if (!organisationDetailsDataExists) {
      const decodedJWTToken = getJWTTokenDecoded(
        getCookie(`idToken:${getCurrentUserIdFromStore()}`)
      );
      setCurrentUserOrganisationDetailsLoaded(false);

      callGetUser(decodedJWTToken.sub)
        .then((response) => {
          // bypass error if error response is 403 (access denied )
          if (!response.ok && response.status !== 403) {
            throw response.json();
          }
          return response.json();
        })
        .then((userData) => {
          const userExists = !!userData.organisation;
          if (!userExists) {
            // route user to add organisation if they dont belong to one
            history.push('/addorganisation');
          } else {
            dispatch({
              type: 'SET_CURRENT_USER_ORGANISATION_DETAILS',
              payload: userData.organisation,
            });
            dispatch({
              type: 'SET_CURRENT_USER_PERMISSIONS',
              payload: userData.permissions,
            });
            dispatch({
              type: 'SET_CURRENT_USER_STATUS',
              payload: 'USER_ORGANISATION_ADDED',
            });
            if (userData.currentUserType === 'Haulier-Admin') {
              dispatch({
                type: 'SET_CURRENT_USER_IS_HAULIER',
              });
            }
          }
          setCurrentUserOrganisationDetailsLoaded(true);
        })

        .catch((err) => handleError(err));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return currentUserOrganisationDetailsLoaded ? (
    children
  ) : (
    <main>
      <LoadingCircle />
    </main>
  );
};

export default CurrentUserContainer;
