import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import BookingsIcon from '../../../images/metro-truck.svg';
import RecurringIcon from '../../../images/metro-truck-recurring.svg';

const BookingDetailsTypeForm = () => {
  const viewStore = useSelector((state) => state.viewStore);
  const dispatch = useDispatch();

  function handleBookingTypeSelect(newBookingType) {
    const { subRouteProgress } = viewStore;

    dispatch({
      type: 'SET_NEW_BOOKING_TYPE',
      payload: newBookingType,
    });

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: subRouteProgress + 1,
    });
  }

  return (
    <div className="container">
      <Typography
        className="u-text-center"
        variant="h5"
        component="h4"
        gutterBottom
      >
        Please select the type of booking you would like to book
      </Typography>

      <div className="add-booking-view__booking-type-button-container">
        <button
          type="button"
          className="button --square-image-button"
          onClick={() => {
            handleBookingTypeSelect('single-booking');
          }}
        >
          One-Time Booking
          <img src={BookingsIcon} alt="One-Time Booking" />
        </button>

        <button
          type="button"
          className="button --square-image-button"
          onClick={() => {
            handleBookingTypeSelect('recurring-booking');
          }}
        >
          Recurring Booking
          <img src={RecurringIcon} alt="Recurring Booking" />
        </button>
      </div>
    </div>
  );
};

export default BookingDetailsTypeForm;
