import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  getAlphabeticallySortedArray,
  getUniqueAvailableUsers,
} from '../../../utils/functions';
import AvailableTableRow from './AvailableTableRow';
import { callGetUsers } from '../../../api/kerb-construction';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import SearchForm from '../../../shared/SearchForm';

const AvailableTable = ({
  connectedUsers,
  selectedUserType,
  setNewUserConnected,
  newUserConnected,
}) => {
  const [searchString, setSearchString] = useState('');
  const [searchedUsers, setSearchedUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const handleErrorResponse = useHandleErrorResponse();

  function handleGetUsers() {
    callGetUsers()
      .then((res) => {
        if (!res.ok) {
          throw res.json();
        }
        return res.json();
      })
      .then((res) => {
        setResponse(res);
        setSearchedUsers(res);
        setLoading(false);
        setSearchString('');
      })
      .catch((err) => handleErrorResponse(err));
  }

  function handleSearch(ev) {
    const searchValue = ev.currentTarget.value;
    setSearchString(searchValue);
  }

  function getAvailableUsers(users) {
    const userTypeFilteredUsers = users.filter(
      (user) => user.permissions === selectedUserType
    );
    const uniqueUsers = getUniqueAvailableUsers(
      userTypeFilteredUsers,
      connectedUsers
    );
    return getAlphabeticallySortedArray(uniqueUsers, 'name');
  }

  useEffect(() => {
    // re-call getUsers once newUserConnected set to true
    if (newUserConnected) handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserConnected]);

  useEffect(() => {
    // initial call on component load
    handleGetUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // handle search
    if (searchString.length > 0) {
      const searchResults = response.filter((user) => {
        return user.name.toLowerCase().includes(searchString.toLowerCase());
      });
      setSearchedUsers(searchResults);
    } else {
      setSearchedUsers(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return !loading && connectedUsers ? (
    <>
      <SearchForm
        value={searchString}
        onChange={(ev) => handleSearch(ev)}
        label="Search"
      />
      <TableContainer className="mui-table">
        <Table aria-label="Available users table">
          <TableHead>
            <TableRow>
              <TableCell>Organisation Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAvailableUsers(searchedUsers).map((user) => {
              return (
                <AvailableTableRow
                  setNewUserConnected={setNewUserConnected}
                  key={user.id}
                  user={user}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <LinearProgress />
  );
};

export default AvailableTable;
