import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LoadingCircle from '../../../shared/LoadingCircle';
import { callSiteUserRequest } from '../../../api/kerb-construction';
import AvailableSitesDropDown from '../../../shared/AvailableSitesDropDown';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';

const UserSiteForm = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [siteIsSelected, setSiteIsSelected] = useState(false);
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const addUserStore = useSelector((state) => state.addUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const dispatch = useDispatch();
  const selectedSiteDetails = selectedSiteStore;
  const handleErrorResponse = useHandleErrorResponse();
  const { subRouteProgress } = viewStore;

  function handleAddUserToSite() {
    const newUserId = addUserStore.newUserDetails.id;
    const siteId = selectedSiteDetails.id;

    setFormSubmitting(true);
    callSiteUserRequest(newUserId, siteId)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then(() => {
        dispatch({
          type: 'SET_SUB_ROUTE_PROGRESS',
          payload: subRouteProgress + 1,
        });
      })
      .catch((err) => {
        handleErrorResponse(err);
        setFormSubmitting(false);
      });
  }
  function handleSkip() {
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: subRouteProgress + 1,
    });
  }

  useEffect(() => {
    const selectedSiteExists = !!selectedSiteDetails.id;
    setSiteIsSelected(selectedSiteExists);
  }, [selectedSiteDetails, siteIsSelected]);

  return (
    <form className="form" noValidate autoComplete="off">
      <div className="form__form-inner">
        <Typography variant="h5" component="h4" gutterBottom>
          Would you like to select a specific Project or Site to add a user to?
        </Typography>
        <AvailableSitesDropDown />
        {formSubmitting ? (
          <LoadingCircle />
        ) : (
          <div className="manage-user-view__two-button-container">
            <Button
              className="cancel-button"
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSkip}
            >
              Skip
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!siteIsSelected}
              onClick={handleAddUserToSite}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    </form>
  );
};

export default UserSiteForm;
