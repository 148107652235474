import { useEffect, useState } from 'react';
import { isMobileWidth } from '../utils/functions';

const useIsMobileDevice = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(isMobileWidth());

  useEffect(() => {
    function handleResize() {
      setIsMobileDevice(isMobileWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobileDevice;
};

export default useIsMobileDevice;
