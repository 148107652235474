import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import {
  callGetHauliers,
  callGetSiteHauliers,
} from '../../../api/kerb-construction';
import useCallApi from '../../../hooks/useCallApi';
import ConnectedHauliersTable from '../tables/ConnectedHauliersTable';
import AvailableHauliersTable from '../tables/AvailableHauliersTable';
import ConnectHaulierHeaderButton from '../components/ConnectHaulierHeaderButton';

const HauliersView = ({
  setSelectedHaulier,
  refreshHauliersToggle,
  refreshHauliersList,
  hasChangeOccurred,
}) => {
  const dispatch = useDispatch();
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const selectedSiteId = selectedSiteStore.id;

  useEffect(() => {
    dispatch({
      type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
      payload: hasChangeOccurred ? ConnectHaulierHeaderButton : null,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChangeOccurred]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
        payload: null,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hauliersLoading, hauliers] = useCallApi(
    callGetHauliers,
    [selectedSiteId],
    {
      dependencies: [refreshHauliersToggle],
    }
  );

  const [connectedHauliersLoading, connectedHauliers] = useCallApi(
    callGetSiteHauliers,
    ['site_id', selectedSiteId],
    {
      dependencies: [refreshHauliersToggle],
    }
  );

  function getAvailableHauliers(hauliersArray) {
    if (!hauliersArray || !connectedHauliers) {
      return [];
    }
    return hauliersArray.filter(
      (haulier) =>
        connectedHauliers.findIndex((connectedHaulier) => {
          return haulier.id === connectedHaulier.haulier_id;
        }) < 0
    );
  }

  return (
    <main className="connect-haulier">
      <div className="container">
        <Typography
          variant="h6"
          component="h6"
          gutterBottom
          className="u-highlight-title "
        >
          Please select a Haulier that you would like to connect to{' '}
          {selectedSiteStore.name}
        </Typography>
        <ConnectedHauliersTable
          connectedHauliers={connectedHauliers}
          connectedHauliersLoaded={!connectedHauliersLoading}
          setSelectedHaulier={setSelectedHaulier}
        />
        <AvailableHauliersTable
          availableHauliers={getAvailableHauliers(hauliers)}
          availableHauliersLoaded={
            !hauliersLoading && !connectedHauliersLoading
          }
          refreshHauliersList={refreshHauliersList}
        />
      </div>
    </main>
  );
};

export default HauliersView;
