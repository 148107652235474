import React from 'react';
import { useDispatch } from 'react-redux';

const HeaderConfirmButton = () => {
  const dispatch = useDispatch();

  const handleButtonPress = () => {
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
    dispatch({
      type: 'RESET_PATCH_BOOKING_PARAM',
    });
    dispatch({
      type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
      payload: null,
    });
    dispatch({
      type: 'SET_EDIT_BOOKING_ASSIGNMENT_TYPE',
      payload: null,
    });
    dispatch({
      type: 'RESET_PATCH_BOOKING_PARAM',
    });
    dispatch({
      type: 'SET_CONFIRM_BOOKING_ASSIGNMENT_CHANGES',
      payload: true,
    });
  };

  return (
    <button
      type="button"
      className="button main-header__button-confirm --success"
      onClick={handleButtonPress}
    >
      Save Changes
    </button>
  );
};

export default HeaderConfirmButton;
