import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';
import { getAlphabeticallySortedArray } from '../../../utils/functions';
import ConnectedTableRow from './ConnectedTableRow';
import { callGetSiteUsers } from '../../../api/kerb-construction';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import SearchForm from '../../../shared/SearchForm';

function ConnectedTable({
  newUserConnected,
  setNewUserConnected,
  setConnectedUsers,
  selectedUserType,
}) {
  const [searchString, setSearchString] = useState('');
  const [searchedUsers, setSearchedUsers] = useState(null);
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const siteId = selectedSiteStore.id;
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const handleErrorResponse = useHandleErrorResponse();

  function handleGetSiteUsers() {
    callGetSiteUsers(siteId)
      .then((res) => {
        if (!res.ok) {
          throw res.json();
        }
        return res.json();
      })
      .then((res) => {
        setResponse(res);
        setLoading(false);
        setNewUserConnected(false);
        setSearchString('');
      })
      .catch((err) => handleErrorResponse(err));
  }

  function getUsersFilteredByType(users) {
    return users.filter((user) => user.user_group === selectedUserType);
  }

  function handleSearch(ev) {
    const searchValue = ev.currentTarget.value;
    setSearchString(searchValue);
  }

  useEffect(() => {
    // set connected users for Available table after response returned
    if (response) {
      setConnectedUsers(response);
      setSearchedUsers(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    // re-call getSiteUsers once newUserConnected set to true
    if (newUserConnected) handleGetSiteUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserConnected]);

  useEffect(() => {
    // initial call on component load
    handleGetSiteUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // handle search form
    if (searchString.length > 0) {
      const searchResults = response.filter((user) => {
        return user.user_name
          .toLowerCase()
          .includes(searchString.toLowerCase());
      });
      setSearchedUsers(searchResults);
    } else {
      setSearchedUsers(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return !loading ? (
    <>
      <SearchForm
        value={searchString}
        onChange={(ev) => handleSearch(ev)}
        label="Search"
      />
      <TableContainer className="mui-table">
        <Table
          className="--table-without-buttons"
          aria-label="Connected users table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAlphabeticallySortedArray(
              getUsersFilteredByType(searchedUsers),
              'user_name'
            ).map((user) => {
              return <ConnectedTableRow key={user.id} user={user} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) : (
    <LinearProgress />
  );
}

export default ConnectedTable;
