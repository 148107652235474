const initialState = {
  bookingModalOpen: false,
  cardModalData: {},
  newNoteData: {},
};

const bookingModalStore = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MODAL_OPEN':
      return {
        ...state,
        bookingModalOpen: true,
      };
    case 'SET_MODAL_DATA':
      return {
        ...state,
        cardModalData: action.payload,
      };
    case 'SET_MODAL_CLOSED':
      return {
        ...state,
        bookingModalOpen: false,
        newNoteData: {},
      };
    case 'SET_NEW_NOTE_MESSAGE':
      return {
        ...state,
        newNoteData: action.payload,
      };
    case 'CLEAR_NEW_NOTE_MESSAGE':
      return {
        ...state,
        newNoteData: {},
      };
    default:
      return state;
  }
};

export default bookingModalStore;
