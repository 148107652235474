import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPreviousSubRouteIndex } from '../utils/functions';

const useBackButtonHandler = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const viewStore = useSelector((state) => state.viewStore);

  function redirectToPreviousPage() {
    const { subRouteProgress, subRouteData } = viewStore;
    // check to see if route is within subroute, otherwise use browser history to go back.
    if (subRouteProgress > 0) {
      const newSubRouteIndex = getPreviousSubRouteIndex(
        subRouteProgress,
        subRouteData
      );

      dispatch({
        type: 'SET_SUB_ROUTE_PROGRESS',
        payload: newSubRouteIndex,
      });
    } else {
      history.goBack();
    }
  }

  return redirectToPreviousPage;
};

export default useBackButtonHandler;
