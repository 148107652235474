import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import LoginHelperButtons from '../components/LoginHelperButtons';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import LoadingCircle from '../../../shared/LoadingCircle';
import inputRefConfig from '../../../shared/inputRef.config';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';

function LoginForm({ formSubmitting, onSubmit }) {
  const [formValidated, setFormValidated] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm({
    mode: 'onTouched',
  });

  const username = useRef({});
  const password = useRef({});
  username.current = watch('username', '');
  password.current = watch('password', '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // use password.current.length to determine that the last field has been filled.
    const errorsLength = Object.keys(errors).length;

    if (password.current.length > 8 && errorsLength === 0) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h5" component="h4" gutterBottom>
            Please enter your login details
          </Typography>
          <TextField
            autoFocus
            inputRef={register({ ...inputRefConfig.emailInput })}
            name="username"
            label="name@example.com"
            type="email"
            variant="outlined"
          />
          <FormErrorMessage errors={errors.username} />

          <TextField
            inputRef={register({})}
            name="password"
            label="Password"
            type="password"
            variant="outlined"
          />
          <FormErrorMessage errors={errors.password} />
          <LoginHelperButtons />
          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
}

export default LoginForm;
