import React, { useState } from 'react';
import ConfirmationButtons from './ConfirmationButtons';

const NameConfirmationField = ({ data }) => {
  const [editState, setEditState] = useState(false);

  return (
    <div className="add-organisation-view__confirmation-fieldset">
      <span>Organisation Name:</span>
      <input
        data-input-name
        type="text"
        maxLength="20"
        placeholder="organisation_name"
        defaultValue={data}
        className="add-organisation-view__confirmation-input"
        disabled={!editState}
      />
      <ConfirmationButtons editState={editState} setEditState={setEditState} />
    </div>
  );
};
export default NameConfirmationField;
