import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { add, isAfter } from 'date-fns';
import useRefreshAmplifySession from '../hooks/useRefreshAmplifySession';

// component to call auth.currentSession() and update session and idToken used by API calls at selected interval time.
const AmplifyRefreshTimer = () => {
  const dateNow = new Date();
  const refreshIntervalInMinutes = 5;
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { isHaulier } = currentUserStore;
  const [refreshAmplifySession] = useRefreshAmplifySession();

  useEffect(() => {
    let amplifyRefreshIntervalTimer = null;
    let refreshTime = add(dateNow, {
      minutes: refreshIntervalInMinutes,
    });

    amplifyRefreshIntervalTimer = setInterval(() => {
      const timeNow = new Date();
      if (isAfter(timeNow, refreshTime)) {
        refreshAmplifySession(isHaulier);
        refreshTime = add(new Date(), {
          minutes: refreshIntervalInMinutes,
        });
      }
    }, 1000);

    return () => {
      clearInterval(amplifyRefreshIntervalTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default AmplifyRefreshTimer;
