import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import penImage from '../../../images/pen.svg';
import Close from '../../../images/Components/Close';

const UserTableRow = ({ individualUserData, tableDataProperties }) => {
  const dispatch = useDispatch();

  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setShowDropdownOptions((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setShowDropdownOptions(false);
  };

  const selectUserForDelete = (userData) => {
    dispatch({
      type: 'SET_SELECTED_USER_DETAILS',
      payload: userData,
    });

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 2,
    });
  };

  const prevOpen = useRef(showDropdownOptions);

  useEffect(() => {
    if (
      anchorRef.current &&
      prevOpen.current === true &&
      showDropdownOptions === false
    ) {
      anchorRef.current.focus();
    }

    prevOpen.current = showDropdownOptions;
  }, [showDropdownOptions]);

  return (
    <TableRow
      className="edit-row edit-user-row manage-users__table-row"
      key={individualUserData.id}
    >
      <TableCell>{individualUserData[tableDataProperties[0]]}</TableCell>
      <TableCell className="manage-users__overflowing-table-cell">
        {individualUserData[tableDataProperties[1]]}
      </TableCell>
      <TableCell className="cell-with-button__value-beside-button">
        <div className="cell-with-button__value-beside-button">
          Connected
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              {showDropdownOptions ? (
                <>
                  <button
                    type="button"
                    ref={anchorRef}
                    aria-controls={
                      showDropdownOptions ? 'menu-list-grow' : undefined
                    }
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className="button button-edit-row edit-user-row --unselect"
                  >
                    <Close />
                  </button>
                  <div className="dropdown-menu --manage-users">
                    <button
                      type="button"
                      className="button dropdown-option"
                      onClick={() => {
                        selectUserForDelete(individualUserData);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </>
              ) : (
                <button
                  type="button"
                  className="button button-edit-row edit-user-row"
                  onClick={handleToggle}
                >
                  <img src={penImage} alt="edit booking" />
                </button>
              )}
            </div>
          </ClickAwayListener>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default UserTableRow;
