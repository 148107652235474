import React from 'react';
import SubRouteHandler from '../../router/SubRouteHandler';
import UserTypeForm from './forms/UserTypeForm';
import UserDetailsForm from './forms/UserDetailsForm';
import UserSiteForm from './forms/UserSiteForm';
import UserSuccessForm from './forms/UserSuccessForm';
import UserConfirmDeleteForm from './forms/UserConfirmDeleteForm';
import ManageUsersContainer from './components/ManageUsersContainer';

function ManageUserView() {
  const manageUsersProgressRoutes = [
    { component: UserTypeForm },
    { component: ManageUsersContainer },
    { component: UserConfirmDeleteForm, props: { bypassPrevious: true } },
    { component: UserDetailsForm },
    { component: UserSiteForm, props: { bypassPrevious: true } },
    { component: UserSuccessForm },
  ];

  return (
    <main className="manage-user-view">
      <SubRouteHandler subroutes={manageUsersProgressRoutes} />
    </main>
  );
}

export default ManageUserView;
