import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { callGetSites } from '../api/kerb-construction';
import LoadingCircle from './LoadingCircle';
import useCallApi from '../hooks/useCallApi';

const AvailableSitesDropDown = () => {
  const dispatch = useDispatch();
  const [selectedSiteId, setSelectedSiteId] = useState('none');
  const [loading, response] = useCallApi(callGetSites);

  const handleSiteSelect = (ev) => {
    const dropdownSiteId = ev.target.value;

    if (dropdownSiteId !== 'none') {
      const filteredSiteDetails = response.filter(
        (site) => site.id === dropdownSiteId
      )[0];

      setSelectedSiteId(dropdownSiteId);
      dispatch({
        type: 'SET_SELECTED_SITE_DETAILS',
        payload: filteredSiteDetails,
      });
    } else {
      setSelectedSiteId('none');
      dispatch({
        type: 'RESET_SELECTED_SITE_DETAILS',
      });
    }
  };

  useEffect(() => {
    setSelectedSiteId('none');
    dispatch({
      type: 'RESET_SELECTED_SITE_DETAILS',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ? (
    <div className="form__form-inner">
      <FormControl variant="outlined">
        <Select
          id="dropdown"
          value={selectedSiteId}
          onChange={handleSiteSelect}
        >
          <MenuItem key="none" value="none">
            No Site Selected
          </MenuItem>
          {response.map((site) => {
            return (
              <MenuItem key={site.id} value={site.id}>
                {site.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  ) : (
    <LoadingCircle />
  );
};

export default AvailableSitesDropDown;
