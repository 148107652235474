import React from 'react';

const MetroTruck = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.615"
      height="20"
      viewBox="0 0 24.615 20"
      className={className}
      alt="Single Booking"
    >
      <path
        id="Icon_metro-truck"
        data-name="Icon metro-truck"
        d="M27.186,16.553,24.109,10.4H19.494V7.323a1.543,1.543,0,0,0-1.538-1.538H4.109A1.543,1.543,0,0,0,2.571,7.323V19.63l1.538,1.538H6.06a3.077,3.077,0,1,0,5.329,0h8.518a3.077,3.077,0,1,0,5.328,0h1.951V16.553Zm-7.692,0V11.938h3.189l2.308,4.615h-5.5Z"
        transform="translate(-2.571 -5.784)"
      />
    </svg>
  );
};
export default MetroTruck;
