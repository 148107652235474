import React from 'react';
import { Typography } from '@material-ui/core';
import HaulierSitesTable from './tables/HaulierSitesTable';

function HaulierSitesView() {
  return (
    <main className="table-view">
      <div className="container">
        <Typography
          variant="h6"
          component="h6"
          gutterBottom
          className="u-highlight-title"
        >
          Please select the Site that you would like to manage the bookings for:
        </Typography>
        <div className="hr" />
        <HaulierSitesTable />
      </div>
    </main>
  );
}

export default HaulierSitesView;
