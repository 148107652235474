import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfirmationCodeForm from './forms/ConfirmationCodeForm';
import { callConfirmSignup, callForgotPasswordSubmit } from '../../api/auth';
import useHandleErrorResponse from '../../hooks/useHandleErrorResponse';

const ConfirmationCodeView = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const { userResettingPassword, tempPassword } = currentUserStore;
  const handleErrorResponse = useHandleErrorResponse();
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = () => {
    const { username } = currentUserStore.details;
    const { confirmationCode } = viewStore;

    setFormSubmitting(true);

    const confirmationCall = userResettingPassword
      ? callForgotPasswordSubmit(username, confirmationCode, tempPassword)
      : callConfirmSignup(username, confirmationCode);

    confirmationCall
      .then(() => {
        dispatch({
          type: 'SET_ALERT',
          payload: userResettingPassword
            ? 'Password Reset, please sign in'
            : 'Account Created! Please sign in.',
        });

        history.push('/login');
      })
      .catch((err) => {
        handleErrorResponse(err);
        setFormSubmitting(false);
      });
  };
  useEffect(() => {
    dispatch({
      type: 'SET_CONFIRMATION_CODE',
      payload: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main className="confirmation-code-view">
      <ConfirmationCodeForm
        onSubmit={onSubmit}
        formSubmitting={formSubmitting}
      />
    </main>
  );
};
export default ConfirmationCodeView;
