import React from 'react';
import { KerbErrorPage } from '@gridsmartercities/kerb-components-web';
import BackgroundImage from './backgroundImage.svg';

const ErrorPageView = () => {
  return (
    <main className="--mobile-full-page">
      <KerbErrorPage backgroundImage={BackgroundImage} />
    </main>
  );
};
export default ErrorPageView;
