import React from 'react';
import { useDispatch } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const ConnectedHauliersTableRow = ({ haulier, setSelectedHaulier }) => {
  const dispatch = useDispatch();

  const progressToConfirmDelete = () => {
    setSelectedHaulier(haulier);

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 1,
    });
  };

  return (
    <TableRow key={haulier.haulier_id}>
      <TableCell>{haulier.haulier_name}</TableCell>
      <TableCell>{haulier.haulier_address.postcode}</TableCell>
      <TableCell className="cell-with-button">
        <button
          type="button"
          onClick={progressToConfirmDelete}
          className="button button__haulier-select --warning"
        >
          Remove
        </button>
      </TableCell>
    </TableRow>
  );
};

export default ConnectedHauliersTableRow;
