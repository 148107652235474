import React from 'react';

const FooterButton = ({ Button }) => {
  return (
    <div className="footer-button-container">
      <Button />
    </div>
  );
};

export default FooterButton;
