import React from 'react';
import {
  getHHMMFormattedDateFromTimeString,
  getBookingSlotStringFromDueStartAndDueEnd,
  isBookingLate,
} from '../../../../utils/timeFunctions';
import LateCue from '../../../../images/Components/LateCue';
import FirstDestination from '../../../../images/Components/FirstDestination';

const BookingCardStatus = ({ cardData }) => {
  const dateModified = getHHMMFormattedDateFromTimeString(
    cardData.date_modified
  );
  const recordCreated = cardData.date_created;
  const recordUpdated = cardData.date_modified;
  const inTransit = cardData.in_transit;
  const isComplete = cardData.completed;
  const firstDestination = cardData.first_destination;
  const isLate = isBookingLate(cardData.due_end);
  const { type, status, recurrent, rejected } = cardData;

  const getStatusPrefix = () => {
    if (!isComplete) {
      if (recordCreated === recordUpdated) {
        return 'Registered at: ';
      }
      if (type === 'PLANNED') {
        if (firstDestination === status && inTransit) {
          return 'Departed at: ';
        }
        return inTransit ? 'Requested at: ' : 'Arrived at: ';
      }
      return 'Sent at: ';
    }

    if (rejected) {
      return 'Rejected at: ';
    }

    return 'Completed at: ';
  };

  const getFullStatus = () => {
    if (cardData.type === 'PLANNED' && !recurrent && status === 'new') {
      const bookingSlot = getBookingSlotStringFromDueStartAndDueEnd(
        cardData.due_start,
        cardData.due_end
      );

      return (
        <>
          <div className="booking-card__booking-slot">
            {`Slot: `}
            <span className="booking-card__booking-slot">{bookingSlot}</span>
          </div>
        </>
      );
    }

    if (status !== 'new') {
      return `${getStatusPrefix()} ${dateModified}`;
    }

    return null;
  };

  return (
    <>
      <div
        className={`booking-card__status${
          isLate && !isComplete ? '--late' : ''
        }`}
      >
        <span>{getFullStatus()}</span>
        {isLate && !isComplete && (
          <LateCue className="booking-card__late-cue" />
        )}
      </div>

      {status === 'new' && (
        <div className="booking-card__first-destination">
          <FirstDestination className="booking-card__first-destination-icon" />
          {firstDestination}
        </div>
      )}
    </>
  );
};
export default BookingCardStatus;
