/* eslint-disable camelcase */
import { getCookie, getCurrentUserIdFromStore } from '../utils/functions';

const apiUrl = process.env.REACT_APP_REST_URL;

export function callCreateRegistration(data) {
  return fetch(`${apiUrl}/registration`, {
    method: 'POST',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      ...data,
    }),
  });
}

export function callAddUser(
  userName,
  userType,
  temporaryPassword,
  email,
  number
) {
  return fetch(`${apiUrl}/users`, {
    method: 'POST',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      user_name: userName,
      user_group: userType,
      temporary_password: temporaryPassword,
      email,
      phone: number,
    }),
  });
}

export function callGetUser(userId) {
  return fetch(`${apiUrl}/users/${userId}`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callGetSites() {
  return fetch(`${apiUrl}/sites`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callGetHauliers() {
  return fetch(`${apiUrl}/hauliers`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callGetBookings(site_id) {
  return fetch(`${apiUrl}/delivery_records?site_id=${site_id}`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}
export function callGetUnassignedBookings(date) {
  return fetch(`${apiUrl}/delivery_records?date=${date}&assigned=FALSE`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callGetBookingsByDate(date, siteID) {
  return fetch(`${apiUrl}/delivery_records?date=${date}&site_id=${siteID}`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callPostBookingNote(bookingID, note) {
  return fetch(`${apiUrl}/delivery_records/${bookingID}/notes`, {
    method: 'POST',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content: note,
    }),
  });
}

export function callGetBookingNotes(bookingID) {
  return fetch(`${apiUrl}/delivery_records/${bookingID}/notes`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}
export function callGetBookingActivityLog(bookingID) {
  return fetch(`${apiUrl}/delivery_records/${bookingID}/logs`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callGetVehicles() {
  return fetch(`${apiUrl}/vehicles`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callDeleteVehiclesById(vehicleID) {
  return fetch(`${apiUrl}/vehicles/${vehicleID}`, {
    method: 'DELETE',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callGetBookingVehicleData(dueStart, dueEnd) {
  return fetch(
    `${apiUrl}/vehicles?due_start=${encodeURIComponent(
      dueStart
    )}&due_end=${encodeURIComponent(dueEnd)}`,
    {
      method: 'GET',
      headers: {
        Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
        'Content-Type': 'application/json',
      },
    }
  );
}

export function callGetUsers() {
  return fetch(`${apiUrl}/users`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callDeleteUserById(userID) {
  return fetch(`${apiUrl}/users/${userID}`, {
    method: 'DELETE',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callGetUsersByGroup(userGroup) {
  return fetch(`${apiUrl}/users?user_group=${userGroup}`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callGetDriverAvailabilityData(dueStart, dueEnd) {
  return fetch(
    `${apiUrl}/users?user_group=Haulier-Driver&due_start=${encodeURIComponent(
      dueStart
    )}&due_end=${encodeURIComponent(dueEnd)}`,
    {
      method: 'GET',
      headers: {
        Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
        'Content-Type': 'application/json',
      },
    }
  );
}
/**
 * @param {string} type - type of user making request. Can be either 'site_id' or 'haulier_id'
 * @param {string} id - organisation id
 */
export function callGetSiteHauliers(type, id) {
  return fetch(`${apiUrl}/sitehauliers?${type}=${id}`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}
export function callLinkSiteHauliers(siteId, haulierId) {
  return fetch(`${apiUrl}/sitehauliers`, {
    method: 'POST',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      site_id: siteId,
      haulier_id: haulierId,
    }),
  });
}

export function callUnlinkSiteHauliers(siteId, haulierId) {
  return fetch(
    `${apiUrl}/sitehauliers?site_id=${siteId}&haulier_id=${haulierId}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
        'Content-Type': 'application/json',
      },
    }
  );
}

export function callSiteUserRequest(newUserId, siteId) {
  return fetch(`${apiUrl}/siteusers`, {
    method: 'POST',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      site_id: siteId,
      user_id: newUserId,
    }),
  });
}
export function callGetSiteUsers(siteId) {
  return fetch(`${apiUrl}/siteusers?site_id=${siteId}`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}
export function callGetOrganisationUsers(orgId) {
  return fetch(`${apiUrl}/organisationUsers/${orgId}`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}
export function callGetConnectedHauliers(siteId) {
  return fetch(`${apiUrl}/sitehaulier/${siteId}`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}

export function callCreateBooking(newBookingData) {
  return fetch(`${apiUrl}/delivery_records`, {
    method: 'POST',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...newBookingData }),
  });
}

export function callPatchBooking(bookingId, patchBookingParams) {
  return fetch(`${apiUrl}/delivery_records/${bookingId}`, {
    method: 'PATCH',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...patchBookingParams,
    }),
  });
}

export function callHaulierAddVehicle(haulierId, vehicleVrm) {
  return fetch(`${apiUrl}/vehicles`, {
    method: 'POST',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      haulier_id: haulierId,
      vrm: vehicleVrm,
    }),
  });
}

export function callPostSupportMessage(userMessage) {
  return fetch(`${apiUrl}/support`, {
    method: 'POST',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      subject: 'Support Message',
      message: userMessage,
    }),
  });
}

export async function callGetRejectedBookings() {
  return fetch(`${apiUrl}/delivery_records?status_filter=rejected`, {
    method: 'GET',
    headers: {
      Authorization: getCookie(`idToken:${getCurrentUserIdFromStore()}`),
      'Content-Type': 'application/json',
    },
  });
}
