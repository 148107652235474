import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const OrganisationSuccess = () => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { isHaulier } = currentUserStore;

  return (
    <form className="form">
      <div className="form__form-inner">
        <Typography variant="h6" component="h4" gutterBottom>
          Success! You have now created your Organisation!
        </Typography>
        <p>
          You are now able to access the main dashboard, where you will be able
          to:
        </p>
        {isHaulier ? (
          <ul>
            <li>Add the details of your Drivers and Vehicles.</li>
            <li>Manage the bookings for your Organisation.</li>
          </ul>
        ) : (
          <ul>
            <li>
              Add users to your organisation and manage their permissions.
            </li>
            <li>
              Allowing for coordinated management across all of your sites.
            </li>
          </ul>
        )}

        <p>Please login to continue.</p>

        <Link className="button --full-width" to="/login">
          Login
        </Link>
      </div>
    </form>
  );
};
export default OrganisationSuccess;
