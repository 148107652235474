import React, { useState } from 'react';
import {
  callUnlinkSiteHauliers,
  callPatchBooking,
} from '../../../api/kerb-construction';
import useCallApi from '../../../hooks/useCallApi';
import ClickWaitButton from '../../ClickWaitButton';
import useBackButtonHandler from '../../../hooks/useBackButtonHandler';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';

const DeleteButton = ({
  deletionType,
  entityToDelete,
  className,
  successfulDeleteCallBack,
}) => {
  const [apiCalling, setApiCalling] = useState(false);
  const handleErrorResponse = useHandleErrorResponse();

  const redirectToPreviousPage = useBackButtonHandler();

  const apiToFetch = () => {
    switch (deletionType) {
      case 'haulier-disconnect':
        return callUnlinkSiteHauliers;
      case 'delete-booking':
        return callPatchBooking;
      default:
        return () => {};
    }
  };

  const conditionForDelete = () => {
    switch (deletionType) {
      case 'delete-booking':
        // In Progress bookings should not be soft deleted
        return !entityToDelete.in_progress;
      default:
        return true;
    }
  };

  const deleteErrorMessage = () => {
    switch (deletionType) {
      case 'delete-booking':
        return 'You cannot delete a booking that is currently in progress. Please wait for the delivery to complete, and try again.';
      default:
        return 'This cannot currently be deleted. Please try again later';
    }
  };

  const apiParams = () => {
    switch (deletionType) {
      case 'haulier-disconnect':
        return [entityToDelete.site_id, entityToDelete.haulier_id];
      case 'delete-booking':
        return [entityToDelete.id, { active: false }];
      default:
        return [];
    }
  };

  const successCallback = () => {
    redirectToPreviousPage();
    successfulDeleteCallBack();
  };

  const caughtErrorCallBack = () => {
    setApiCalling(false);
  };

  const [haulierDisconnecting] = useCallApi(apiToFetch(), apiParams(), {
    condition: apiCalling,
    caughtErrorCallBack,
    successCallback,
  });

  return (
    <ClickWaitButton
      className={className}
      apiCalling={haulierDisconnecting}
      clickFunction={() => {
        if (conditionForDelete()) {
          setApiCalling(true);
        } else {
          const errorMessage = deleteErrorMessage();
          handleErrorResponse(errorMessage);
        }
      }}
    >
      Delete
    </ClickWaitButton>
  );
};

export default DeleteButton;
