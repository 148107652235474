import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import CurrentUserContainer from '../containers/CurrentUserContainer';
import AmplifyRefreshTimer from '../containers/AmplifyRefreshTimer';
import AmplifySessionContainer from '../containers/AmplifySessionContainer';

const PrivateRoute = ({ children, isLoggedIn }) => {
  return (
    <Route
      render={
        ({ location }) => {
          return isLoggedIn ? (
            <AmplifySessionContainer>
              <CurrentUserContainer>
                <AmplifyRefreshTimer />
                {children}
              </CurrentUserContainer>
            </AmplifySessionContainer>
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }

        // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  );
};
export default PrivateRoute;
