import React, { useState, useEffect, useRef } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import penImage from '../../../images/pen.svg';
import Close from '../../../images/Components/Close';
import LoadingCircle from '../../../shared/LoadingCircle';
import { callDeleteVehiclesById } from '../../../api/kerb-construction';

const ManageVehiclesTableRow = ({ vehicle, loadVehicles }) => {
  const handleErrorResponse = useHandleErrorResponse();

  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setShowDropdownOptions((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setShowDropdownOptions(false);
  };

  const prevOpen = useRef(showDropdownOptions);

  const deleteVehicle = (vehicleID) => {
    setWaitingForResponse(true);
    callDeleteVehiclesById(vehicleID)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return {};
      })
      .then(() => {
        loadVehicles();
        setWaitingForResponse(false);
      })
      .catch((err) => {
        handleErrorResponse(err);
        setWaitingForResponse(false);
        setShowDropdownOptions(false);
      });
  };

  useEffect(() => {
    if (
      anchorRef.current &&
      prevOpen.current === true &&
      showDropdownOptions === false
    ) {
      anchorRef.current.focus();
    }

    prevOpen.current = showDropdownOptions;
  }, [showDropdownOptions]);

  return (
    <TableRow
      key={vehicle.vehicle_id}
      className="edit-row edit-vehicle-row manage-vehicles-view__table-row"
    >
      <TableCell>{vehicle.vrm}</TableCell>
      <TableCell>
        Active{' '}
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            {showDropdownOptions ? (
              <>
                <button
                  type="button"
                  ref={anchorRef}
                  aria-controls={
                    showDropdownOptions ? 'menu-list-grow' : undefined
                  }
                  aria-haspopup="true"
                  onClick={handleToggle}
                  className="button button-edit-row edit-vehicle-row --unselect"
                >
                  <Close />
                </button>
                <div className="dropdown-menu --manage-vehicles">
                  <button
                    type="button"
                    className={`button dropdown-option ${
                      waitingForResponse ? '--disable-hover-highlight' : ''
                    }`}
                    disabled={waitingForResponse}
                    onClick={() => {
                      deleteVehicle(vehicle.vehicle_id);
                    }}
                  >
                    {!waitingForResponse ? (
                      'Delete'
                    ) : (
                      <LoadingCircle className="dropdown-menu__loading-circle" />
                    )}
                  </button>
                </div>
              </>
            ) : (
              <button
                type="button"
                className="button button-edit-row edit-vehicle-row"
                onClick={handleToggle}
              >
                <img src={penImage} alt="edit booking" />
              </button>
            )}
          </div>
        </ClickAwayListener>
      </TableCell>
    </TableRow>
  );
};

export default ManageVehiclesTableRow;
