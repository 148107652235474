import { useState, useEffect } from 'react';
import useHandleErrorResponse from './useHandleErrorResponse';
/**
 * A Custom Hook that calls an api from a component only when the condition value is true and exposes an array containing 'loading and response'
 * @param {Function} apiCall - apiCall fetch method.
 * @param {Array} argsObject  - args to be called with apicall. Defaults to empty object.
 * @param {ANY} condition - the changing condition which when true, triggers the api call
 * @return {Array} [Loading: boolean, Response: Any]
 */
const useConditionalCallApi = (
  apiCall,
  argsObject = [],
  condition,
  callback,
  caughtErrorCallBack
) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleErrorResponse = useHandleErrorResponse();

  useEffect(() => {
    if (condition) {
      setLoading(true);
      apiCall(...argsObject)
        .then((res) => {
          if (!res.ok) {
            throw res.json();
          }
          return res.json();
        })
        .then((responseData) => {
          setResponse(responseData);
          if (callback) {
            callback(responseData);
          }
          setLoading(false);
        })
        .catch((err) => {
          handleErrorResponse(err);
          if (caughtErrorCallBack) {
            caughtErrorCallBack();
          }
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return [loading, response];
};

export default useConditionalCallApi;
