/* eslint-disable no-useless-escape */
const emailRegexPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const postcodeRegex =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/; // regex created to match cognito password policy - https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html

const inputRefConfig = {
  emailInput: {
    required: true,
    pattern: {
      value: emailRegexPattern,
      message: 'invalid email address',
    },
  },
  numberInput: {
    required: true,
    maxLength: 20,
    minLength: 3,
    pattern: /([0-9+])\w+/i,
    message: 'Please Meet Number Requirements',
  },
  addressInput: {
    required: true,
    maxLength: 20,
    minLength: 3,
    pattern: /([A-Z0-9._%+-])\w+/i,
  },
  postcodeInput: {
    required: true,
    maxLength: 8,
    minLength: 2,
    pattern: {
      value: postcodeRegex,
      message: 'Invalid Postcode',
    },
  },
  nameInput: {
    required: true,
    maxLength: 30,
    minLength: 2,
    pattern: /([A-Z])\w+/i,
  },
  VRMInput: {
    required: false,
    maxLength: 8,
    minLength: 6,
    pattern: /([A-Z•])\w+/i,
  },
  passwordInput: {
    required: true,
    maxLength: 20,
    minLength: {
      value: 8,
      message: 'password must be 8 char',
    },
    // eslint-disable-next-line no-useless-escape
    pattern: passwordRegex,
    message: 'Please Meet Password Requirements',
  },
  recurringDateInput: {
    required: true,
  },
  forsIDInput: {
    required: false,
    maxLength: 32,
  },
  helpRequest: {
    required: true,
    minLength: {
      value: 16,
      message: 'Please enter between 16 and 1000 characters',
    },
    maxLength: {
      value: 1000,
      message: 'Please enter between 16 and 1000 characters',
    },
    message: 'Please Meet Password Requirements',
  },
};

export default inputRefConfig;
