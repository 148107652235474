import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import { callPatchBooking } from '../../../api/kerb-construction';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import Close from '../../../images/Components/Close';
import penImage from '../../../images/pen.svg';

const ButtonSingleEditRow = ({
  booking,
  originalValueFunctions,
  originalSingleBookingData,
  assignmentType,
  hasRecordChanged,
}) => {
  const dispatch = useDispatch();
  const handleErrorResponse = useHandleErrorResponse();

  const storePlannedBooking = originalValueFunctions.storePlanned;
  const removePlannedBooking = originalValueFunctions.removePlanned;

  const bookingID = booking.id;

  const originalPlannedBookingDetails = originalSingleBookingData;

  // Taken from example given in Material Drop Down Documentation
  // From Here

  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setShowDropdownOptions((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setShowDropdownOptions(false);
  };

  const prevOpen = useRef(showDropdownOptions);

  useEffect(() => {
    if (
      anchorRef.current &&
      prevOpen.current === true &&
      showDropdownOptions === false
    ) {
      anchorRef.current.focus();
    }

    prevOpen.current = showDropdownOptions;
  }, [showDropdownOptions]);

  // To Here

  function handleEditRow() {
    // Avoids overiding with another future change
    // e.g. if the driver is being changed but the VRM already has
    if (!(booking.id in originalSingleBookingData)) {
      storePlannedBooking(booking);
    }
    dispatch({
      type: 'SET_EDIT_BOOKING_ID',
      payload: booking.id,
    });

    dispatch({
      type: 'SET_EDIT_BOOKING_ASSIGNMENT_TYPE',
      payload: assignmentType,
    });

    dispatch({
      type: 'SET_EDIT_BOOKING_DETAILS',
      payload: { ...booking },
    });

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 1,
    });
  }

  function clearEditRow() {
    const originalDriverAndVehicleID = {
      vehicle_id: originalPlannedBookingDetails[bookingID].vehicle_id,
      driver_id: originalPlannedBookingDetails[bookingID].driver_id,
    };

    // This ensures only the correct data values are being reset
    if (
      originalDriverAndVehicleID.vehicle_id === undefined ||
      assignmentType === 'driver'
    ) {
      delete originalDriverAndVehicleID.vehicle_id;
    }

    if (
      originalDriverAndVehicleID.driver_id === undefined ||
      assignmentType === 'vehicle'
    ) {
      delete originalDriverAndVehicleID.driver_id;
    }

    callPatchBooking(bookingID, originalDriverAndVehicleID)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then(() => {
        removePlannedBooking(assignmentType, booking);

        dispatch({
          type: 'SET_EDIT_BOOKING_ID',
          payload: null,
        });
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {showDropdownOptions ? (
          <>
            <button
              type="button"
              ref={anchorRef}
              aria-controls={showDropdownOptions ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              className="button button-edit-row --unselect"
            >
              <Close />
            </button>
            <div className="dropdown-menu --assign-booking">
              <button
                type="button"
                className="button dropdown-option"
                onClick={handleEditRow}
              >
                Change Selection
              </button>

              {hasRecordChanged && (
                <>
                  <button
                    type="button"
                    className="button dropdown-option"
                    onClick={clearEditRow}
                  >
                    Undo
                  </button>
                </>
              )}
            </div>
          </>
        ) : (
          <button
            type="button"
            className={`button button-edit-row button-edit-${assignmentType}`}
            onClick={handleToggle}
          >
            <img src={penImage} alt="edit booking" />
          </button>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default ButtonSingleEditRow;
