import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

function ConnectedTableRow({ user }) {
  return (
    <TableRow>
      <TableCell>{user.user_name}</TableCell>
    </TableRow>
  );
}

export default ConnectedTableRow;
