import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LoadingCircle from './LoadingCircle';
import useWebSocket from '../hooks/useWebSocket';
import useHandleErrorResponse from '../hooks/useHandleErrorResponse';

const WebsocketWrapper = ({ children, connectionType, connectionID }) => {
  const [websocketData, setWebsocketData] = useState(null);
  const handleErrorResponse = useHandleErrorResponse();
  const dispatch = useDispatch();

  const handleWSData = (event) => {
    const data = JSON.parse(event.data);

    switch (data.type) {
      case 'delivery_update': {
        setWebsocketData(data.delivery_record);
        break;
      }

      case 'delivery_note': {
        if (connectionType === 'site_id') {
          dispatch({
            type: 'SET_NEW_NOTE_MESSAGE',
            payload: data.delivery_record_note,
          });
        }

        break;
      }

      default:
        break;
    }
  };

  const handleWSError = () => {
    // eslint-disable-next-line no-console
    const errorMessage = 'Could not connect to Websocket';
    handleErrorResponse(errorMessage);
  };

  const [ws, connected] = useWebSocket(
    connectionType,
    connectionID,
    (event) => handleWSData(event),
    () => handleWSError()
  );

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    ws.current; // instantiate WS.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { websocketData })
  );

  return connected ? childrenWithProps : <LoadingCircle />;
};

export default WebsocketWrapper;
