import { useDispatch } from 'react-redux';
import { getFlattenedResponseMessage, logUserOut } from '../utils/functions';

const useHandleErrorResponse = () => {
  const dispatch = useDispatch();
  const errorMessageTimeout = 6000;

  function dispatchError(res) {
    dispatch({
      type: 'SET_ERROR_ALERT',
      payload: getFlattenedResponseMessage(res),
    });

    dispatch({
      type: 'SET_DELIVERY_ASSIGNMENT_CALL_IN_PROGRESS',
      payload: false,
    });
    setTimeout(
      () => dispatch({ type: 'SET_ERROR_ALERT', payload: null }),
      errorMessageTimeout
    );
  }

  return (err) => {
    // check to see if error response is a promise
    if (Promise.resolve(err) === err) {
      const errorStatus = err.status;
      err.then((res) => {
        dispatchError(res);
        if (errorStatus === 401) logUserOut();
      });
    } else if (err instanceof Error) {
      const errString = err.toString();
      dispatchError(errString);
    } else {
      dispatchError(err);
    }
  };
};

export default useHandleErrorResponse;
