import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import AvailableSitesDropDown from '../../../shared/AvailableSitesDropDown';

const BookingDetailsSite = () => {
  const dispatch = useDispatch();
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const siteIsSelected = !!selectedSiteStore.id;
  const [siteHasBeenReset, setSiteHasBeenReset] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'RESET_SELECTED_SITE_DETAILS',
    });
    setSiteHasBeenReset(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteHasBeenReset) {
      dispatch({
        type: 'SET_SUB_ROUTE_PROGRESS',
        payload: siteIsSelected ? 1 : 0,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteIsSelected]);

  return (
    <form className="form">
      <Typography variant="h5" component="h4" gutterBottom>
        Please Select A Site:
      </Typography>
      <AvailableSitesDropDown />
    </form>
  );
};
export default BookingDetailsSite;
