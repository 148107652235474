/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { callResendSignUp } from '../../../api/auth';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';

const ResendConfirmationCode = ({ username }) => {
  const [resendCodeRequested, setResendCodeRequested] = useState(false);
  const dispatch = useDispatch();
  const resendTimeout = 30000; // 30 seconds
  const handleErrorResponse = useHandleErrorResponse();

  const handleResend = () => {
    callResendSignUp(username)
      .then(() => {
        dispatch({
          type: 'SET_ALERT',
          payload: 'Code Resent!',
        });
        setResendCodeRequested(true);
        setTimeout(() => {
          setResendCodeRequested(false);
        }, resendTimeout);
      })
      .catch((err) => handleErrorResponse(err));
  };

  return (
    <div className="confirmation-code-view__resend-code-container">
      <p className="u-helper-text">Didn&apos;t receive a code? </p>

      <a
        disabled={resendCodeRequested}
        className="u-block-link"
        onKeyDown={(ev) => {
          if (ev.keyCode === 13) {
            handleResend();
          }
        }}
        onClick={handleResend}
      >
        Resend
      </a>
    </div>
  );
};

export default ResendConfirmationCode;
