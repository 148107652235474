import React from 'react';
import { useSelector } from 'react-redux';
import isSameDay from 'date-fns/isSameDay';
import BookingDateSelector from './BookingDateSelector';
import BookingCard from './BookingCards/BookingCard';
import 'react-datepicker/dist/react-datepicker.css';
import BookingScheduleButton from './BookingScheduleButton';
import NoBookings from '../../../images/NoBookings.svg';

const Column = ({
  columnBookingData,
  columnStatus,
  columnName,
  howManyColumns,
}) => {
  const columnLevel = columnBookingData.length;
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const scheduleStore = useSelector((state) => state.scheduleStore);
  const siteConfig = selectedSiteStore.config;
  const columnStatusInfo = siteConfig.statuses[columnStatus];
  const columnCapacity = columnStatusInfo.capacity
    ? columnStatusInfo.capacity
    : 'Unlimited';
  const columnCapacityLabel = `${columnLevel}/${columnCapacity}`;

  const currentColumnIndex = columnStatusInfo.display_order;

  const firstOrLastColumn =
    currentColumnIndex === 1 || currentColumnIndex >= howManyColumns;

  const EmptyColumnContents = () => {
    const todaysDate = new Date();
    const selectedDate = scheduleStore.selectedDateString;

    const isTodaysSelected = isSameDay(todaysDate, selectedDate);

    const emptyColumnContentsClassName = `column__empty-column-contents-message ${
      isTodaysSelected ? '--todays-empty-schedule' : ''
    }`;

    return (
      <div className="column__empty-column-contents">
        <div className="column__empty-column-icon-container">
          <img src={NoBookings} alt="Lorry icon" />
        </div>
        <span className={emptyColumnContentsClassName}>
          There are currently no records to display
        </span>
      </div>
    );
  };

  return (
    <div className="column">
      <header className="column__header">
        <h5 className="column__heading">{columnName}</h5>
        {columnName === 'Schedule' && (
          <BookingScheduleButton className="schedule-view__booking-schedule-link-button" />
        )}
      </header>
      <>
        {!firstOrLastColumn && (
          <div className="column__capacity-label" data-name={columnName}>
            Capacity: {columnCapacityLabel}
          </div>
        )}

        <div className="column__scrolling-container">
          {columnStatus === 'new' && (
            <BookingDateSelector columnBookingData={columnBookingData} />
          )}
          {columnBookingData.length > 0 ? (
            columnBookingData.map((card, index) => {
              return (
                <BookingCard key={card.id} cardIndex={index} cardData={card} />
              );
            })
          ) : (
            <EmptyColumnContents />
          )}
        </div>
      </>
    </div>
  );
};
export default Column;
