import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const UnplannedModalDetails = ({ cardModalData }) => {
  const formatedDateCreated = format(
    parseISO(cardModalData.date_created),
    'dd/mm/yyyy'
  );

  return (
    <div className="booking-modal__details">
      <span>
        Material: <b>{cardModalData.material}</b>
      </span>
      <span>
        Company: <b>{cardModalData.haulier_name}</b>
      </span>
      <span>
        Date: <b>{formatedDateCreated}</b>
      </span>
      <span>
        Mobile: <b>{cardModalData.mobile_number}</b>
      </span>
    </div>
  );
};
export default UnplannedModalDetails;
