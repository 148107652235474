import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from './countries';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
const defaultCountry = countries.filter((country) => country.code === 'GB')[0];

const CountryPhoneNumberInput = ({ ...props }) => {
  const { inputRef, autoFocus } = props;

  return (
    <div className="country-phone-combined-input">
      <Autocomplete
        options={countries}
        autoHighlight
        getOptionLabel={(option) => `+${option.phone}`}
        defaultValue={defaultCountry}
        renderOption={(option) => (
          <>
            <span>{countryToFlag(option.code)}</span>({option.code}) +
            {option.phone}
          </>
        )}
        renderInput={(params) => {
          return (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Code*"
              name="countryCode"
              variant="outlined"
              inputRef={inputRef}
              onKeyUp={(e) => {
                e.preventDefault(); // prevent user from deleting.
              }}
            />
          );
        }}
      />
      <TextField
        className="country-phone-combined-input__number-input"
        name="number"
        autoFocus={autoFocus}
        label="Mobile Number*"
        variant="outlined"
        inputRef={inputRef}
      />
    </div>
  );
};

export default CountryPhoneNumberInput;
