import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useDispatch } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RecurringBookingIcon from '../../../images/Components/MetroTruckRecurring';
import RecurringInTransitIcon from '../../../images/Components/MetroTruckRecurringInTransit';
import ButtonRecurringEditRow from '../components/ButtonRecurringEditRow';

const RecurringBookingsAssignmentTableRow = ({
  recurringBookings,
  editRowActive,
  bookingCount,
  inTransit,
  originalValueFunctions,
  originalBookingDetails,
}) => {
  const dispatch = useDispatch();

  const firstBooking = recurringBookings[0];
  const lastBooking = recurringBookings[recurringBookings.length - 1];
  const formattedStartDate = format(
    parseISO(firstBooking.due_start),
    'dd/MM/yyyy'
  );

  const formattedEndDate = format(parseISO(lastBooking.due_end), 'dd/MM/yyyy');

  const bookingVRM = firstBooking.vrm;
  const bookingDriver = firstBooking.driver_name;

  const rowIsEditable = bookingVRM !== undefined || bookingDriver !== undefined;

  const inProgress = firstBooking.in_progress;

  const originalRecurringBookingDetails = originalBookingDetails[1];

  const showText = (type) => {
    return (
      (type === 'driver' && !firstBooking.driver_id) ||
      (type === 'vehicle' && !firstBooking.vrm)
    );
  };

  const handleBookingSelect = () => {
    dispatch({
      type: 'SET_EDIT_BOOKING_DETAILS',
      payload: { ...firstBooking },
    });

    dispatch({
      type: 'SET_EDIT_RECURRING_BOOKING_PARAMS',
      payload: {
        bookingCount,
        endDate: formattedEndDate,
      },
    });
  };

  const handleAssignBooking = (type) => {
    handleBookingSelect();
    dispatch({
      type: 'SET_EDIT_BOOKING_ASSIGNMENT_TYPE',
      payload: type,
    });

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 1,
    });
  };

  const viewNotesPage = () => {
    handleBookingSelect();
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 2,
    });
  };

  const TDEditCell = ({ editableValue, type }) => {
    const [hasRecordChanged, setHasRecordChanged] = useState(false);
    const firstRecurrentId = firstBooking.recurrent_id;

    const originalRecurrentRecordData =
      originalRecurringBookingDetails[firstRecurrentId];

    const checkHasRecordChanged = () => {
      setHasRecordChanged(false);

      if (
        type === 'driver' &&
        firstBooking.driver_id !== originalRecurrentRecordData.driver_id
      ) {
        setHasRecordChanged(true);
      } else if (
        type === 'vehicle' &&
        firstBooking.vrm !== originalRecurrentRecordData.vrm
      ) {
        setHasRecordChanged(true);
      }
    };

    useEffect(() => {
      if (firstRecurrentId in originalRecurringBookingDetails) {
        checkHasRecordChanged();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <TableCell
        className={`cell-with-button ${
          hasRecordChanged && '--booking-changed'
        } `}
      >
        {showText(type) ? (
          <button
            type="button"
            onClick={() => handleAssignBooking(type)}
            className="button button-table button-assign"
          >
            Assign {type}
          </button>
        ) : (
          <>
            <div className="cell-with-button__value-beside-button">
              {editableValue}
            </div>
            {rowIsEditable && !inTransit && !inProgress && (
              <ButtonRecurringEditRow
                booking={firstBooking}
                hasRecordChanged={hasRecordChanged}
                originalValueFunctions={originalValueFunctions}
                originalRecurringBookingData={originalRecurringBookingDetails}
                assignmentType={type}
                formattedEndDate={formattedEndDate}
                bookingCount={bookingCount}
              />
            )}
          </>
        )}
      </TableCell>
    );
  };

  return (
    <TableRow className={rowIsEditable ? `edit-row` : ``}>
      <TableCell>
        {inProgress ? (
          <RecurringInTransitIcon className="mui-table__booking-type-image --in-transit" />
        ) : (
          <RecurringBookingIcon className="mui-table__booking-type-image" />
        )}
      </TableCell>
      <TableCell>{bookingCount}</TableCell>
      <TableCell>{firstBooking.material}</TableCell>
      <TableCell>{firstBooking.haulier_name}</TableCell>
      <TableCell>{formattedStartDate}</TableCell>
      <TableCell>{formattedEndDate}</TableCell>
      <TableCell>N/A</TableCell>
      <TableCell className="capitalized-cell">
        {firstBooking.first_destination}
      </TableCell>

      <TDEditCell
        type="vehicle"
        bookingData={firstBooking}
        editableValue={firstBooking.vrm}
        editRowActive={editRowActive}
        originalRecurringBookingData={originalRecurringBookingDetails}
      />
      <TDEditCell
        type="driver"
        bookingData={firstBooking}
        editableValue={firstBooking.driver_name}
        editRowActive={editRowActive}
        originalRecurringBookingData={originalRecurringBookingDetails}
      />
      <TableCell className="cell-with-button">
        <button
          type="button"
          onClick={viewNotesPage}
          className="button button-table button-view-notes"
        >
          Notes
        </button>
      </TableCell>
    </TableRow>
  );
};

export default RecurringBookingsAssignmentTableRow;
