/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { callGetBookingVehicleData } from '../../../../api/kerb-construction';
import useCallApi from '../../../../hooks/useCallApi';
import ButtonConfirmBookingPatchRecord from '../../components/ButtonConfirmBookingPatchRecord';
import LoadingCircle from '../../../../shared/LoadingCircle';
import warningImage from '../../../../images/warning.svg';
import { getAlphabeticallySortedArray } from '../../../../utils/functions';

const VehicleAssignTable = () => {
  const [currentlySelectedVehicleId, setCurrentlySelectedVehicleId] =
    useState(null);
  const dispatch = useDispatch();
  const { editBookingDetails } = useSelector((state) => state.editBookingStore);
  const { due_start, due_end } = editBookingDetails;
  const [loading, response] = useCallApi(callGetBookingVehicleData, [
    due_start,
    due_end,
  ]);

  const vehicleID = editBookingDetails.vehicle_id;
  const VRM = editBookingDetails.vrm;

  const [originalVRM, setOriginalVRM] = useState(null);
  const [originalVehicleID, setOriginalVehicleID] = useState(null);

  useEffect(() => {
    setOriginalVRM(VRM);
    setOriginalVehicleID(vehicleID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleVehicleSelect(newSelectedVehicleObj) {
    const vehicleIsCurrentlySelected =
      newSelectedVehicleObj.vehicle_id === currentlySelectedVehicleId;
    const bookingIsRecurring = editBookingDetails.recurrent;

    if (!vehicleIsCurrentlySelected) {
      setCurrentlySelectedVehicleId(newSelectedVehicleObj.vehicle_id);
      dispatch({
        type: 'SET_PATCH_BOOKING_PARAM',
        payload: {
          vehicle_id: newSelectedVehicleObj.vehicle_id,
          patch_all_recurrent: bookingIsRecurring, // this may need to change, if we decide to let the user select individual records to patch.
        },
      });
      dispatch({
        type: 'SET_EDIT_BOOKING_VRM',
        payload: newSelectedVehicleObj.vrm,
      });
      dispatch({
        type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
        payload: ButtonConfirmBookingPatchRecord,
      });
    } else {
      setCurrentlySelectedVehicleId(null);
      dispatch({
        type: 'SET_PATCH_BOOKING_PARAM',
        payload: {
          vehicle_id: originalVehicleID,
          patch_all_recurrent: bookingIsRecurring, // this may need to change, if we decide to let the user select individual records to patch.
        },
      });
      dispatch({
        type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
        payload: null,
      });
      dispatch({
        type: 'SET_EDIT_BOOKING_VRM',
        payload: originalVRM,
      });
    }
  }

  return !loading ? (
    <>
      <div className="assign-booking-view__assignment-table-header">
        <div className="warning-information-container">
          <img src={warningImage} alt="warning" />
          <p className="u-text-italic">
            = Already assigned to a booking booking on the same date
          </p>
        </div>
      </div>
      <div className="assign-booking-view__assignment-table-body">
        <TableContainer className="mui-table">
          <Table
            className="--assignment-table"
            stickyHeader
            aria-label="Vehicle Assignment table"
          >
            <TableHead>
              <TableRow>
                <TableCell>VRM (Vehicle Registration Mark)</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getAlphabeticallySortedArray(response, 'vrm').map((vehicle) => {
                const vehicleVRM = vehicle.vrm;
                const vehicleId = vehicle.vehicle_id;
                const buttonIsSelected =
                  currentlySelectedVehicleId === vehicleId;

                return (
                  <TableRow key={vehicleId} className="assignment-table-row">
                    <TableCell>{vehicleVRM}</TableCell>
                    <TableCell className="cell-with-button">
                      <button
                        type="button"
                        onClick={() => handleVehicleSelect(vehicle)}
                        className={`button button-table ${
                          buttonIsSelected ? '--selected' : ''
                        }`}
                      >
                        {`${buttonIsSelected ? 'Unselect' : 'Select'}`}
                      </button>
                    </TableCell>
                    {vehicle.status !== 'available' && (
                      <TableCell className="assignment-warning-cell">
                        <img src={warningImage} alt="warning" />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  ) : (
    <LoadingCircle />
  );
};
export default VehicleAssignTable;
