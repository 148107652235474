import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import { getBookingSlotStringFromDueStartAndDueEnd } from '../../../utils/timeFunctions';
import recurringImage from '../../../images/RecurringBookingVehicle.svg';
import singleImage from '../../../images/SingleBookingVehicle.svg';

const SingleRowBookingTable = () => {
  const editBookingStore = useSelector((state) => state.editBookingStore);
  const { editBookingDetails } = editBookingStore;
  const bookingIsRecurring = editBookingDetails.recurrent;
  const { bookingCount, endDate } = editBookingStore.editRecurringBookingParams;
  const formattedStartDate = format(
    parseISO(editBookingDetails.due_start),
    'dd/MM/yyyy'
  );

  const formattedEndDate = bookingIsRecurring
    ? endDate
    : format(parseISO(editBookingDetails.due_end), 'dd/MM/yyyy');

  const bookingDueSlot = getBookingSlotStringFromDueStartAndDueEnd(
    editBookingDetails.due_start,
    editBookingDetails.due_end
  );

  const currentDriverId = editBookingStore.editBookingDetails.driver_id;

  const selectedPatchDriverId = editBookingStore.patchBookingParams.driver_id;

  const currentVehicleId = editBookingStore.editBookingDetails.vehicle_id;

  const selectedPatchVehicleId = editBookingStore.patchBookingParams.vehicle_id;

  const newDriverSelected =
    !!selectedPatchDriverId && selectedPatchDriverId !== currentDriverId;

  const newVRMSelected =
    !!selectedPatchVehicleId && selectedPatchVehicleId !== currentVehicleId;

  return (
    <TableContainer className="mui-table">
      <Table
        aria-label="Booking Assignment table"
        className="--single-booking-table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Number Of Days</TableCell>
            <TableCell>Material</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Slot</TableCell>
            <TableCell>First Destination</TableCell>
            <TableCell>Vehicle (VRM)</TableCell>
            <TableCell>Driver</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <img
                className="mui-table__booking-type-image"
                src={bookingIsRecurring ? recurringImage : singleImage}
                alt={
                  bookingIsRecurring
                    ? 'Recurring Booking'
                    : 'Individual Booking'
                }
              />
            </TableCell>

            <TableCell>{bookingIsRecurring ? bookingCount : 1}</TableCell>
            <TableCell>{editBookingDetails.material}</TableCell>
            <TableCell>{editBookingDetails.haulier_name}</TableCell>
            <TableCell>{formattedStartDate}</TableCell>
            <TableCell>{formattedEndDate}</TableCell>
            <TableCell>{bookingIsRecurring ? 'N/A' : bookingDueSlot}</TableCell>
            <TableCell className="capitalized-cell">
              {editBookingDetails.first_destination}
            </TableCell>
            <TableCell
              className={`${newVRMSelected ? '--booking-changed' : ''}`}
            >
              {editBookingDetails.vrm || '-----------'}
            </TableCell>
            <TableCell
              className={`${newDriverSelected ? '--booking-changed' : ''}`}
            >
              {editBookingDetails.driver_name || '-----------'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SingleRowBookingTable;
