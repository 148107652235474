import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import ArrowDown from '../../../../../images/Components/ArrowDown';
import { callGetBookingNotes } from '../../../../../api/kerb-construction';
import useCallApi from '../../../../../hooks/useCallApi';

const BookingNotes = ({ bookingID }) => {
  const [dropdownStates, setDropdownStates] = useState([true, false]);
  const [notes, setNotes] = useState([]);
  const bookingModalStore = useSelector((state) => state.bookingModalStore);
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const [loading, response] = useCallApi(callGetBookingNotes, [bookingID]);
  const dispatch = useDispatch();
  const currentUserID = currentUserStore.details.userId;
  const { newNoteData } = bookingModalStore;

  const handleDropDownToggle = (index) => {
    const newDropdownState = [...dropdownStates];
    newDropdownState.splice(index, 1, !dropdownStates[index]);
    setDropdownStates(newDropdownState);
  };

  const getDropdownStateClassname = (index) => {
    return dropdownStates[index] ? '--dropdown-active' : '--dropdown-inactive';
  };

  useEffect(() => {
    dispatch({
      type: 'CLEAR_NEW_NOTE_MESSAGE',
    });
    setNotes(response);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (newNoteData.delivery_record_id) {
      if (newNoteData.delivery_record_id === bookingID) {
        setNotes([newNoteData, ...notes]);
      }

      dispatch({
        type: 'CLEAR_NEW_NOTE_MESSAGE',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNoteData]);

  return (
    <div
      role="button"
      tabIndex="0"
      onKeyDown={() => handleDropDownToggle(1)}
      onClick={() => handleDropDownToggle(1)}
      className={`booking-modal__dropdown-container ${getDropdownStateClassname(
        1
      )}`}
    >
      <header>
        <h4>Notes {notes && `(${notes.length})`}</h4>
        <ArrowDown className="dropdown-arrow" />
      </header>

      {!loading && notes && (
        <div role="button" className="booking-modal__dropdown-content">
          {notes.reverse().map((note) => {
            return (
              <div key={note.date_created} className="booking-note">
                <div className="booking-note__note-user">
                  {note.creator_id === currentUserID ? 'You' : note.creator_id}
                </div>
                <div
                  className={`booking-note__note-content ${
                    note.creator_id === currentUserID ? '--dark' : ''
                  }`}
                >
                  {note.content}
                </div>
                <div className="booking-note__note-timestamp">
                  {format(parseISO(note.date_created), "MM/dd/yy 'at' HH:mm")}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default BookingNotes;
