import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const MobileNavLink = ({ route, toggleMenuOpen }) => {
  const dispatch = useDispatch();

  const handleButtonClick = (buttonAction) => {
    switch (buttonAction) {
      case 'toggle-notification':
        dispatch({
          type: 'SET_NOTIFICATION_PANEL_OPEN',
          payload: true,
        });
        break;
      default:
        break;
    }
  };

  function handleLinkClick(routeData) {
    const { mobileMenu } = routeData;

    handleButtonClick(mobileMenu.onClick);
  }

  return (
    <li key={route.title}>
      {route.mobileMenu.onClick ? (
        <button
          type="button"
          onClick={() => {
            handleLinkClick(route);
          }}
        >
          {route.title}
          {'mobileMenuIcon' in route && (
            <route.mobileMenuIcon className="mobile-nav__nav-icon" />
          )}
        </button>
      ) : (
        <Link onClick={toggleMenuOpen} to={route.path}>
          {route.title}
          {'mobileMenuIcon' in route && (
            <route.mobileMenuIcon className="mobile-nav__nav-icon" />
          )}
        </Link>
      )}
    </li>
  );
};

export default MobileNavLink;
