import React, { useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import EventIcon from '@material-ui/icons/Event';
import TextField from '@material-ui/core/TextField';
import { add, isSameDay, format, parseISO } from 'date-fns';

const SingleDatePicker = ({ bookingFormState, setBookingFormState }) => {
  const dateNow = new Date();
  const dateOneDayFromNow = add(dateNow, {
    days: 1,
  });

  const recurrentDates = bookingFormState.recurrentDates || [];

  const setRecurrentDays = (days) => {
    setBookingFormState({ ...bookingFormState, recurrentDates: days });
  };

  const [datesUpdating, setDatesUpdating] = useState(false);

  const editDates = () => {
    setDatesUpdating(true);
  };

  const confirmDates = () => {
    setDatesUpdating(false);
  };

  const datesFieldValue = () => {
    if (!recurrentDates || recurrentDates.length === 0) {
      return 'Select dates';
    }
    if (recurrentDates.length === 1) {
      return '1 date selected';
    }
    return `${recurrentDates.length} dates selected`;
  };

  const datesTextFieldValue = () => {
    if (!recurrentDates || recurrentDates.length === 0) {
      return 'Select dates';
    }

    const formattedStrings = recurrentDates.map(
      (day) => `${format(day, 'EEE do MMMM yyyy')}` // will format date eg: Tue 10th November 2020
    );

    return formattedStrings.join('\n').replace(/\n$/, ''); // Removes the trailing new line, allows form to be validated
  };

  const handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return;
    }

    if (!recurrentDates) {
      setRecurrentDays([day]);
      return;
    }

    if (selected) {
      setRecurrentDays(recurrentDates.filter((date) => !isSameDay(date, day)));
    } else {
      setRecurrentDays([...recurrentDates, day]);
    }
  };

  function formatDate(date, dateFormat, locale) {
    return format(date, dateFormat, { locale });
  }

  function parseDate(str, dateFormat, locale) {
    const parsed = parseISO(str, dateFormat, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  return (
    <>
      {datesUpdating ? (
        <div className="recurrent-booking-date-selection">
          <TextField
            name="dates"
            label="Dates*"
            variant="outlined"
            focused
            value={datesFieldValue()}
            className="recurring-date-picker__date-list"
          />
          <EventIcon className="event-icon" />

          <div className="recurrent-booking-date-selection__date-selector">
            <DayPicker
              selectedDays={recurrentDates}
              onDayClick={handleDayClick}
              disabledDays={{ before: dateOneDayFromNow }}
              format="YYYY-MM-DD"
              formatDate={formatDate}
              parseDate={parseDate}
            />
            <button type="button" className="button" onClick={confirmDates}>
              Confirm
            </button>
          </div>
        </div>
      ) : (
        <div className="recurrent-booking-date-selection">
          <TextField
            multiline
            autoFocus
            spellCheck={false}
            value={datesTextFieldValue()}
            name="dates"
            label="Dates*"
            variant="outlined"
            inputProps={{ spellCheck: 'false' }}
            onClick={editDates}
          />

          <EventIcon className="event-icon" onClick={editDates} />
        </div>
      )}
    </>
  );
};

export default SingleDatePicker;
