import React from 'react';
import { format } from 'date-fns';
import IdleTimer from 'react-idle-timer';

const InactivityTimer = ({ inactivityRefreshTime }) => {
  const handleOnIdle = () => {
    // eslint-disable-next-line no-console
    console.log(
      `%c[Inactivity Handler]: Last page load at ${format(
        new Date(),
        'HH:mm, dd/MM/yyyy'
      )} `,
      'color: limegreen'
    );
    window.location.reload();
  };

  return (
    <IdleTimer timeout={1000 * inactivityRefreshTime} onIdle={handleOnIdle} />
  );
};

export default InactivityTimer;
