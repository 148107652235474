import React from 'react';
import { useSelector } from 'react-redux';
import MobileNav from './MobileNav';
import NotificationPanel from '../NotificationPanel/NotificationPanel';
import MobileBackButton from './MobileBackButton';

const HeaderMobile = ({ pageTitle }) => {
  const viewStore = useSelector((state) => state.viewStore);
  const { notificationPanelOpen } = viewStore;

  return (
    <header className="main-header --header-mobile">
      <MobileNav />
      <h1 className="main-header__header-title">{pageTitle}</h1>
      <MobileBackButton />
      {notificationPanelOpen && <NotificationPanel />}
    </header>
  );
};
export default HeaderMobile;
