import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getBookingSlotStringFromDueStartAndDueEnd } from '../../../utils/timeFunctions';
import SingleBookingIcon from '../../../images/Components/MetroTruck';
import SingleInTransitIcon from '../../../images/Components/MetroTruckInTransit';
import ButtonSingleEditRow from '../components/ButtonSingleEditRow';

const SingleBookingsAssignmentTableRow = ({
  booking,
  editRowActive,
  bookingCount,
  inTransit,
  originalValueFunctions,
  originalBookingDetails,
}) => {
  const dispatch = useDispatch();

  const bookingDueSlot = getBookingSlotStringFromDueStartAndDueEnd(
    booking.due_start,
    booking.due_end
  );

  const formattedStartDate = format(parseISO(booking.due_start), 'dd/MM/yyyy');

  const formattedEndDate = format(parseISO(booking.due_end), 'dd/MM/yyyy');

  const bookingVRM = booking.vrm;
  const bookingDriver = booking.driver_name;

  const rowIsEditable = bookingVRM !== undefined || bookingDriver !== undefined;

  const originalSingleBookingDetails = originalBookingDetails[0];

  const inProgress = booking.in_progress;

  const showText = (type) => {
    return (
      (type === 'driver' && !booking.driver_id) ||
      (type === 'vehicle' && !booking.vrm)
    );
  };

  const handleBookingSelect = () => {
    dispatch({
      type: 'SET_EDIT_BOOKING_DETAILS',
      payload: { ...booking },
    });
  };

  const handleAssignBooking = (type) => {
    handleBookingSelect();

    dispatch({
      type: 'SET_EDIT_BOOKING_ASSIGNMENT_TYPE',
      payload: type,
    });

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 1,
    });
  };

  const viewNotesPage = () => {
    handleBookingSelect();

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 2,
    });
  };

  const TDEditCell = ({ editableValue, type }) => {
    const [hasRecordChanged, setHasRecordChanged] = useState(false);

    useEffect(() => {
      if (booking.id in originalSingleBookingDetails) {
        if (
          type === 'driver' &&
          booking.driver_id !==
            originalSingleBookingDetails[booking.id].driver_id
        ) {
          setHasRecordChanged(true);
        }

        if (
          type === 'vehicle' &&
          booking.vrm !== originalSingleBookingDetails[booking.id].vrm
        ) {
          setHasRecordChanged(true);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <TableCell
        className={`cell-with-button ${
          hasRecordChanged && '--booking-changed'
        }`}
      >
        <>
          {showText(type) ? (
            <button
              type="button"
              onClick={() => handleAssignBooking(type)}
              className="button button-table button-assign"
            >
              Assign {type}
            </button>
          ) : (
            <>
              <div className="cell-with-button__value-beside-button">
                {editableValue}
              </div>
              {rowIsEditable && !inTransit && !inProgress && (
                <ButtonSingleEditRow
                  booking={booking}
                  originalValueFunctions={originalValueFunctions}
                  originalSingleBookingData={originalSingleBookingDetails}
                  hasRecordChanged={hasRecordChanged}
                  assignmentType={type}
                />
              )}
            </>
          )}
        </>
      </TableCell>
    );
  };

  return (
    <TableRow className={rowIsEditable ? `edit-row` : ``}>
      <TableCell className="assign-booking-view__assignment-icon-cell">
        {inProgress ? (
          <SingleInTransitIcon className="mui-table__booking-type-image --in-transit" />
        ) : (
          <SingleBookingIcon className="mui-table__booking-type-image" />
        )}
      </TableCell>
      <TableCell>{bookingCount}</TableCell>
      <TableCell>{booking.material}</TableCell>
      <TableCell>{booking.haulier_name}</TableCell>
      <TableCell>{formattedStartDate}</TableCell>
      <TableCell>{formattedEndDate}</TableCell>
      <TableCell>{bookingDueSlot}</TableCell>
      <TableCell className="capitalized-cell">
        {booking.first_destination}
      </TableCell>

      <TDEditCell
        type="vehicle"
        bookingData={booking}
        editableValue={booking.vrm}
        editRowActive={editRowActive}
        originalSingleBookingData={originalSingleBookingDetails}
      />

      <TDEditCell
        type="driver"
        bookingData={booking}
        editableValue={booking.driver_name}
        editRowActive={editRowActive}
        originalSingleBookingData={originalSingleBookingDetails}
      />
      <TableCell className="cell-with-button">
        <button
          type="button"
          onClick={viewNotesPage}
          className="button button-table button-view-notes"
        >
          Notes
        </button>
      </TableCell>
    </TableRow>
  );
};

export default SingleBookingsAssignmentTableRow;
