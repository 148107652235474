export const confirmDeletePageHeading = (deletionType) => {
  switch (deletionType) {
    case 'haulier-disconnect':
      return 'Are you sure you would like to remove this haulier?';
    case 'delete-booking':
      return 'Are you sure you would like to delete this booking?';
    default:
      return '';
  }
};

export const confirmDeletePageSubheading = (deletionType) => {
  switch (deletionType) {
    case 'haulier-disconnect':
      return 'This haulier may have assigned bookings, removing this haulier will result in the deletion of affiliated bookings.';
    case 'delete-booking':
      return 'Are you sure you would like to delete this booking? Deletion will notify the corresponding Haulier Administrator(s).';
    default:
      return '';
  }
};
