import React from 'react';

const BookingCardJobType = ({ cardData }) => {
  function getJobType() {
    if (cardData.type === 'UNPLANNED') {
      return 'Unplanned';
    }
    if (cardData.recurrent) {
      return 'Recurring';
    }
    return 'Planned';
  }

  const jobType = getJobType();

  return <span className="booking-card__job-name">Job Type: {jobType}</span>;
};
export default BookingCardJobType;
