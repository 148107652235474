import React, { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';

import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchForm from '../../../shared/SearchForm';
import { getAlphabeticallySortedArray } from '../../../utils/functions';
import UserTableRow from './UserTableRow';

import userTypeTableInfo from './ManageUserTable.config';

const UserTable = ({ manageUserData }) => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { currentUserType } = currentUserStore;
  const currentUserId = currentUserStore.details.userId;

  const dispatch = useDispatch();
  const { userData, usersLoaded } = manageUserData;
  const [filterText, setFilterText] = useState('');

  const { userType } = manageUserData;

  const userDataOfType = userData.filter(
    (user) => user.permissions === userType
  );

  const removeCurrentUserFromList =
    currentUserType === userType
      ? userDataOfType.filter((user) => user.id !== currentUserId)
      : userDataOfType;

  const sortedUserData = getAlphabeticallySortedArray(
    removeCurrentUserFromList,
    'name'
  );

  const userTableProperties = userTypeTableInfo[userType];

  const sortedFilteredUserData = () => {
    return filterText
      ? sortedUserData.filter((user) =>
          user.name.toLowerCase().includes(filterText.toLowerCase())
        )
      : sortedUserData;
  };

  const {
    manageUserHeading,
    tableHeadings,
    tableDataProperties,
    addUserButtonText,
  } = userTableProperties;

  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value);
  };

  const progressToAddUser = (event) => {
    event.preventDefault();

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 3,
    });
  };

  const TableHeading = () => {
    return (
      <TableRow>
        {tableHeadings.map((heading) => {
          return (
            <TableCell key={heading} className="manage-users__table-heading">
              {heading}
            </TableCell>
          );
        })}
        <TableCell className="manage-users__table-heading">Status</TableCell>
      </TableRow>
    );
  };

  const TableBodyRow = () => {
    return sortedFilteredUserData().map((data) => {
      return (
        <UserTableRow
          key={data.id}
          individualUserData={data}
          manageUserData={manageUserData}
          tableDataProperties={tableDataProperties}
        />
      );
    });
  };

  return (
    <TableContainer className="mui-table">
      <header className="manage-users__header">
        <div className="manage-users__heading-container">
          <Typography
            variant="h6"
            component="h6"
            gutterBottom
            className="u-highlight-title manager-users__heading"
          >
            {manageUserHeading}
          </Typography>
        </div>

        <Button className="button --small" onClick={progressToAddUser}>
          {addUserButtonText}
        </Button>
      </header>

      <div className="filter-text-box">
        <SearchForm
          label="Search"
          value={filterText}
          onChange={handleFilterTextChange}
        />
      </div>

      <div className="manage-users__table-container">
        <div className="manage-users__table-container-contents">
          {usersLoaded ? (
            <>
              <Table
                className="manage-users__table"
                stickyHeader
                aria-label="manage users table"
              >
                <TableHead>
                  <TableHeading />
                </TableHead>
                <TableBody>
                  <TableBodyRow />
                </TableBody>
              </Table>
            </>
          ) : (
            <LinearProgress />
          )}
        </div>
      </div>
    </TableContainer>
  );
};

export default UserTable;
