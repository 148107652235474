import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import { CSSTransition } from 'react-transition-group';
import { getSiteEntrancesArray } from '../../../utils/siteConfigFunctions';

const AvailableSiteEntranceFieldWithButtons = ({
  bookingFormState,
  setBookingFormState,
  originalSiteEntrance,
}) => {
  const selectedSiteEntrance =
    bookingFormState.siteEntrance || originalSiteEntrance || '';

  const [entranceIsFocused, setEntranceIsFocused] = useState(false);

  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const availableSiteEntrances = getSiteEntrancesArray(
    selectedSiteStore.config
  );

  const handleEntranceSelect = (entranceName) => {
    setBookingFormState({
      ...bookingFormState,
      siteEntrance: entranceName,
      firstDestination: '',
      bookingSlot: 'none',
    });
    setEntranceIsFocused(false);
  };

  function handleSiteEntranceFocus() {
    setEntranceIsFocused(true);
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setEntranceIsFocused(false);
      }}
    >
      <div className="form__textfield-with-buttons">
        <TextField
          name="site_entrance"
          label="Site Entrance"
          className="u-text-capitalise"
          variant="outlined"
          value={selectedSiteEntrance}
          onFocus={handleSiteEntranceFocus}
        />

        <CSSTransition
          classNames="form__textfield-button-container"
          in={entranceIsFocused}
          timeout={0}
          unmountOnExit
        >
          <div className="form__textfield-button-container">
            {availableSiteEntrances.map((entranceArr) => {
              const entranceName = entranceArr[0];
              return (
                <button
                  tabIndex="0"
                  type="button"
                  key={entranceName}
                  className="button --outline"
                  onClick={() => handleEntranceSelect(entranceName)}
                >
                  {entranceName}
                </button>
              );
            })}
          </div>
        </CSSTransition>
      </div>
    </ClickAwayListener>
  );
};
export default AvailableSiteEntranceFieldWithButtons;
