import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { callGetSiteHauliers } from '../../../api/kerb-construction';
import useCallApi from '../../../hooks/useCallApi';
import LoadingCircle from '../../../shared/LoadingCircle';

const HaulierSitesTable = () => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const dispatch = useDispatch();
  const history = useHistory();
  const haulierName = currentUserStore.organisation.name;
  const haulierId = currentUserStore.organisation.id;
  const [loading, response] = useCallApi(callGetSiteHauliers, [
    'haulier_id',
    haulierId,
  ]);

  function handleViewSiteBookings(site) {
    dispatch({
      type: 'SET_SELECTED_SITE_DETAILS',
      payload: {},
    });
    dispatch({
      type: 'SET_SELECTED_SITE_DETAILS',
      payload: {
        name: site.site_name,
        id: site.id,
      },
    });
    history.push('/bookings');
  }

  return !loading ? (
    <TableContainer className="mui-table">
      <Table stickyHeader aria-label="haulier sites table">
        <TableHead>
          <TableRow>
            <TableCell>Organisation Name</TableCell>
            <TableCell align="left">Site</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {response.map((site) => (
            <TableRow key={site.id}>
              <TableCell scope="site">{haulierName}</TableCell>
              <TableCell align="left">{site.site_name}</TableCell>
              <TableCell align="left" className="cell-with-button">
                <button
                  type="button"
                  className="button button-table"
                  onClick={() => handleViewSiteBookings(site)}
                >
                  View
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <LoadingCircle />
  );
};

export default HaulierSitesTable;
