import { useRef, useEffect, useState } from 'react';
import { getCookie, getCurrentUserIdFromStore } from '../utils/functions';

const useWebSocket = (connectionType, connectionID, onMessage, onError) => {
  const [connected, setConnected] = useState(false);

  const ws = useRef(null);

  const urlSuffix = `${connectionType}=${connectionID}`;

  useEffect(() => {
    const idToken = getCookie(`idToken:${getCurrentUserIdFromStore()}`);
    const wsURL = `${process.env.REACT_APP_WSS_URL}?Authorization=${idToken}&${urlSuffix}`;
    const socket = new WebSocket(wsURL);

    socket.onmessage = onMessage;
    socket.onerror = onError;
    ws.current = socket;
    ws.current.onopen = (event) => {
      // eslint-disable-next-line no-console
      console.log('[Websocket connected to]: ', event.currentTarget.url);
      setConnected(true);
    };
    return () => {
      ws.current.close();
      // eslint-disable-next-line no-console
      console.log('[Websocket Closed]');
      setConnected(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [ws, connected];
};

export default useWebSocket;
