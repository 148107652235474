import React, { useState } from 'react';
import ConfirmationButtons from './ConfirmationButtons';

function preventLetterInput(ev) {
  const letterReg = new RegExp('^[a-z, A-Z]');

  if (letterReg.test(ev.key)) ev.preventDefault();
}

const NumberConfirmationField = ({ data }) => {
  const [editState, setEditState] = useState(false);

  return (
    <div className="add-organisation-view__confirmation-fieldset">
      <span>Organisation Number:</span>
      <input
        type="text"
        data-input-phone
        maxLength="20"
        placeholder="organisation_phone"
        defaultValue={data}
        className="add-organisation-view__confirmation-input"
        disabled={!editState}
        onKeyPress={(ev) => preventLetterInput(ev)}
      />

      <ConfirmationButtons editState={editState} setEditState={setEditState} />
    </div>
  );
};

export default NumberConfirmationField;
