import sitesImg from '../../images/AddLocation.svg';
import userImg from '../../images/User.svg';
import bookingsImg from '../../images/Bookings.svg';
import vehiclesImg from '../../images/Vehicle.svg';

const userTypeDashboardConfigs = [
  {
    userType: 'Haulier-Admin',
    availableButtons: [
      {
        title: 'Sites & Bookings',
        img: bookingsImg,
        linkHref: '/hauliersites',
      },
      {
        title: 'Manage Vehicles',
        img: vehiclesImg,
        linkHref: '/managevehicles',
      },
      {
        title: 'Manage Users',
        img: userImg,
        linkHref: '/manageusers',
      },
    ],
  },
  {
    userType: 'Site-Organisation-Admin',
    availableButtons: [
      {
        title: 'Projects & Sites',
        img: sitesImg,
        linkHref: '/sites',
      },

      {
        title: 'Manage Users',
        img: userImg,
        linkHref: '/manageusers',
      },
    ],
  },
  {
    userType: 'Site-Manager',
    availableButtons: [
      {
        title: 'Projects & Sites',
        img: sitesImg,
        linkHref: '/sites',
      },
      {
        title: 'Add Bookings',
        img: bookingsImg,
        linkHref: '/addbooking',
      },
      {
        title: 'Manage Users',
        img: userImg,
        linkHref: '/manageusers',
      },
    ],
  },
];

export default userTypeDashboardConfigs;
