import React, { useState } from 'react';
import ConfirmationButtons from './ConfirmationButtons';

const ForsIdConfirmationField = ({ data }) => {
  const [editState, setEditState] = useState(false);

  return (
    <div className="add-organisation-view__confirmation-fieldset">
      <span>Fors ID:</span>
      <input
        type="text"
        data-input-forsid
        maxLength="20"
        placeholder="Fors ID"
        defaultValue={data}
        className="add-organisation-view__confirmation-input"
        disabled={!editState}
      />

      <ConfirmationButtons editState={editState} setEditState={setEditState} />
    </div>
  );
};

export default ForsIdConfirmationField;
