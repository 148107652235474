import React from 'react';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useCallApi from '../../../hooks/useCallApi';
import { callGetSiteHauliers } from '../../../api/kerb-construction';
import LoadingCircle from '../../LoadingCircle';

const AvailableHauliersDropDown = ({
  bookingFormState,
  setBookingFormState,
}) => {
  const selectedHaulier = bookingFormState.selectedHaulier || 'none';

  const setSelectedHaulier = (newSelectedHaulier) => {
    setBookingFormState({
      ...bookingFormState,
      selectedHaulier: newSelectedHaulier,
    });
  };

  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const siteId = selectedSiteStore.id;
  const [loading, response] = useCallApi(callGetSiteHauliers, [
    'site_id',
    siteId,
  ]);
  const dropdownText = response ? 'Select a Company' : 'No Companies Available';

  const handleHaulierSelect = (ev) => {
    const dropdownHaulier = ev.target.value;

    if (dropdownHaulier !== 'none') {
      const selectedHaulierObject = response.filter((haulier) => {
        return haulier.id === dropdownHaulier.id;
      })[0];

      setSelectedHaulier(selectedHaulierObject);
    } else {
      setSelectedHaulier(null);
    }
  };

  return (
    <FormControl required variant="outlined" name="haulier">
      {!loading ? (
        <Select
          id="haulierDropdown"
          value={selectedHaulier || 'none'}
          onChange={handleHaulierSelect}
          disabled={!response.length > 0}
          name="haulier"
        >
          <MenuItem key="none" value="none">
            {dropdownText}
          </MenuItem>
          {response.map((haulier) => {
            return (
              <MenuItem key={haulier.id} value={haulier}>
                {haulier.haulier_name}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <LoadingCircle />
      )}
    </FormControl>
  );
};
export default AvailableHauliersDropDown;
