import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import close from '../images/close.svg';

const AlertPanel = ({ alert }) => {
  const [alertTypeIsError, setAlertTypeIsError] = useState(false);
  const [animateAlert, setAnimateAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const dispatch = useDispatch();

  const handleClose = () => {
    setAlertTypeIsError(false);
    dispatch({
      type: 'CLEAR_ALERT',
    });
  };
  // eslint-disable-next-line no-shadow
  function createAlertMessage(alert) {
    let constructedMessage = null;
    if (alert.isError) {
      constructedMessage = `Error: ${alert.message}`;
      setAlertTypeIsError(true);
    } else if (!alert.isError) {
      constructedMessage = `${alert.message}`;
      setAlertTypeIsError(false);
    }
    return constructedMessage;
  }

  useEffect(() => {
    setAlertMessage(createAlertMessage(alert));
    setAnimateAlert(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-console
  console.log(`%c ${alertMessage}`, 'color: orange');
  return (
    <CSSTransition
      in={animateAlert}
      classNames="alert-panel"
      timeout={100}
      unmountOnExit
    >
      <dialog
        className={`alert-panel ${
          alertTypeIsError ? 'alert-panel--is-error' : ''
        }`}
      >
        <button
          type="button"
          className="button alert-panel__close"
          onClick={handleClose}
        >
          <img src={close} alt="close button" />
        </button>
        <span>{alertMessage}</span>
      </dialog>
    </CSSTransition>
  );
};

AlertPanel.propTypes = {
  alert: PropTypes.shape({
    isError: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default AlertPanel;
