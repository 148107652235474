import React, { useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { callDeleteUserById } from '../../../api/kerb-construction';
import userTypeTableInfo from './ManageUserTable.config';
import LoadingCircle from '../../../shared/LoadingCircle';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';

const DeleteUserTable = () => {
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  const dispatch = useDispatch();
  const handleErrorResponse = useHandleErrorResponse();

  const addUserStore = useSelector((state) => state.addUserStore);
  const { selectedUserDetails } = addUserStore;

  const selectedUserType = selectedUserDetails.permissions;

  const returnToPreviousPage = () => {
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 1,
    });
  };

  const confirmUserDelete = () => {
    setWaitingForResponse(true);

    callDeleteUserById(selectedUserDetails.id)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return {};
      })
      .then(() => {
        setWaitingForResponse(false);
        returnToPreviousPage();
      })
      .catch((err) => {
        setWaitingForResponse(false);
        handleErrorResponse(err);
      });
  };

  const {
    deleteUserHeading,
    deleteUserSubHeading,
    tableHeadings,
    tableDataProperties,
  } = userTypeTableInfo[selectedUserType];

  const TableHeading = () => {
    return (
      <TableRow>
        {tableHeadings.map((heading) => {
          return (
            <TableCell key={heading} className="manage-users__table-heading">
              {heading}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const TableBodyRow = () => {
    return (
      <TableRow
        className="edit-row edit-user-row manage-users__table-row"
        key={selectedUserDetails.id}
      >
        <TableCell>{selectedUserDetails[tableDataProperties[0]]}</TableCell>
        <TableCell className="manage-users__overflowing-table-cell">
          {selectedUserDetails[tableDataProperties[1]]}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer className="mui-table">
      <header className="manage-users__header">
        <div className="manage-users__heading-container">
          <Typography
            variant="h6"
            component="h6"
            gutterBottom
            className="u-highlight-title manager-users__heading"
          >
            {deleteUserHeading}
          </Typography>
        </div>

        <div className="manage-users__heading-button-container">
          <Button className="button" onClick={returnToPreviousPage}>
            Cancel
          </Button>
          <Button className="button  --warning" onClick={confirmUserDelete}>
            {!waitingForResponse ? (
              'Delete'
            ) : (
              <LoadingCircle className="dropdown-menu__loading-circle" />
            )}
          </Button>
        </div>

        {deleteUserSubHeading && (
          <div className="manage-users__sub-heading-container">
            <span className="u-text-italic u-text-bold">
              {deleteUserSubHeading}
            </span>
          </div>
        )}
      </header>

      <div className="manage-users__table-container">
        <div className="manage-users__table-container-contents --two-column">
          <Table
            className="manage-users__table"
            stickyHeader
            aria-label="manage users table"
          >
            <TableHead>
              <TableHeading />
            </TableHead>
            <TableBody>
              <TableBodyRow />
            </TableBody>
          </Table>
        </div>
      </div>
    </TableContainer>
  );
};

export default DeleteUserTable;
