import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { callCompleteNewPassword, callSignIn } from '../../api/auth';
import NewPasswordForm from './forms/NewPasswordForm';
import useHandleErrorResponse from '../../hooks/useHandleErrorResponse';
import useHandleUserSignInResponse from '../../hooks/useHandleUserSignInResponse';

const NewPasswordView = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const handleErrorResponse = useHandleErrorResponse();
  const handleUserSigninResponse = useHandleUserSignInResponse();

  const onSubmit = useCallback((data) => {
    const { username } = currentUserStore.details;
    const { tempPassword } = currentUserStore;
    const newPassword = data.password;

    callSignIn(username, tempPassword)
      .then((userObject) => {
        callCompleteNewPassword(userObject, newPassword)
          .then((user) => {
            handleUserSigninResponse(user);
          })
          .catch((err) => {
            handleErrorResponse(err);
            setFormSubmitting(false);
          });
      })
      .catch((err) => {
        handleErrorResponse(err);
        setFormSubmitting(false);
      });

    setFormSubmitting(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <NewPasswordForm onSubmit={onSubmit} formSubitting={formSubmitting} />
    </main>
  );
};
export default NewPasswordView;
