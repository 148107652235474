// ../views
import { Redirect } from 'react-router-dom';
import LandingViewConstruction from '../../views/Landing/LandingViewConstruction';
import LandingViewHaulier from '../../views/Landing/LandingViewHaulier';
import LoginView from '../../views/Login/LoginView';
import RegisterView from '../../views/Register/RegisterView';
import ConfirmationCodeView from '../../views/ConfirmationCode/ConfirmationCodeView';
import ManageUsersView from '../../views/ManageUsers/ManageUsersView';
import OperationOverviewView from '../../views/OperationOverview/OperationOverviewView';
import AddOrganisationView from '../../views/AddOrganisation/AddOrganisationView';
import DashboardView from '../../views/Dashboard/DashboardView';
import AssignBookingView from '../../views/BookingOverview/AssignBooking';
import AddBookingView from '../../views/AddBooking/AddBookingView';
import ErrorPageView from '../../views/ErrorPage/ErrorPageView';
import SitesView from '../../views/Sites/SitesView';
import ConnectHauliersView from '../../views/ConnectHauliers/ConnectHauliersView';
import ConnectUsersView from '../../views/ConnectUsers/ConnectUsersView';
import BookingOverviewView from '../../views/BookingOverview/BookingOverviewView';
import HaulierSitesView from '../../views/HaulierSites/HaulierSitesView';
import ResetPasswordView from '../../views/ResetPassword/ResetPasswordView';
import NewPasswordView from '../../views/NewPassword/NewPasswordView';
import HelpView from '../../views/Help/HelpView';
import UnauthorisedPageView from '../../views/UnauthorisedPage/UnauthorisedPageView';
import BookingScheduleView from '../../views/BookingSchedule/BookingScheduleView';
import EditBookingView from '../../views/EditBooking/EditBookingView';

// mobile nav icons
import HelpIcon from '../../images/Components/Help';

const routesConstruction = [
  {
    path: '/',
    title: 'Welcome',
    component: LandingViewConstruction,
    isPrivate: false,
    header: false,
  },
  {
    path: '/haulier',
    title: 'Welcome',
    component: LandingViewHaulier,
    isPrivate: false,
    header: false,
  },

  {
    path: '/login',
    title: 'Login',
    component: LoginView,
    isPrivate: false,
    header: false,
  },
  {
    path: '/register',
    title: 'Register',
    component: RegisterView,
    isPrivate: false,
    header: false,
  },
  {
    path: '/confirm',
    title: 'Confirmation Code',
    component: ConfirmationCodeView,
    isPrivate: false,
    header: false,
  },
  {
    path: '/newpassword',
    title: 'New Password',
    component: NewPasswordView,
    isPrivate: false,
  },
  {
    path: '/resetpassword',
    title: 'Reset Password',
    component: ResetPasswordView,
    isPrivate: false,
  },
  {
    path: '/error',
    title: 'Error',
    component: ErrorPageView,
    isPrivate: false,
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    component: DashboardView,
    isPrivate: true,
    header: {
      isDark: false,
      hasLogoutButton: true,
    },
    mobileMenu: true,
  },
  {
    path: '/addorganisation',
    title: 'Add Organisation',
    component: AddOrganisationView,
    isPrivate: true,
    header: false,
  },
  {
    path: '/sites',
    title: 'Projects & Sites',
    component: SitesView,
    isPrivate: true,
    header: {
      isDark: true,
    },
    mobileMenu: true,
  },
  {
    path: '/manageusers',
    title: 'Manage Users',
    component: ManageUsersView,
    isPrivate: true,
    header: true,
    mobileMenu: true,
  },
  {
    path: '/connectusers',
    title: 'Manage Users',
    component: ConnectUsersView,
    isPrivate: true,
    header: true,
  },
  {
    path: '/connecthauliers',
    title: 'Manage Hauliers',
    component: ConnectHauliersView,
    isPrivate: true,
    header: true,
    hiddenFrom: ['Site-Organisation-Admin'],
  },
  {
    path: '/hauliersites',
    title: 'Sites & Connections',
    component: HaulierSitesView,
    isPrivate: true,
    header: true,
  },
  {
    path: '/bookings',
    title: 'Bookings Overview',
    component: BookingOverviewView,
    isPrivate: true,
    header: true,
  },
  {
    path: '/assignbooking',
    title: 'Assign Booking',
    component: AssignBookingView,
    isPrivate: true,
    header: true,
  },
  {
    path: '/bookingschedule',
    title: 'Schedule',
    component: BookingScheduleView,
    isPrivate: true,
    header: {
      plannedBookingButton: true,
      hasLogoutButton: false,
    },
  },
  {
    path: '/operationoverview',
    title: 'Operation Overview',
    component: OperationOverviewView,
    isPrivate: true,
    header: {
      plannedBookingButton: true,
      isDark: true,
      hasLogoutButton: true,
    },
  },
  {
    path: '/addbooking',
    title: 'Add Booking',
    component: AddBookingView,
    isPrivate: true,
    header: true,
    mobileMenu: true,
    hiddenFrom: ['Site-Organisation-Admin'],
  },
  {
    path: '/editbooking',
    title: 'Edit Booking',
    component: EditBookingView,
    isPrivate: true,
    header: true,
    mobileMenu: true,
    hiddenFrom: ['Site-Organisation-Admin'],
  },
  {
    path: '/help',
    title: 'Help',
    component: HelpView,
    isPrivate: true,
    header: true,
    mobileMenu: true,
    mobileMenuIcon: HelpIcon,
  },
  {
    path: '/unauthorised',
    title: 'Unauthorised',
    component: UnauthorisedPageView,
    isPrivate: true,
    mobileMenu: false,
  },
  {
    path: '*',
    component: Redirect,
    props: {
      to: '/error',
    },
    isPrivate: false,
  },
];

export default routesConstruction;
