import React from 'react';

const MetroTruckUnplanned = ({ className }) => {
  return (
    <svg
      id="Unplanned_Vehicle"
      data-name="Unplanned Vehicle"
      xmlns="http://www.w3.org/2000/svg"
      width="36.923"
      height="30"
      viewBox="0 0 36.923 30"
      className={className}
    >
      <path
        id="Subtraction_2"
        data-name="Subtraction 2"
        d="M-15934-6879a4.62,4.62,0,0,1-4.615-4.615,4.609,4.609,0,0,1,.621-2.308h-12.777a4.608,4.608,0,0,1,.617,2.308,4.62,4.62,0,0,1-4.615,4.615,4.62,4.62,0,0,1-4.615-4.615,4.609,4.609,0,0,1,.621-2.308h-2.929l-2.308-2.308v-18.462a2.31,2.31,0,0,1,2.308-2.308h20.77a2.31,2.31,0,0,1,2.308,2.308v4.616h6.923l4.615,9.23v6.923H-15930a4.608,4.608,0,0,1,.617,2.308A4.62,4.62,0,0,1-15934-6879Zm-17.5-15a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0-15951.5-6894Zm12.885-5.769v6.923h8.245l-3.462-6.923ZM-15951.5-6905a1.5,1.5,0,0,0-1.5,1.5v6a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6A1.5,1.5,0,0,0-15951.5-6905Z"
        transform="translate(15964 6908.999)"
      />
    </svg>
  );
};
export default MetroTruckUnplanned;
