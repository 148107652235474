import BusinessImg from '../../../images/Business.svg';
import ScreenImg from '../../../images/Screen.svg';
import TrafficMarshalImg from '../../../images/TrafficMarshal.svg';
import inputRefConfig from '../../../shared/inputRef.config';
import SharedInputField from '../components/SharedInputField';
import CountryPhoneNumberInput from '../../../shared/CountryPhoneNumber/CountryPhoneNumberInput';

// note: Traffic Marshall & Haulier Driver roles not neccessary for Web App
const userTypeAddUserPermissions = [
  {
    group: 'Site-Organisation-Admin',
    addUserTypePermissions: [
      'Site-Organisation-Admin',
      'Site-Manager',
      'Site-Traffic-Marshal',
    ],
  },
  {
    group: 'Site-Manager',
    addUserTypePermissions: ['Site-Manager', 'Site-Traffic-Marshal'],
  },
  {
    group: 'Haulier-Admin',
    addUserTypePermissions: ['Haulier-Admin', 'Haulier-Driver'],
  },
];

export const userTypeConfig = [
  {
    group: 'Site-Organisation-Admin',
    img: BusinessImg,
    title: 'Organisation Admin',
    userFeatures: [
      'Access the Site Web App for all sites in an Organisation',
      'Manage User Roles and Access for the Organisation',
    ],
    fields: [
      {
        component: SharedInputField,
        props: {
          autoFocus: true,
          name: 'firstName',
          label: 'First Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: SharedInputField,
        props: {
          name: 'lastName',
          label: 'Last Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: SharedInputField,
        props: {
          name: 'email',
          label: 'Email*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.emailInput,
      },
    ],
    addToSite: false,
  },
  {
    group: 'Site-Manager',
    img: ScreenImg,
    title: 'Site Manager',
    userFeatures: [
      'Access the Site Web & Mobile Apps',
      'Register Bookings',
      'Monitor and Coordinate Site Bookings in Real-Time',
      'Manage User Roles and Access',
    ],
    fields: [
      {
        component: SharedInputField,
        props: {
          autoFocus: true,
          name: 'firstName',
          label: 'First Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: SharedInputField,
        props: {
          name: 'lastName',
          label: 'Last Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: SharedInputField,
        props: {
          name: 'email',
          label: 'Email*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.emailInput,
      },
    ],
    addToSite: true,
  },
  {
    group: 'Site-Traffic-Marshal',
    img: TrafficMarshalImg,
    title: 'Traffic Marshal',
    userFeatures: [
      'Access the Site Mobile App',
      'Coordinate Vehicles in Real-Time',
      'Booking Unplanned Vehicles',
      'Mark Bookings as Complete / Rejected',
    ],
    fields: [
      {
        component: SharedInputField,
        props: {
          autoFocus: true,
          name: 'firstName',
          label: 'First Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: SharedInputField,
        props: {
          name: 'lastName',
          label: 'Last Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: CountryPhoneNumberInput,
        props: {
          name: 'number',
        },
        inputRefConfig: inputRefConfig.numberInput,
      },
    ],
    addToSite: true,
  },
  {
    group: 'Haulier-Admin',
    img: ScreenImg,
    title: 'Admin',
    userFeatures: [
      'Access Haulier Web App',
      'Manage Bookings for All Connected Sites',
      'Manage User Roles and Vehicles',
    ],
    fields: [
      {
        component: SharedInputField,
        props: {
          autoFocus: true,
          name: 'firstName',
          label: 'First Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: SharedInputField,
        props: {
          name: 'lastName',
          label: 'Last Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: SharedInputField,
        props: {
          name: 'email',
          label: 'Email*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.emailInput,
      },
    ],
    addToSite: false,
  },
  {
    group: 'Haulier-Driver',
    img: TrafficMarshalImg,
    title: 'Driver',
    userFeatures: [
      'Access Driver Mobile App',
      'Receive and Complete Bookings',
      'Respond to Site’s Instruction in Real-Time',
    ],
    fields: [
      {
        component: SharedInputField,
        props: {
          autoFocus: true,

          name: 'firstName',
          label: 'First Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: SharedInputField,
        props: {
          name: 'lastName',
          label: 'Last Name*',
          variant: 'outlined',
          type: 'text',
        },
        inputRefConfig: inputRefConfig.nameInput,
      },
      {
        component: CountryPhoneNumberInput,
        props: {
          name: 'number',
          label: 'Mobile Number*',
          variant: 'outlined',
          type: 'text',
          defaultValue: '+44',
        },
        inputRefConfig: inputRefConfig.numberInput,
      },
    ],
    addToSite: false,
  },
];

export function getuserTypeConfigFromCurrentUserPermissions(
  currentUserPermissions
) {
  const availableGroupsArray = userTypeAddUserPermissions.filter(
    (entry) => entry.group === currentUserPermissions
  )[0].addUserTypePermissions;

  return userTypeConfig.filter((entry) => {
    return availableGroupsArray.includes(entry.group);
  });
}
