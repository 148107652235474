import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useCallApi from '../../../hooks/useCallApi';
import ClickWaitButton from '../../../shared/ClickWaitButton';

const HaulierTableButton = ({
  haulierID,
  refreshHauliersList,
  className,
  apiToCall,
  children,
}) => {
  const [apiCalling, setApiCalling] = useState(false);

  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const selectedSiteId = selectedSiteStore.id;

  const successCallback = () => {
    if (refreshHauliersList) refreshHauliersList();
  };

  const caughtErrorCallBack = () => {
    setApiCalling(false);
  };

  const [haulierDisconnecting] = useCallApi(
    apiToCall,
    [selectedSiteId, haulierID],
    { condition: apiCalling, successCallback, caughtErrorCallBack }
  );

  return (
    <ClickWaitButton
      className={className}
      apiCalling={haulierDisconnecting}
      clickFunction={() => {
        setApiCalling(true);
      }}
    >
      {children}
    </ClickWaitButton>
  );
};

export default HaulierTableButton;
