import React, { useState } from 'react';
import DropDownContent from './Dropdown/DropDownContent';
import useGetUserType from '../../../../hooks/useGetUserType';
import { getHHMMFormattedDateFromTimeString } from '../../../../utils/timeFunctions';
import UnplannedModalDetails from './Components/UnplannedModalDetails';
import PlannedModalDetails from './Components/PlannedModalDetails';
import RecurringModalDetails from './Components/RecurringModalDetails';
import DetailsButtonContainer from './Components/DetailsButtonContainer';

const BookingDetails = ({ cardModalData }) => {
  if (cardModalData.recurrent) {
    return <RecurringModalDetails cardModalData={cardModalData} />;
  }

  if (cardModalData.type === 'PLANNED')
    return <PlannedModalDetails cardModalData={cardModalData} />;

  return <UnplannedModalDetails cardModalData={cardModalData} />;
};

const BookingModalDetails = ({ cardModalData }) => {
  const [selectedForEdit, setSelectedForEdit] = useState(false);
  const currentUserType = useGetUserType();

  const dateModified = getHHMMFormattedDateFromTimeString(
    cardModalData.date_modified
  );
  const recordCreated = cardModalData.date_created;
  const recordUpdated = cardModalData.date_modified;

  const inTransit = cardModalData.in_transit;
  const isComplete = cardModalData.completed;
  const firstDestination = cardModalData.first_destination;

  const showEditButton = () => {
    return (
      currentUserType !== 'Site-Organisation-Admin' &&
      cardModalData.type === 'PLANNED'
    );
  };

  const { type, status } = cardModalData;

  const getStatusPrefix = () => {
    if (!isComplete) {
      if (recordCreated === recordUpdated) {
        return 'Registered at: ';
      }
      if (type === 'PLANNED') {
        if (firstDestination === status && inTransit) {
          return 'Departed at: ';
        }
        return inTransit ? 'Requested at: ' : 'Arrived at: ';
      }
      return 'Sent at: ';
    }

    if (status === 'rejected') {
      return 'Rejected at: ';
    }

    return 'Completed at: ';
  };

  return (
    <>
      <div
        className={`booking-modal__details-container ${
          selectedForEdit ? '--selected-for-edit' : ''
        }`}
      >
        <div className="booking-modal__details-container-column">
          <BookingDetails cardModalData={cardModalData} />
          {status !== 'new' && (
            <div className="booking-modal__status">
              <span>
                {getStatusPrefix()}
                {dateModified}
              </span>
            </div>
          )}
        </div>
        {showEditButton() && (
          <div className="booking-modal__details-container-column">
            <DetailsButtonContainer
              cardModalData={cardModalData}
              selectedForEdit={selectedForEdit}
              setSelectedForEdit={setSelectedForEdit}
            />
          </div>
        )}
      </div>
      {currentUserType !== 'Site-Organisation-Admin' && (
        // dropdown content shouldn't be shown to Org Admins.
        <DropDownContent cardModalData={cardModalData} />
      )}
    </>
  );
};
export default BookingModalDetails;
