import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import LoadingCircle from '../../../shared/LoadingCircle';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';
import inputRefConfig from '../../../shared/inputRef.config';

const OrganisationAddress = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onTouched',
  });
  const dispatch = useDispatch();
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const organisationDetails = currentUserStore.organisation;
  const organisationAddressPostCode = useRef({});
  organisationAddressPostCode.current = watch(
    'organisationAddressPostCode',
    ''
  );

  const onSubmit = (data) => {
    const { subRouteProgress } = viewStore;

    if (formValidated) {
      setFormSubmitting(true);
      dispatch({
        type: 'SET_CURRENT_USER_ORGANISATION_DETAILS',
        payload: {
          ...organisationDetails,
          address: {
            line_1: data.organisationAddressLineOne,
            county: data.organisationAddressCounty,
            city: data.organisationAddressCity,
            postcode: data.organisationAddressPostCode,
            country_code: 'GB',
          },
        },
      });
      dispatch({
        type: 'SET_SUB_ROUTE_PROGRESS',
        payload: subRouteProgress + 1,
      });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const errorsLength = Object.keys(errors).length;
    if (errorsLength === 0 && organisationAddressPostCode.current.length > 1) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h6" component="h4" gutterBottom>
            What is the address of your Business?
          </Typography>

          <TextField
            autoFocus
            inputRef={register(inputRefConfig.addressInput)}
            name="organisationAddressLineOne"
            label="Street*"
            type="text"
            variant="outlined"
          />

          <FormErrorMessage errors={errors.organisationAddressLineOne} />

          <TextField
            inputRef={register(inputRefConfig.addressInput)}
            name="organisationAddressCity"
            label="City / Town*"
            type="text"
            variant="outlined"
          />

          <FormErrorMessage errors={errors.organisationAddressCity} />

          <TextField
            inputRef={register(inputRefConfig.addressInput)}
            name="organisationAddressCounty"
            label="County*"
            type="text"
            variant="outlined"
          />

          <FormErrorMessage errors={errors.organisationAddressCounty} />

          <TextField
            inputRef={register(inputRefConfig.postcodeInput)}
            name="organisationAddressPostCode"
            label="Postcode*"
            variant="outlined"
          />

          <FormErrorMessage errors={errors.organisationAddressPostCode} />

          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
};
export default OrganisationAddress;
