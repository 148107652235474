import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ClickWaitButton from '../../../shared/ClickWaitButton';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import { callSiteUserRequest } from '../../../api/kerb-construction';
import ButtonDoneAssignUsers from '../components/ButtonDoneAssignUsers';

function AvailableTableRow({ user, setNewUserConnected }) {
  const [apiCalling, setApiCalling] = useState(false);
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const dispatch = useDispatch();
  const handleErrorResponse = useHandleErrorResponse();

  function handleUserConnect(userId) {
    const siteId = selectedSiteStore.id;
    setApiCalling(true);

    callSiteUserRequest(userId, siteId)
      .then((res) => {
        if (!res.ok) {
          throw res.json();
        }
        return res.json();
      })
      .then(() => {
        setApiCalling(false);
        setNewUserConnected(true);

        dispatch({
          type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
          payload: ButtonDoneAssignUsers,
        });
      })
      .catch((err) => {
        setApiCalling(false);
        handleErrorResponse(err);
      });
  }

  return (
    <TableRow>
      <TableCell>{user.name}</TableCell>
      <TableCell className="cell-with-button">
        <ClickWaitButton
          apiCalling={apiCalling}
          clickFunction={() => {
            handleUserConnect(user.id);
          }}
        >
          Connect
        </ClickWaitButton>
      </TableCell>
    </TableRow>
  );
}

export default AvailableTableRow;
