import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';

const UserTypeButton = ({ userType }) => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [infoClicked, setInfoClicked] = useState(false);
  const viewStore = useSelector((state) => state.viewStore);
  const dispatch = useDispatch();

  function handleGroupSelect(newSelectedUserType) {
    const { subRouteProgress } = viewStore;
    setSelectedGroup(newSelectedUserType);
    dispatch({
      type: 'SET_NEW_USER_TYPE',
      payload: newSelectedUserType,
    });

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: subRouteProgress + 1,
    });
  }

  function handleInfoClick(ev) {
    ev.stopPropagation();

    if (ev.keyCode === 13 || ev.type === 'click') {
      ev.preventDefault();
      setInfoClicked(!infoClicked);
    }
  }

  function getClassName() {
    let classNameString = '';
    classNameString =
      userType.group === selectedGroup
        ? 'button --square-image-button --selected'
        : 'button --square-image-button';

    classNameString = infoClicked
      ? `${classNameString} --active`
      : classNameString;

    return classNameString;
  }

  return (
    <button
      type="button"
      className={getClassName()}
      data-group={userType.group}
      onClick={() => {
        handleGroupSelect(userType.group);
      }}
    >
      <h4>{userType.title}</h4>
      {infoClicked ? (
        <div className="manage-user-view__info-container">
          <p>
            <b>This user will be able to:</b>
          </p>
          <ul>
            {userType.userFeatures.map((feature, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i}>{feature}</li>
            ))}
          </ul>
        </div>
      ) : (
        <img src={userType.img} alt={userType.title} />
      )}
      <div
        tabIndex="0"
        role="button"
        aria-label={`More Information for ${userType.title}`}
        className="manage-user-view__info-button"
        onClick={(ev) => {
          handleInfoClick(ev);
        }}
        onKeyDown={(ev) => {
          handleInfoClick(ev);
        }}
      >
        <InfoIcon />
      </div>
    </button>
  );
};

export default UserTypeButton;
