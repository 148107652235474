import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { format, min } from 'date-fns';
import LoadingCircle from '../../LoadingCircle';
import MaterialField from '../components/MaterialField';
import MultipleDatePicker from '../components/MultipleDatePicker';
import AvailableHauliersDropDown from '../components/AvailableHauliersDropDown';
import AvailableSiteEntrances from '../components/AvailableSiteEntranceFieldWithButtons';
import AvailableFirstDestinations from '../components/AvailableFirstDestinationWithButtons';
import isBookingFormValid from '../functions/bookingFormFunctions';
import useConditionalCallApi from '../../../hooks/useConditionalCallApi';
import { callCreateBooking } from '../../../api/kerb-construction';

const NewRecurringBookingDetailsForm = ({
  bookingFormState,
  setBookingFormState,
}) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [bookingParameters, setBookingParameters] = useState({});

  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const siteID = selectedSiteStore.id;

  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();

  const successfulResponseCallback = () => {
    history.push('/operationoverview');
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
  };

  const [formLoading] = useConditionalCallApi(
    callCreateBooking,
    [bookingParameters],
    formSubmitting,
    successfulResponseCallback
  );

  const submitFormData = () => {
    const {
      recurrentDates,
      material,
      selectedHaulier,
      firstDestination,
      siteEntrance,
    } = bookingFormState;

    const formattedDates = recurrentDates.map((day) =>
      format(day, 'yyyy-MM-dd')
    );
    const dueStart = `${format(min(recurrentDates), 'yyyy-MM-dd')} 00:00`;
    const dueEnd = `${format(min(recurrentDates), 'yyyy-MM-dd')} 01:00`;
    const formattedDateString = formattedDates.toString();
    const recurrentName = `Recur. ${material}`;

    setBookingParameters({
      site_id: siteID,
      haulier_id: selectedHaulier.haulier_id,
      type: 'PLANNED',
      recurrent: true,
      recurrent_days: formattedDateString,
      recurrent_name: recurrentName,
      due_start: dueStart,
      due_end: dueEnd,
      material,
      first_destination: firstDestination,
      site_entrance: siteEntrance,
    });

    setFormSubmitting(true);
  };

  useEffect(() => {
    setBookingFormState({
      ...bookingFormState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsFormValid(isBookingFormValid('new__recurrent', bookingFormState));
  });

  return (
    <form
      className="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(submitFormData)}
    >
      <div className="form__form-inner">
        <Typography variant="h5" component="h4" gutterBottom>
          Please enter the Booking Details
        </Typography>

        <MultipleDatePicker
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
        />

        <MaterialField
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
        />

        <AvailableHauliersDropDown
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
        />

        <AvailableSiteEntrances
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
        />

        {bookingFormState.siteEntrance && (
          <AvailableFirstDestinations
            bookingFormState={bookingFormState}
            setBookingFormState={setBookingFormState}
          />
        )}

        {!formLoading ? (
          <Button
            disabled={!isFormValid}
            type="submit"
            variant="contained"
            color="primary"
          >
            Add Booking
          </Button>
        ) : (
          <LoadingCircle />
        )}
      </div>
    </form>
  );
};
export default NewRecurringBookingDetailsForm;
