import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import VehicleAssignTable from './VehicleAssignTable';
import DriverAssignTable from './DriverAssignTable';

// eslint-disable-next-line consistent-return
const AssigmentTableSwitch = ({ assignmentType }) => {
  switch (assignmentType) {
    case 'vehicle':
      return <VehicleAssignTable />;
    case 'driver':
      return <DriverAssignTable />;
    default:
      break;
  }
};

const AssigmentTable = ({ assignmentType }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // clear store on component load
    dispatch({
      type: 'RESET_PATCH_BOOKING_PARAM',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="assign-booking-view__assignment-table-container">
      <div className="container">
        <AssigmentTableSwitch assignmentType={assignmentType} />
      </div>
    </div>
  );
};

export default AssigmentTable;
