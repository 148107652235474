import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { clearLocalStorage } from '../../utils/functions';
import FormFieldAnimationContainer from '../../containers/FormFieldAnimationContainer';

const LandingViewConstruction = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    clearLocalStorage();
    dispatch({
      type: 'RESET_STORE',
    });
    dispatch({
      type: 'SET_CURRENT_USER_IS_NOT_HAULIER',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <form className="form">
        <div className="form__form-inner">
          <FormFieldAnimationContainer>
            <Typography variant="h5" component="h4" gutterBottom>
              Construction
            </Typography>
            <button
              type="button"
              className="button --full-width"
              onClick={() => {
                history.push('/login');
              }}
            >
              Log In
            </button>
            <button
              type="button"
              className="button --full-width"
              onClick={() => {
                history.push('/register');
              }}
            >
              Register
            </button>
            <Link className="u-helper-text" to="/haulier">
              Not A Construction Site? Click here to visit Haulier
            </Link>
          </FormFieldAnimationContainer>
        </div>
      </form>
    </main>
  );
};

export default LandingViewConstruction;
