import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { callSignUp } from '../../api/auth';
import { configureAmplify } from '../../utils/functions';
import useHandleErrorResponse from '../../hooks/useHandleErrorResponse';
import RegisterForm from './forms/RegisterForm';

const RegisterView = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleErrorResponse = useHandleErrorResponse();

  const onSubmit = useCallback(
    (data) => {
      const { isHaulier } = currentUserStore;

      setFormSubmitting(true);
      configureAmplify(isHaulier);
      callSignUp(data)
        .then(() => {
          dispatch({
            type: 'SET_CURRENT_USER_DETAILS',
            payload: data,
          });
          history.push('/confirm');
        })
        .catch((err) => {
          handleErrorResponse(err);
          setFormSubmitting(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <main>
      <RegisterForm formSubmitting={formSubmitting} onSubmit={onSubmit} />
    </main>
  );
};
export default RegisterView;
