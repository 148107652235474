import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getAlphabeticallySortedArray } from '../../../utils/functions';
import SearchForm from '../../../shared/SearchForm';
import AvailableHauliersTableRow from './rows/AvailableHauliersTableRow';

const AvailableHauliersTable = ({
  availableHauliers,
  availableHauliersLoaded,
  refreshHauliersList,
}) => {
  const [availableHaulierFilter, setAvailableHaulierFilter] = useState('');

  const handleAvailable = (event) => {
    setAvailableHaulierFilter(event.target.value);
  };

  const filterHauliers = (haulierList, filter) => {
    const sortedHaulierList = getAlphabeticallySortedArray(haulierList, 'name');
    return filter
      ? sortedHaulierList.filter((haulier) =>
          haulier.name.toLowerCase().includes(filter.toLowerCase())
        )
      : sortedHaulierList;
  };

  if (!availableHauliersLoaded) {
    return (
      <div className="connect-haulier__table-container">
        <div className="connect-haulier__table-container-contents">
          <Typography
            variant="h6"
            component="h6"
            gutterBottom
            className="u-highlight-title "
          >
            Available Hauliers
          </Typography>
          <LinearProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="connect-haulier__table-container">
      <div className="connect-haulier__table-container-contents">
        <Typography
          variant="h6"
          component="h6"
          gutterBottom
          className="u-highlight-title "
        >
          Available Hauliers
        </Typography>

        {availableHauliers.length > 0 ? (
          <>
            <div className="connect-haulier__filter">
              <SearchForm
                label="Search Available Hauliers"
                value={availableHaulierFilter}
                onChange={handleAvailable}
              />
            </div>

            <TableContainer className="mui-table">
              <Table stickyHeader aria-label="haulier sites table">
                <TableHead>
                  <TableRow>
                    <TableCell>Organisation Name</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterHauliers(
                    availableHauliers,
                    availableHaulierFilter
                  ).map((haulier) => {
                    return (
                      <AvailableHauliersTableRow
                        key={haulier.id}
                        haulier={haulier}
                        refreshHauliersList={refreshHauliersList}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          'You currently do not have any Hauliers available to connect to your site.'
        )}
      </div>
    </div>
  );
};

export default AvailableHauliersTable;
