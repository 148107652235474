const initialState = {
  selectedDateString: null,
};

const scheduleStore = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_SCHEDULE_DATE_STRING':
      return {
        ...state,
        selectedDateString: action.payload,
      };

    default:
      return state;
  }
};
export default scheduleStore;
