// eslint-disable-next-line import/prefer-default-export

/**
 * returns an array of Site statuses in order of display order, without completion columns.
 * @param {Object} siteConfig
 * @return {Array} ordered site entrances to be rendered as columns.
 */
export function getProcessedColumnConfiguration(siteConfig) {
  // sort entrances by display_order
  const orderedSiteConfig = Object.entries(siteConfig.statuses).sort((a, b) => {
    return a[1].display_order - b[1].display_order;
  });
  // remove completion columns as this is hard coded
  const siteConfigWithSingleCompleteArray = orderedSiteConfig.filter(
    (entry) => {
      return !entry[1].completion_type;
    }
  );

  return siteConfigWithSingleCompleteArray;
}

/**
 * returns an array of completed statuses from the site config.
 * @param {Object} siteConfig
 * @return {Array} completed status types
 */
export function getCompletionStatusesArray(siteConfig) {
  const completedStatusEntries = Object.entries(siteConfig.statuses).filter(
    (entry) => entry[1].completion_type
  );
  const completedStatusArray = completedStatusEntries.map((entry) => entry[0]);

  return completedStatusArray;
}

export function getSiteEntrancesArray(siteConfig) {
  const statusesWithEntrances = Object.entries(siteConfig.statuses).filter(
    (entry) => entry[1].entrance_statuses
  );

  return statusesWithEntrances;
}
export function getSelectedEntranceFirstDestinations(
  siteConfig,
  selectedSiteEntrance
) {
  return siteConfig.statuses[selectedSiteEntrance].entrance_statuses;
}

export function getAvailableSiteEntranceBookingSlotsArray(
  siteConfig,
  selectedSiteEntrance
) {
  if (!selectedSiteEntrance) return null;
  return siteConfig.statuses[selectedSiteEntrance].slots;
}

// retrieves array of statuses with 'capacity' key from config
export function getAvailableBayStatusesWithCapacity(
  selectedSiteConfigStatuses
) {
  return Object.entries(selectedSiteConfigStatuses).filter((status) =>
    Object.prototype.hasOwnProperty.call(status[1], 'capacity')
  );
}

export const calculateFinalColumnName = (statuses) => {
  const statusNames = Object.keys(statuses);

  const completedStatusName = statusNames.filter(
    (statusName) =>
      'completion_type' in statuses[statusName] &&
      statuses[statusName].completion_type === 'COMPLETION'
  );

  return completedStatusName[0];
};
