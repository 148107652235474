import React, { useState } from 'react';
import { callPostBookingNote } from '../../../../../api/kerb-construction';
import useHandleErrorResponse from '../../../../../hooks/useHandleErrorResponse';
import LoadingCircle from '../../../../../shared/LoadingCircle';

const BookingNoteForm = ({ bookingID }) => {
  const [note, setNote] = useState('');
  const [formActive, setFormActive] = useState(false);
  const [sendingNote, setSendingNote] = useState(false);

  const onChange = (e) => {
    setNote(e.target.value);
  };

  const focusOnForm = (e) => {
    e.preventDefault();
    setFormActive(true);
  };

  const handleErrorResponse = useHandleErrorResponse();

  const onSubmit = (e) => {
    e.preventDefault();
    if (note.length > 0) {
      setSendingNote(true);
      callPostBookingNote(bookingID, note)
        .then((response) => {
          if (!response.ok) {
            throw response.json();
          }
          return response.json();
        })
        .then(() => {
          setSendingNote(false);
        })
        .catch((err) => {
          setSendingNote(false);
          handleErrorResponse(err);
        });
    }
    setNote('');
  };

  return (
    <form className="booking-modal__notes-form" onSubmit={onSubmit}>
      <input
        placeholder="Add a new note..."
        onChange={onChange}
        value={note}
        onClick={focusOnForm}
        className="booking-modal__notes-form__input"
      />
      {formActive &&
        (sendingNote ? (
          <div className="booking-modal__notes-form__loading-circle">
            <LoadingCircle />
          </div>
        ) : (
          <button
            className="button booking-modal__notes-form__button"
            type="submit"
            onClick={onSubmit}
          >
            Add Note
          </button>
        ))}
    </form>
  );
};
export default BookingNoteForm;
