import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import LinearProgress from '@material-ui/core/LinearProgress';
import BookingScheduleDayPicker from './Components/BookingScheduleDayPicker';
import BookingScheduleHeading from './Components/BookingScheduleHeading';
import BookingIconKeys from '../../shared/BookingIconKeys';
import UpcomingBookingsTable from './Tables/UpcomingBookingsTable';
import { callGetBookingsByDate } from '../../api/kerb-construction';
import CompletedBookingsTable from './Tables/CompletedBookingsTable';
import useCallApi from '../../hooks/useCallApi';

const BookingScheduleView = () => {
  const todaysDate = new Date();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const siteId = selectedSiteStore.id;

  const [loading, bookings] = useCallApi(
    callGetBookingsByDate,
    [formattedDate, siteId],
    { dependencies: [formattedDate] }
  );

  const upcomingBookings = () => {
    return bookings.filter((booking) => {
      return !booking.completed;
    });
  };

  const completedBookings = () => {
    return bookings.filter((booking) => {
      return booking.completed;
    });
  };

  const completedBookingsTable = () => {
    return isSameDay(todaysDate, selectedDate) ? (
      <CompletedBookingsTable bookings={completedBookings()} />
    ) : null;
  };

  return (
    <main className="table-view booking-schedule-view">
      <div className="container">
        <BookingScheduleHeading />
        <BookingIconKeys
          showPlanned
          showRecurring
          showUnplanned
          showInTransit
        />
        <BookingScheduleDayPicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />

        <div className="booking-schedule-view__table-container">
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <UpcomingBookingsTable bookings={upcomingBookings()} />
              {completedBookingsTable()}
            </>
          )}
        </div>
      </div>
    </main>
  );
};
export default BookingScheduleView;
