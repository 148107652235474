import React from 'react';
import { useHistory } from 'react-router-dom';
import BookingScheduleIcon from '../../../images/Components/BookingSchedule';

const BookingScheduleButton = ({ className }) => {
  const history = useHistory();
  const handleOverviewButtonPress = (e) => {
    e.preventDefault();

    history.push('/bookingschedule');
  };

  return (
    <button
      type="button"
      className={`${className} button`}
      onClick={handleOverviewButtonPress}
    >
      <BookingScheduleIcon className="schedule-view__booking-schedule-link-icon" />
    </button>
  );
};

export default BookingScheduleButton;
