import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import SingleRowBookingTable from './tables/SingleRowBookingTable';
import BookingNotesForm from './components/BookingNotesForm';
import BookingNotesList from './components/BookingNotesList';
import useCallApi from '../../hooks/useCallApi';
import { callGetBookingNotes } from '../../api/kerb-construction';

const BookingNotes = () => {
  const editBookingStore = useSelector((state) => state.editBookingStore);
  const selectedBooking = editBookingStore.editBookingDetails;
  const selectedBookingId = selectedBooking.id;

  const [sendingNote, setSendingNote] = useState(false);

  const [reloadTrigger, setReloadTrigger] = useState(0);

  const incrementReloadTrigger = () => {
    setReloadTrigger(reloadTrigger + 1);
  };

  const [loading, response] = useCallApi(
    callGetBookingNotes,
    [selectedBookingId],
    { dependencies: [reloadTrigger] }
  );

  return (
    <main className="assign-booking-view">
      <div className="assign-booking-view__booking-summary">
        <div className="container">
          <Typography
            variant="h6"
            component="h6"
            gutterBottom
            className="u-highlight-title"
          >
            Please enter the Note that you would like to attach to this
            delivery:
          </Typography>
          <SingleRowBookingTable booking={selectedBooking} />
        </div>
      </div>
      <div className="assign-booking-view__booking-notes">
        <div className="container">
          <BookingNotesForm
            bookingID={selectedBookingId}
            sendingNote={sendingNote}
            setSendingNote={setSendingNote}
            incrementReloadTrigger={incrementReloadTrigger}
          />
          <BookingNotesList bookingNotes={response} loading={loading} />
        </div>
      </div>
    </main>
  );
};

export default BookingNotes;
