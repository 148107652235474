import React from 'react';

const FirstDestination = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
    >
      <path
        id="Subtraction_22"
        data-name="Subtraction 22"
        d="M-20561-15713.5v-5h-10v-10h10v-5l10,10-10,10Zm-6.873-11.445v0l-.02.591-.01.547v3.3h1.207v-5.71h-.99l-1.844,1.461.584.729.654-.526c.074-.06.2-.182.416-.4Zm5.2-.584v1.229a.923.923,0,0,0,.191.636.78.78,0,0,0,.6.211,1.593,1.593,0,0,0,.641-.118v-.517a1.554,1.554,0,0,1-.439.077.291.291,0,0,1-.217-.072.26.26,0,0,1-.082-.217v-1.229h.713v-.521h-.713v-.542h-.447l-.2.537-.383.232v.294Zm-2.545,1.352h0v.573a1.972,1.972,0,0,0,.367.108,2.385,2.385,0,0,0,.475.041,1.36,1.36,0,0,0,.811-.206.7.7,0,0,0,.273-.6.808.808,0,0,0-.062-.325.687.687,0,0,0-.207-.243,2.157,2.157,0,0,0-.437-.227,2.567,2.567,0,0,1-.434-.2.183.183,0,0,1-.1-.159c0-.11.1-.166.3-.166a1.094,1.094,0,0,1,.34.052,3,3,0,0,1,.355.135l.213-.5a2.1,2.1,0,0,0-.9-.2,1.281,1.281,0,0,0-.744.186.608.608,0,0,0-.264.526.807.807,0,0,0,.063.331.622.622,0,0,0,.2.242,2.321,2.321,0,0,0,.434.228c.137.058.246.11.326.149a.886.886,0,0,1,.176.119.2.2,0,0,1,.045.134c0,.146-.123.217-.377.217a1.789,1.789,0,0,1-.428-.062,2.922,2.922,0,0,1-.434-.15Z"
        transform="translate(20571 15733.498)"
        fill="#253137"
      />
    </svg>
  );
};
export default FirstDestination;
