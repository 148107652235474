import React from 'react';
import { useSelector } from 'react-redux';
import Column from './Column';

import {
  getFilteredColumnData,
  getSelectedDateFilteredBookings,
  getCompletedColumnBookingData,
} from '../../../utils/bookingFunctions';
import {
  getProcessedColumnConfiguration,
  getCompletionStatusesArray,
  calculateFinalColumnName,
} from '../../../utils/siteConfigFunctions';

const ScheduleBoard = ({ bookingData }) => {
  const scheduleStore = useSelector((state) => state.scheduleStore);
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const { selectedDateString } = scheduleStore;
  const { config } = selectedSiteStore;
  const { statuses } = config;

  const processedColumnConfiguration = getProcessedColumnConfiguration(
    selectedSiteStore.config
  );

  const completionStatusesArray = getCompletionStatusesArray(
    selectedSiteStore.config
  );

  const completedColumnBookingData = getCompletedColumnBookingData(
    bookingData,
    completionStatusesArray
  );

  const selectedDateFilteredCompleteBookings = getSelectedDateFilteredBookings(
    completedColumnBookingData,
    selectedDateString
  );

  const howManyColumns = processedColumnConfiguration.length + 1;

  return (
    <div className="schedule-view__column-container">
      {processedColumnConfiguration.map(([columnStatus]) => {
        const columnName =
          columnStatus === selectedSiteStore.config.initial_status
            ? 'Schedule'
            : columnStatus;

        const columnBookingData = getFilteredColumnData(
          bookingData,
          columnStatus
        );
        const selectedDateFilteredBookings = getSelectedDateFilteredBookings(
          columnBookingData,
          selectedDateString
        );

        return (
          <Column
            key={columnStatus}
            columnStatus={columnStatus}
            columnName={columnName}
            columnBookingData={selectedDateFilteredBookings}
            howManyColumns={howManyColumns}
          />
        );
      })}

      <Column
        key="completed"
        columnStatus={calculateFinalColumnName(statuses)}
        columnName="History"
        columnBookingData={selectedDateFilteredCompleteBookings}
        howManyColumns={howManyColumns}
      />
    </div>
  );
};

export default ScheduleBoard;
