import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import FirstDestination from '../../../../../images/Components/FirstDestination';

const RecurringModalDetails = ({ cardModalData }) => {
  const formattedPlannedBookingDate = format(
    parseISO(cardModalData.due_start),
    'dd/MM/yyyy'
  );

  return (
    <div className="booking-modal__details">
      <span>
        Material: <b>{cardModalData.material}</b>
      </span>
      <span>
        Company: <b>{cardModalData.haulier_name}</b>
      </span>
      <span>
        Date: <b>{formattedPlannedBookingDate}</b>
      </span>
      <span className="booking-modal__first-destination">
        <FirstDestination className="booking-modal__first-destination-icon" />
        {cardModalData.first_destination}
      </span>
    </div>
  );
};

export default RecurringModalDetails;
