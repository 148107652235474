import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import BookingOverviewIcon from '../../../images/Components/BookingOverview';

const BookingScheduleHeading = () => {
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const history = useHistory();

  const handleOverviewButtonPress = (e) => {
    e.preventDefault();

    history.push('/operationoverview');
  };

  return (
    <div className="booking-schedule-view__header">
      <Typography
        variant="h6"
        component="h6"
        gutterBottom
        className="u-highlight-title booking-schedule-view__heading"
      >
        Booking Schedule For {selectedSiteStore.name}.
      </Typography>

      <button
        type="button"
        className="booking-schedule-view__overview-link-button button"
        onClick={handleOverviewButtonPress}
      >
        <BookingOverviewIcon className="booking-schedule-view__overview-link-icon" />
      </button>
    </div>
  );
};
export default BookingScheduleHeading;
