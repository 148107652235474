/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import BookingNotes from './BookingNotes';
import BookingNoteForm from './BookingNoteForm';
import BookingActivityLog from './BookingActivityLog';
import ArrowDown from '../../../../../images/Components/ArrowDown';

const DropDownContent = ({ cardModalData }) => {
  const [dropdownStates, setDropdownStates] = useState([true, false]);

  const handleDropDownToggle = (index) => {
    const newDropdownState = [...dropdownStates];
    newDropdownState.splice(index, 1, !dropdownStates[index]);
    setDropdownStates(newDropdownState);
  };

  const getDropdownStateClassname = (index) => {
    return dropdownStates[index] ? '--dropdown-active' : '--dropdown-inactive';
  };

  return (
    <>
      <hr />
      <div className="booking-modal__notes-container">
        <BookingNoteForm bookingID={cardModalData.id} />
        <BookingNotes bookingID={cardModalData.id} />
      </div>

      <div
        role="button"
        tabIndex="0"
        onClick={() => handleDropDownToggle(1)}
        className={`booking-modal__dropdown-container ${getDropdownStateClassname(
          1
        )}`}
      >
        <header>
          <h4>Activity Log</h4>
          <ArrowDown className="dropdown-arrow" />
        </header>
        <div className="booking-modal__dropdown-content">
          {dropdownStates[1] && (
            <BookingActivityLog bookingId={cardModalData.id} />
          )}
        </div>
      </div>
    </>
  );
};
export default DropDownContent;
