import React from 'react';
import Typography from '@material-ui/core/Typography';
import UserTypeButton from '../components/UserTypeButton';
import ScreenImg from '../../../images/Screen.svg';
import TrafficMarshalImg from '../../../images/TrafficMarshal.svg';

const ConnectUserTypeForm = ({ setSelectedUserType }) => {
  const userTypes = [
    { title: 'Site Manager', img: ScreenImg, group: 'Site-Manager' },
    {
      title: 'Traffic Marshal',
      img: TrafficMarshalImg,
      group: 'Site-Traffic-Marshal',
    },
  ];

  return (
    <div className="container">
      <Typography
        className="u-text-center"
        variant="h5"
        component="h4"
        gutterBottom
      >
        Please select the role that you would like to manage
      </Typography>

      <div className="connect-users-view__user-type-button-container">
        {userTypes.map((userType) => {
          return React.createElement(UserTypeButton, {
            key: userType.group,
            userType,
            setSelectedUserType,
          });
        })}
      </div>
    </div>
  );
};

export default ConnectUserTypeForm;
