import React, { useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import EventIcon from '@material-ui/icons/Event';
import TextField from '@material-ui/core/TextField';
import add from 'date-fns/add';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const SingleDatePicker = ({ bookingFormState, setBookingFormState }) => {
  const dateNow = new Date();
  const dateOneDayFromNow = add(dateNow, {
    days: 1,
  });

  const selectedDate = bookingFormState.selectedDate || dateOneDayFromNow;

  const setSelectedDate = (newSelectedDate) => {
    setBookingFormState({
      ...bookingFormState,
      selectedDate: newSelectedDate,
    });
  };

  const [datesUpdating, setDatesUpdating] = useState(false);

  const editDates = () => {
    setDatesUpdating(true);
  };

  const confirmDates = () => {
    setDatesUpdating(false);
  };

  const datesTextFieldValue = () => {
    return format(selectedDate, 'EEE do MMMM yyyy');
  };

  const handleDayClick = (day, { selected, disabled }) => {
    if (!selected && !disabled) {
      setSelectedDate(day);
    }
  };

  function formatDate(date, dateFormat, locale) {
    return format(date, dateFormat, { locale });
  }

  function parseDate(str, dateFormat, locale) {
    const parsed = parseISO(str, dateFormat, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  return (
    <>
      {datesUpdating ? (
        <div className="recurrent-booking-date-selection">
          <TextField
            name="date"
            label="Date*"
            variant="outlined"
            focused
            value={datesTextFieldValue()}
            className="recurring-date-picker__date-list"
          />
          <EventIcon className="event-icon" />

          <div className="recurrent-booking-date-selection__date-selector">
            <DayPicker
              selectedDays={[selectedDate]}
              onDayClick={handleDayClick}
              disabledDays={{ before: dateOneDayFromNow }}
              format="YYYY-MM-DD"
              formatDate={formatDate}
              parseDate={parseDate}
            />
            <button type="button" className="button" onClick={confirmDates}>
              Confirm
            </button>
          </div>
        </div>
      ) : (
        <div className="recurrent-booking-date-selection">
          <TextField
            multiline
            autoFocus
            spellCheck={false}
            value={datesTextFieldValue()}
            name="dates"
            label="Dates*"
            variant="outlined"
            inputProps={{ spellCheck: 'false' }}
            onClick={editDates}
          />

          <EventIcon className="event-icon" onClick={editDates} />
        </div>
      )}
    </>
  );
};

export default SingleDatePicker;
