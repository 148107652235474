import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const UserTypeButton = ({ userType, setSelectedUserType }) => {
  const dispatch = useDispatch();
  const viewStore = useSelector((state) => state.viewStore);

  function handleGroupSelect(newUserType) {
    const { subRouteProgress } = viewStore;

    setSelectedUserType(newUserType);
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: subRouteProgress + 1,
    });
  }

  return (
    <button
      type="button"
      className={
        userType.group === userType
          ? 'button --square-image-button --selected'
          : 'button --square-image-button'
      }
      data-group={userType.group}
      onClick={() => {
        handleGroupSelect(userType.group);
      }}
    >
      {userType.title}
      <img src={userType.img} alt={userType.title} />
    </button>
  );
};
export default UserTypeButton;
