import isSameDay from 'date-fns/isSameDay';
import parseISO from 'date-fns/parseISO';
import compareDesc from 'date-fns/compareDesc';

export function removePreExistingBookingEntry(currentData, newData) {
  const processedData = Object.values(currentData).filter((entry) => {
    return entry.id !== newData.id;
  });
  return processedData;
}
/**
 * returns an array of booking records filtered by the status. Doesnt show records with new status, and type UNPLANNEd
 * @param {Object} bookingData
 * @param {String} column status
 * @return {Array} booking Records
 */
export function getFilteredColumnData(bookingData, columnStatus) {
  const filteredColumnData = [];
  if (!bookingData) return filteredColumnData;
  Object.values(bookingData).forEach((booking) => {
    const bookingIsUnplannedAndNew =
      booking.status === 'new' && booking.type === 'UNPLANNED';
    // temporary flag so that we dont show records that are new & unplanned whilst we wait for back end functionality.
    if (bookingIsUnplannedAndNew) return;
    if (booking.status === columnStatus) {
      filteredColumnData.push(booking);
    }
  });

  return filteredColumnData;
}
/**
 *  returns an array of booking records filtered by the selected date using the isSameDay function.
 * @param {array} bookingData
 * @param {String} selectedDateString
 * @return {array} booking records
 */
export function getSelectedDateFilteredBookings(
  bookingData,
  selectedDateString
) {
  const timeFilteredColumnData = [];
  if (!bookingData) return timeFilteredColumnData;

  Object.values(bookingData).forEach((booking) => {
    const parsedBookingComparisonTime = parseISO(
      // check to see if booking is planned or unplanned
      booking.type === 'PLANNED' ? booking.due_start : booking.date_created
    );
    const selectedDateObject = new Date(selectedDateString);

    if (
      isSameDay(selectedDateObject, parsedBookingComparisonTime) &&
      booking.active
    ) {
      timeFilteredColumnData.push(booking);
    }
  });

  return timeFilteredColumnData;
}

/**
 * Returns an array of booking records whos statuses match completion statuses.
 * @param {Object} bookingData as sent from Websockets
 * @param {Array} completion statuses
 * @return {Array} of booking records
 */
export function getCompletedColumnBookingData(
  bookingData,
  completionStatusesArray
) {
  const filteredColumnData = [];

  if (!bookingData) return filteredColumnData;
  Object.values(bookingData).forEach((booking) => {
    if (completionStatusesArray.includes(booking.status))
      filteredColumnData.push(booking);
  });
  return filteredColumnData;
}

export function getFilteredBookingsNotComplete(bookingData) {
  return bookingData.filter((booking) => !booking.completed);
}

export function getFilteredAssignableBookings(bookingData) {
  return bookingData.filter(
    (booking) => !(booking.completed || booking.rejected)
  );
}

/**
 * @param {Array} bookings
 * @return {Array} of booking records that do not have recurrent flag
 */
export function getSingleBookings(bookings) {
  return bookings.filter((booking) => !booking.recurrent);
}

/**
 * @param {Array} bookings
 * @return {Array} of booking records that have recurrent flag
 */
export function getRecurringBookings(bookings) {
  return bookings.filter((booking) => booking.recurrent);
}

/**
 * @param {String} Recurrent booking ID
 * @param {Array} recurringBookings
 * @return {Array} of booking records whose recurrent_id matches the id passed in.
 */
export function getMatchingIdRecurrentBookings(
  recurrentBookingId,
  recurringBookings
) {
  const groupedRecords = recurringBookings.filter(
    (booking) => booking.recurrent_id === recurrentBookingId
  );

  return groupedRecords;
}
/**
 * A large function that creates a new array of records, unique by either the booking.id or booking.reccurent_id
 * depending on the type of booking.
 * @param {ARRAY} bookings
 * @return {ARRAY} unique records
 */
export function getUniqueBookingsById(bookings) {
  const recurringBookings = getRecurringBookings(bookings);

  const singleBookings = getSingleBookings(bookings);

  const uniqueRecords = [];
  // creates an array of unique ID strings from Recurring Booking records
  const uniqueRecurringBookingIds = [
    ...new Set(recurringBookings.map((booking) => booking.recurrent_id)),
  ];
  // creates an array of unique ID strings from single booking records
  const singleBookingIds = [
    ...new Set(singleBookings.map((booking) => booking.id)),
  ];

  // find first unique booking that recurring_id matches id
  uniqueRecurringBookingIds.forEach((id) => {
    uniqueRecords.push(bookings.find((booking) => booking.recurrent_id === id));
  });
  // find first unique booking that record_id matches id
  singleBookingIds.forEach((id) => {
    uniqueRecords.push(bookings.find((booking) => booking.id === id));
  });

  return uniqueRecords;
}

/**
 * A simple function to sort and return bookingrecords based on their due_start, soonest first.
 * @param {ARRAY} bookings
 * @return {ARRAY} bookings
 */
export function getStartTimeOrderedBookings(bookings) {
  bookings.sort((a, b) => {
    const dateA = new Date(a.due_start);
    const dateB = new Date(b.due_start);
    return dateA - dateB;
  });

  return bookings;
}

/**
 * A simple function to sort and return bookingrecords based on their date_modified, most recently modified first.
 * @param {ARRAY} bookings
 * @return {ARRAY} bookings
 */
export function getModifiedTimeOrderedBookings(bookings) {
  bookings.sort((a, b) => {
    const dateA = new Date(a.date_modified);
    const dateB = new Date(b.date_modified);
    return dateB - dateA;
  });

  return bookings;
}

/**
 *  A function that takes in booking records, and returns an array filtered by records with unique site_id
 * @param {Array} bookings
 * @return {Array} Result.
 */
export function getUniqueBookingsBySiteId(bookings) {
  const result = [];
  const map = new Map();
  // eslint-disable-next-line no-restricted-syntax
  for (const item of bookings) {
    if (!map.has(item.site_id)) {
      map.set(item.site_id, true); // set any value to Map
      result.push(item);
    }
  }
  return result;
}

export const descSortedNotesList = (notesArray) => {
  const sortedList = notesArray.sort((firstNote, secondNote) => {
    const parsedFirstDate = parseISO(firstNote.date_created);
    const parsedSecondDate = parseISO(secondNote.date_created);

    return compareDesc(parsedFirstDate, parsedSecondDate);
  });

  return sortedList;
};
