import React from 'react';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import RecurringBookingIcon from '../../../images/Components/MetroTruckRecurring';
import SingleBookingIcon from '../../../images/Components/MetroTruck';
import UnplannedBookingIcon from '../../../images/Components/MetroTruckUnplanned';

const CompletedBookingRow = ({ booking }) => {
  const isBookingRejected = booking.rejected;

  const bookingTypeIcon = () => {
    if (booking.type === 'UNPLANNED') {
      const unplannedClassName = booking.rejected
        ? '--rejected-unplanned'
        : '--completed-unplanned';
      return (
        <UnplannedBookingIcon
          className={`mui-table__booking-type-image ${unplannedClassName}`}
        />
      );
    }

    if (booking.recurrent) {
      const recurrentClassName = booking.rejected
        ? '--rejected-recurring'
        : '--completed-recurring';
      return (
        <RecurringBookingIcon
          className={`mui-table__booking-type-image ${recurrentClassName}`}
        />
      );
    }

    const plannedClassName = booking.rejected
      ? '--rejected-planned'
      : '--completed-planned';

    return (
      <SingleBookingIcon
        className={`mui-table__booking-type-image ${plannedClassName}`}
      />
    );
  };

  const completedAt = () => {
    if (!isBookingRejected) {
      return format(parseISO(booking.date_modified), 'HH:mm');
    }

    return 'N/A';
  };

  const rejectedAt = () => {
    if (isBookingRejected) {
      return format(parseISO(booking.date_modified), 'HH:mm');
    }

    return 'N/A';
  };

  return (
    <TableRow key={booking.id}>
      <TableCell>{bookingTypeIcon()}</TableCell>
      <TableCell>{booking.material}</TableCell>
      <TableCell>{booking.haulier_name}</TableCell>
      <TableCell>{booking.vrm ? booking.vrm : 'No VRM Assigned'}</TableCell>
      <TableCell
        className={isBookingRejected ? 'completed-booking__completed-at' : ''}
      >
        {completedAt()}
      </TableCell>
      <TableCell
        className={!isBookingRejected ? 'completed-booking__rejected-at' : ''}
      >
        {rejectedAt()}
      </TableCell>
    </TableRow>
  );
};

export default CompletedBookingRow;
