/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LoadingCircle from '../../../shared/LoadingCircle';
import { callCreateRegistration } from '../../../api/kerb-construction';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';
import OrganisationEmailConfirmationField from '../components/OrganisationEmailConfirmationField';
import NameConfirmationField from '../components/NameConfirmationField';
import AddressConfirmationField from '../components/AddressConfirmationField';
import InvoiceEmailConfirmationField from '../components/InvoiceEmailConfirmationField';
import NumberConfirmationField from '../components/NumberConfirmationField';
import ForsIdConfirmationField from '../components/ForsIdConfirmationField';

const OrganisationConfirm = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { isHaulier } = currentUserStore;
  const viewStore = useSelector((state) => state.viewStore);
  const dispatch = useDispatch();
  const handleErrorResponse = useHandleErrorResponse();
  const { username } = currentUserStore.details;
  const organisationAddress = currentUserStore.organisation.address;
  const organisationPhone = currentUserStore.organisation.phone;
  const organisationName = currentUserStore.organisation.name;
  const organisationContactEmail = currentUserStore.organisation.contact_email;
  const organisationInvoiceEmail = currentUserStore.organisation.invoice_email;
  const organisationForsIdState = currentUserStore.organisation.forsId;

  const [confirmOrganisationAddressState] = useState(organisationAddress);
  const [confirmOrganisationPhoneState] = useState(organisationPhone);
  const [confirmOrganisationNameState] = useState(organisationName);
  const [confirmOrganisationForsIdState] = useState(organisationForsIdState);

  const [confirmOrganisationOrganisationEmailState] = useState(
    organisationContactEmail
  );
  const [confirmOrganisationInvoiceEmailState] = useState(
    organisationInvoiceEmail
  );

  const handleFormSubmit = (ev) => {
    ev.preventDefault();
    setFormSubmitting(true);
    const { subRouteProgress } = viewStore;
    const form = ev.target;
    const formInputName = form.querySelector('[data-input-name]');
    const formInputOrganisationEmail = form.querySelector(
      '[data-input-organisationemail]'
    );
    const formInputInvoiceEmail = form.querySelector(
      '[data-input-invoiceemail]'
    );
    const formInputsAddress = form.querySelectorAll('[data-input-address]');
    const formInputPhone = form.querySelector('[data-input-phone]');
    const forsId = form.querySelector('[data-input-forsid]');

    const newAddressData = {};

    formInputsAddress.forEach((input) => {
      const key = input.placeholder;
      const { value } = input;
      newAddressData[key] = value;
    });

    const organisationConfirmedData = {
      organisation_name: formInputName.value,
      organisation_address: newAddressData,
      organisation_contact_email: formInputOrganisationEmail.value,
      organisation_invoice_email: formInputInvoiceEmail.value,
      organisation_phone: formInputPhone.value,
      fors_id: forsId ? forsId.value : null,
      user_name: username,
    };

    // omit fors ID if not entered.
    if (!forsId) delete organisationConfirmedData.fors_id;

    callCreateRegistration(organisationConfirmedData)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then(() => {
        dispatch({
          type: 'UPDATE_CURRENT_USER_STATUS',
          payload: 'USER_ORGANISATION_ADDED',
        });
        dispatch({
          type: 'SET_SUB_ROUTE_PROGRESS',
          payload: subRouteProgress + 1,
        });
        setFormSubmitting(false);
      })
      .catch((err) => {
        setFormSubmitting(false);
        handleErrorResponse(err);
      });
  };

  const ConfirmationFormBody = () => {
    return (
      <FormFieldAnimationContainer>
        <Typography variant="h6" component="h4" gutterBottom>
          Please confirm the information you have provided is correct
        </Typography>
        <NameConfirmationField data={confirmOrganisationNameState} />
        <AddressConfirmationField data={confirmOrganisationAddressState} />
        <OrganisationEmailConfirmationField
          data={confirmOrganisationOrganisationEmailState}
        />
        <InvoiceEmailConfirmationField
          data={confirmOrganisationInvoiceEmailState}
        />
        <>
          {isHaulier && (
            <ForsIdConfirmationField data={confirmOrganisationForsIdState} />
          )}
        </>

        <NumberConfirmationField data={confirmOrganisationPhoneState} />
      </FormFieldAnimationContainer>
    );
  };

  return (
    <form
      className="form"
      onSubmit={(ev) => handleFormSubmit(ev)}
      autoComplete="off"
    >
      <div className="form__form-inner">
        <ConfirmationFormBody isUserHaulier={isHaulier} />
        {formSubmitting ? (
          <LoadingCircle />
        ) : (
          <Button
            className="--full-width"
            type="submit"
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        )}
      </div>
    </form>
  );
};
export default OrganisationConfirm;
