import React from 'react';
import DeleteUserTable from '../Tables/DeleteUserTable';

const UserConfirmDeleteForm = () => {
  return (
    <div className="manage-users">
      <div className="container">
        <DeleteUserTable />
      </div>
    </div>
  );
};

export default UserConfirmDeleteForm;
