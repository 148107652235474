import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { CSSTransition } from 'react-transition-group';
import { getSelectedEntranceFirstDestinations } from '../../../utils/siteConfigFunctions';

const AvailableFirstDestinationWithButtons = ({
  bookingFormState,
  setBookingFormState,
  originalSiteEntrance,
  originalFirstDestination,
}) => {
  const [fieldIsFocused, setFieldIsFocused] = useState(false);
  const [initialSetupToggle, setInitialSetupToggle] = useState(false);

  const getFirstDestination = () => {
    if (bookingFormState.firstDestination)
      return bookingFormState.firstDestination;

    if (!initialSetupToggle) return originalFirstDestination;

    return '';
  };

  useEffect(() => {
    setInitialSetupToggle(true);
  }, []);

  const setSelectedFirstDestination = (newSelectedFirstDestination) => {
    setBookingFormState({
      ...bookingFormState,
      firstDestination: newSelectedFirstDestination,
    });
  };

  const { siteEntrance } = bookingFormState;

  const handleDestinationSelect = (firstDestination) => {
    setSelectedFirstDestination(firstDestination);
    setFieldIsFocused(false);
  };

  function handleDestinationFocus() {
    setFieldIsFocused(true);
  }

  function handleButtonFocus(firstDestination) {
    setSelectedFirstDestination(firstDestination);
  }

  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);

  const availableFirstDestinations = getSelectedEntranceFirstDestinations(
    selectedSiteStore.config,
    siteEntrance || originalSiteEntrance
  );

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFieldIsFocused(false);
      }}
    >
      <div className="form__textfield-with-buttons">
        <TextField
          name="first_destination"
          label="First Destination"
          className="u-text-capitalise"
          variant="outlined"
          value={getFirstDestination() || ''}
          onFocus={handleDestinationFocus}
        />

        <CSSTransition
          in={fieldIsFocused}
          classNames="form__textfield-button-container"
          timeout={0}
          unmountOnExit
        >
          <div className="form__textfield-button-container">
            {availableFirstDestinations.map((firstDestination) => {
              return (
                <button
                  tabIndex="0"
                  type="button"
                  key={firstDestination}
                  className="button --outline button__first-destination"
                  onClick={() => handleDestinationSelect(firstDestination)}
                  onMouseEnter={() => handleButtonFocus(firstDestination)}
                >
                  {firstDestination}
                </button>
              );
            })}
          </div>
        </CSSTransition>
      </div>
    </ClickAwayListener>
  );
};
export default AvailableFirstDestinationWithButtons;
