import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getAlphabeticallySortedArray } from '../../../utils/functions';

const AssignedUserTable = ({ siteUsers }) => {
  const userTypeEnum = {
    'Site-Traffic-Marshal': 'Traffic Marshal',
    'Site-Manager': 'Site Manager',
    'Site-Organisation-Admin': 'Organisation Admin',
    'Haulier-Admin': 'Haulier Admin',
    ' Haulier-Driver': ' Haulier Driver',
  };
  const sortedSiteUsers = getAlphabeticallySortedArray(siteUsers, 'user_name');

  return (
    <TableContainer className="mui-table">
      <Table
        className="--table-without-buttons"
        stickyHeader
        aria-label="Assigned User table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedSiteUsers.map((user) => {
            return (
              <TableRow key={user.id}>
                <TableCell scope="site">{user.user_name}</TableCell>
                <TableCell align="left">
                  {userTypeEnum[user.user_group]}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssignedUserTable;
