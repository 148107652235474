import React from 'react';
import { useSelector } from 'react-redux';
import { Route, HashRouter, Switch, Redirect } from 'react-router-dom';
import Header from '../shared/Header/Header';
import ViewAnimationContainer from '../containers/ViewAnimationContainer';
import PrivateRoute from './PrivateRoute';
import useGetUserType from '../hooks/useGetUserType';
import PreRoute from './PreRoute';
import routesHaulier from './routes/routesHaulier';
import routesConstruction from './routes/routesConstruction';
import ErrorBoundary from '../ErrorBoundary';

const AppRouter = () => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { isLoggedIn, isHaulier } = currentUserStore;
  const { username } = currentUserStore.details;
  const userRoutes = isHaulier ? routesHaulier : routesConstruction;
  const currentUserType = useGetUserType();

  return (
    <HashRouter>
      <Switch>
        {userRoutes.map((route) => {
          return route.isPrivate ? (
            <PrivateRoute
              key={route.path}
              exact
              path={route.path}
              isLoggedIn={isLoggedIn}
            >
              <PreRoute route={route}>
                {route.header && (
                  <Header
                    pageTitle={route.title}
                    username={username}
                    darkHeader={route.header.isDark}
                    plannedBookingButton={route.header.plannedBookingButton}
                    hasLogoutButton={route.header.hasLogoutButton}
                  />
                )}

                <ViewAnimationContainer>
                  {'hiddenFrom' in route &&
                  route.hiddenFrom.includes(currentUserType) ? (
                    <Redirect to="/unauthorised" />
                  ) : (
                    <ErrorBoundary>
                      {route.component &&
                        React.createElement(route.component, {
                          props: { ...route },
                          subroutes: route.subroutes,
                        })}
                    </ErrorBoundary>
                  )}
                </ViewAnimationContainer>
              </PreRoute>
            </PrivateRoute>
          ) : (
            <Route key={route.path} exact path={route.path}>
              <PreRoute route={route}>
                <ViewAnimationContainer>
                  {route.component &&
                    React.createElement(route.component, { ...route.props })}
                </ViewAnimationContainer>
              </PreRoute>
            </Route>
          );
        })}
      </Switch>
    </HashRouter>
  );
};

export default AppRouter;
