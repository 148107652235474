import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import HaulierTableButton from '../../components/HaulierTableButton';
import { callLinkSiteHauliers } from '../../../../api/kerb-construction';

const AvailableHauliersTableRow = ({ haulier, refreshHauliersList }) => {
  return (
    <TableRow key={haulier.id}>
      <TableCell>{haulier.name}</TableCell>
      <TableCell>{haulier.address.postcode}</TableCell>
      <TableCell className="cell-with-button">
        <HaulierTableButton
          haulierID={haulier.id}
          refreshHauliersList={refreshHauliersList}
          apiToCall={callLinkSiteHauliers}
          className="button__haulier-select"
        >
          Connect
        </HaulierTableButton>
      </TableCell>
    </TableRow>
  );
};

export default AvailableHauliersTableRow;
