import { combineReducers } from 'redux';
import viewStore from './viewStore';
import addUserStore from './addUserStore';
import addBookingStore from './addBookingStore';
import editBookingStore from './editBookingStore';
import currentUserStore from './currentUserStore';
import selectedSiteStore from './selectedSiteStore';
import scheduleStore from './scheduleStore';
import bookingModalStore from './bookingModalStore';

const appReducer = combineReducers({
  viewStore,
  currentUserStore,
  addUserStore,
  addBookingStore,
  editBookingStore,
  selectedSiteStore,
  scheduleStore,
  bookingModalStore,
});

// reset the state of a redux store
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
    // eslint-disable-next-line no-console
    console.log('[Store Reset]');
  }
  return appReducer(state, action);
};

export default rootReducer;
