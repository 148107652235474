import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { format, parseISO } from 'date-fns';

const TableBodyRow = ({ deletionType, entityToDelete }) => {
  switch (deletionType) {
    case 'haulier-disconnect':
      return (
        <TableRow>
          <TableCell className="confirm-delete__table-heading">
            {entityToDelete.haulier_name}
          </TableCell>
          <TableCell className="confirm-delete__table-heading">
            {`${entityToDelete.haulier_address.line_1}, 
          ${entityToDelete.haulier_address.city}`}
          </TableCell>
        </TableRow>
      );
    case 'delete-booking':
      return (
        <TableRow>
          <TableCell className="confirm-delete__table-heading">
            {format(parseISO(entityToDelete.due_start), 'dd/MM/yyyy')}
          </TableCell>
          <TableCell className="confirm-delete__table-heading">
            {entityToDelete.material}
          </TableCell>
          <TableCell className="confirm-delete__table-heading">
            {entityToDelete.haulier_name}
          </TableCell>
        </TableRow>
      );
    default:
      return '';
  }
};

export default TableBodyRow;
