import React from 'react';
import { useDispatch } from 'react-redux';
import Delete from '../../../../../../images/Components/Delete';

const DeleteBookingButton = () => {
  const dispatch = useDispatch();

  const progressToConfirmDelete = () => {
    dispatch({
      type: 'SET_MODAL_CLOSED',
    });
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 1,
    });
  };

  return (
    <div className="booking-modal__edit-booking-button-container">
      <button
        type="button"
        className="button booking-modal__edit-booking-button --delete"
        onClick={progressToConfirmDelete}
      >
        Delete
        <Delete className="booking-modal__edit-booking-button-icon" />
      </button>
    </div>
  );
};

export default DeleteBookingButton;
