import React from 'react';
import Typography from '@material-ui/core/Typography';
import AvailableSitesDropDown from '../../../shared/AvailableSitesDropDown';

const SelectSiteForm = () => {
  return (
    <form className="form">
      <Typography variant="h5" component="h4" gutterBottom>
        Please Select A Site:
      </Typography>
      <AvailableSitesDropDown />
    </form>
  );
};
export default SelectSiteForm;
