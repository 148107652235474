import React from 'react';

const MetroTruckRecurringInTransit = ({ className }) => {
  return (
    <svg
      viewBox="0 0 40.619 20.004"
      version="1.1"
      id="svg11"
      className={className}
      alt="Recurring Booking"
    >
      <path
        id="Union_3"
        data-name="Union 3"
        d="M-23078.08-12226.575a3.084,3.084,0,0,1,.418-1.537h-8.521a3.084,3.084,0,0,1,.418,1.537,3.082,3.082,0,0,1-3.08,3.08,3.081,3.081,0,0,1-3.074-3.08,3.052,3.052,0,0,1,.412-1.537h-1.955l-1.535-1.536v-12.309a1.539,1.539,0,0,1,1.535-1.542h13.852a1.539,1.539,0,0,1,1.531,1.542v3.074h4.623l3.074,6.154v4.616h-1.955a3.033,3.033,0,0,1,.412,1.537,3.081,3.081,0,0,1-3.074,3.08A3.082,3.082,0,0,1-23078.08-12226.575Zm-12.9-6.119,2,2v-1.5h6v-3h-1v2.007h-5v-1.5Zm12.9-.034h5.5l-2.309-4.617h-3.189Zm-11.9-3.469h1v-2h5v1.5l2-2-2-2v1.5h-6Zm-13.049,7.366v-1.334h6.641v1.334Zm-3.986-2.668v-1.334h9.3v1.334Zm-3.984-2.668v-1.334h11.955v1.334Zm0-2.668v-1.328h11.955v1.328Zm3.984-2.662v-1.334h9.3v1.334Zm3.986-2.668v-1.334h6.641v1.334Z"
        transform="translate(23111.002 12243.499)"
      />
    </svg>
  );
};
export default MetroTruckRecurringInTransit;
