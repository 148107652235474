import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import BookingCardJobType from './BookingCardJobType';
import BookingCardDetails from './BookingCardDetails';
import BookingCardIcon from './BookingCardIcon';
import BookingCardStatus from './BookingCardStatus';
import BookingCardFooter from './BookingCardFooter';

const BookingCard = ({ cardIndex, cardData }) => {
  const getCardCompletedString = (card) => {
    if (card.completed && card.rejected) {
      return '--rejected';
    }

    if (card.completed) {
      return '--completed';
    }

    return '';
  };

  function getCardClassName(card) {
    const bookingIsRecurrent = card.recurrent;
    const recordInTransit = card.in_transit;
    const plannedString = card.type === 'PLANNED' ? '--planned' : '--unplanned';
    const recurrentString = bookingIsRecurrent ? `--recurrent` : '';
    const inTransitString = recordInTransit ? '--in-transit' : '';
    const completedString = getCardCompletedString(card);

    return `booking-card ${plannedString} ${recurrentString} ${inTransitString} ${completedString}`;
  }

  const dispatch = useDispatch();
  const cardClassName = getCardClassName(cardData);
  const [animateCard, setAnimateCard] = useState(false);
  const cardAnimationDelay = cardIndex * 100;

  useEffect(() => {
    setAnimateCard(true);
  }, []);

  const handleCardClick = (ev, card) => {
    if (ev.keyCode === 13 || ev.button === 0) {
      dispatch({
        type: 'SET_MODAL_OPEN',
        payload: true,
      });
      dispatch({
        type: 'SET_MODAL_DATA',
        payload: card,
      });
    }
  };

  return (
    <CSSTransition
      in={animateCard}
      classNames="booking-card"
      timeout={cardAnimationDelay}
      unmountOnExit
    >
      <div
        key={`card-id-${cardData.id}`}
        role="button"
        tabIndex="0"
        className={cardClassName}
        onKeyDown={(ev) => {
          handleCardClick(ev, cardData);
        }}
        onClick={(ev) => {
          handleCardClick(ev, cardData);
        }}
      >
        <div className="booking-card__inner">
          <BookingCardJobType cardData={cardData} />
          <BookingCardDetails cardData={cardData} />
          <BookingCardIcon cardData={cardData} />
          <BookingCardStatus cardData={cardData} />
        </div>
        <BookingCardFooter cardData={cardData} />
      </div>
    </CSSTransition>
  );
};
export default BookingCard;
