import ReactDOM from 'react-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import ScheduleBoard from '../components/ScheduleBoard';
import WebsocketWrapper from '../../../shared/WebsocketWrapper';
import BookingsRestWrapper from '../wrappers/BookingsRestWrapper';
import BookingModal from '../components/BookingModal/BookingModal';
import SelectSiteForm from '../forms/SelectSiteForm';

const OperationOverviewSubroute = () => {
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const bookingModalStore = useSelector((state) => state.bookingModalStore);
  const { cardModalData, bookingModalOpen } = bookingModalStore;
  const siteIsSelected = !!selectedSiteStore.id;

  return (
    <main className="schedule-view">
      {siteIsSelected ? (
        <WebsocketWrapper
          connectionType="site_id"
          connectionID={selectedSiteStore.id}
        >
          <BookingsRestWrapper siteId={selectedSiteStore.id}>
            <ScheduleBoard />
          </BookingsRestWrapper>
        </WebsocketWrapper>
      ) : (
        <SelectSiteForm />
      )}
      {bookingModalOpen &&
        ReactDOM.createPortal(
          <BookingModal cardModalData={cardModalData} />,
          document.querySelector('body')
        )}
    </main>
  );
};

export default OperationOverviewSubroute;
