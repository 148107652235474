import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import LateCue from '../../../../../images/Components/LateCue';
import { isBookingLate } from '../../../../../utils/timeFunctions';
import FirstDestination from '../../../../../images/Components/FirstDestination';

const PlannedModalDetails = ({ cardModalData }) => {
  const isLate = isBookingLate(cardModalData.due_end);
  const formattedPlannedBookingDate = format(
    parseISO(cardModalData.due_start),
    'dd/MM/yyyy'
  );
  const formattedDueStart = format(parseISO(cardModalData.due_start), 'HH:mm');
  const formattedDueEnd = format(parseISO(cardModalData.due_end), 'HH:mm');

  return (
    <div className="booking-modal__details">
      <span>
        Material: <b>{cardModalData.material}</b>
      </span>
      <span>
        Company: <b>{cardModalData.haulier_name}</b>
      </span>
      <span>
        Date: <b>{formattedPlannedBookingDate}</b>
      </span>
      <span className={isLate ? 'booking-modal__warning' : ''}>
        Slot:&nbsp;
        <b> {`${formattedDueStart} - ${formattedDueEnd}`}</b>
        {isLate && <LateCue className="warning-icon" />}
      </span>
      <span className="booking-modal__first-destination">
        <FirstDestination className="booking-modal__first-destination-icon" />
        {cardModalData.first_destination}
      </span>
    </div>
  );
};

export default PlannedModalDetails;
