import React, { useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableHeading from './components/TableHeadings';
import TableBodyRow from './components/TableRow';
import {
  confirmDeletePageHeading,
  confirmDeletePageSubheading,
} from './confirmDeleteFunctions';
import useBackButtonHandler from '../../hooks/useBackButtonHandler';
import DeleteButton from './components/DeleteButton';

const ConfirmDelete = ({
  deletionType,
  entityToDelete,
  successfulDeleteCallBack,
}) => {
  const redirectToPreviousPage = useBackButtonHandler();

  useEffect(() => {
    if (!entityToDelete) redirectToPreviousPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      {entityToDelete && (
        <TableContainer className="mui-table confirm-delete">
          <header className="confirm-delete__header">
            <div className="confirm-delete__heading-container">
              <Typography
                variant="h6"
                component="h6"
                gutterBottom
                className="u-highlight-title confirm-delete__heading"
              >
                {confirmDeletePageHeading(deletionType)}
              </Typography>
            </div>

            <div className="confirm-delete__heading-button-container">
              <Button className="button" onClick={redirectToPreviousPage}>
                Cancel
              </Button>
              <DeleteButton
                deletionType={deletionType}
                entityToDelete={entityToDelete}
                className="button --warning"
                successfulDeleteCallBack={successfulDeleteCallBack}
              >
                Delete
              </DeleteButton>
            </div>

            <div className="confirm-delete__sub-heading-container">
              <span className="u-text-italic u-text-bold">
                {confirmDeletePageSubheading(deletionType)}
              </span>
            </div>
          </header>
          <div className="confirm-delete__table-container">
            <div className="confirm-delete__table-container-contents --two-column">
              <Table
                className="confirm-delete__table"
                stickyHeader
                aria-label="manage users table"
              >
                <TableHead>
                  <TableHeading deletionType={deletionType} />
                </TableHead>
                <TableBody>
                  <TableBodyRow
                    deletionType={deletionType}
                    entityToDelete={entityToDelete}
                  />
                </TableBody>
              </Table>
            </div>
          </div>
        </TableContainer>
      )}
    </div>
  );
};

export default ConfirmDelete;
