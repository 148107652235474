import React, { useState, useEffect, useRef } from 'react';
import useCallApi from '../../../hooks/useCallApi';
import { callGetBookings } from '../../../api/kerb-construction';
import {
  removePreExistingBookingEntry,
  getModifiedTimeOrderedBookings,
} from '../../../utils/bookingFunctions';
import LoadingCircle from '../../../shared/LoadingCircle';

function BookingsRestWrapper({ children, siteId, websocketData }) {
  const [bookingData, setBookingData] = useState([]);
  const [apiCalled, setApiCalled] = useState(false);
  const [loading, response] = useCallApi(callGetBookings, [siteId]);
  const currentDashboardData = useRef();

  useEffect(() => {
    // call api once on component load, and store records in ref.
    if (response && !apiCalled) {
      setApiCalled(true);
      const sortedRecords = getModifiedTimeOrderedBookings([...response]);

      currentDashboardData.current = sortedRecords;
      setBookingData(sortedRecords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (response && websocketData) {
      const currentData = currentDashboardData.current;
      const processedData = currentData
        ? removePreExistingBookingEntry(currentData, websocketData)
        : currentData;
      const sortedRecords = getModifiedTimeOrderedBookings([
        ...processedData,
        websocketData,
      ]);
      currentDashboardData.current = sortedRecords;
      setBookingData(sortedRecords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketData]);

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { bookingData })
  );
  return !loading ? childrenWithProps : <LoadingCircle />;
}

export default BookingsRestWrapper;
