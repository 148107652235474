import React from 'react';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { descSortedNotesList } from '../../../utils/bookingFunctions';
import LoadingCircle from '../../../shared/LoadingCircle';

const BookingNotesList = ({ bookingNotes, loading }) => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const currentUserID = currentUserStore.details.userId;

  const sortedBookingNotes = () => {
    return descSortedNotesList(bookingNotes);
  };

  const BookingNote = ({ bookingNote }) => {
    return (
      <div className="booking-note --haulier">
        <div className="booking-note__note-user">
          {bookingNote.creator_id === currentUserID
            ? 'You'
            : bookingNote.creator_name}
        </div>
        <div className="booking-note__note-timestamp">
          {format(parseISO(bookingNote.date_created), 'HH:mm')}
        </div>
        <div className="booking-note__note-content">{bookingNote.content}</div>
      </div>
    );
  };

  return !loading ? (
    <div className="booking-note-list">
      {bookingNotes.length === 0 ? (
        <span className="u-text-italic booking-note-list__empty-list">
          There are currently no notes to display
        </span>
      ) : (
        sortedBookingNotes().map((note) => {
          return <BookingNote key={note.date_created} bookingNote={note} />;
        })
      )}
    </div>
  ) : (
    <div className="booking-note-list">
      <LoadingCircle />
    </div>
  );
};

export default BookingNotesList;
