import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';

const ConfirmationCodeInput = () => {
  const initialCodeArray = ['', '', '', '', '', ''];

  const [confirmationCodeArray, setConfirmationCodeArray] =
    useState(initialCodeArray);
  const dispatch = useDispatch();
  const refArray = [];

  confirmationCodeArray.forEach(() => {
    const ref = React.createRef();
    refArray.push(ref);
  });

  const focusNextInput = (index) => {
    if (index + 1 < refArray.length) {
      const nextRef = refArray[index + 1].current;
      nextRef.focus();
    }
  };

  const handleChange = (e, index) => {
    // eslint-disable-next-line radix
    const number = parseInt(e.currentTarget.value);
    const newArray = [...confirmationCodeArray];
    newArray.splice(index, 1, number);
    setConfirmationCodeArray(newArray);
    focusNextInput(index);
  };

  useEffect(() => {
    const confirmationCode = confirmationCodeArray.join('');

    if (confirmationCode.length === 6) {
      dispatch({
        type: 'SET_CONFIRMATION_CODE',
        payload: confirmationCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationCodeArray]);

  useEffect(() => {
    refArray[0].current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="confirmation-code-view__input-container">
      {confirmationCodeArray.map((_v, i) => {
        const keyValue = `value-${i}`;

        return (
          <TextField
            autoFocus={i === confirmationCodeArray.length - 1}
            inputProps={{ minLength: '0', maxLength: '1' }}
            inputRef={refArray[i]}
            name={i}
            key={keyValue}
            variant="outlined"
            className="confirmation-code-view__input"
            onChange={(e) => {
              if (e.target.value.length < 2) {
                handleChange(e, i);
              }
            }}
          />
        );
      })}
    </div>
  );
};

export default ConfirmationCodeInput;
