import './scss/style.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import AlertPanel from './shared/AlertPanel';
import AppRouter from './router/AppRouter';
import InactivityHandler from './shared/InactivityHandler';

function App() {
  const viewStore = useSelector((state) => state.viewStore);
  const { alert } = viewStore;

  // 24 hours x 60 minutes in an hour x 60 seconds in a minute
  const twentyFourHoursInSeconds = 24 * 60 * 60;

  return (
    <div className="App">
      {alert.message && <AlertPanel alert={alert} />}
      <InactivityHandler inactivityRefreshTime={twentyFourHoursInSeconds} />
      <AppRouter />
    </div>
  );
}

export default App;
