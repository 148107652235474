/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SingleBookingsAssignmentTableRow from './SingleBookingsAssignmentTableRow';
import RecurringBookingsAssignmentTableRow from './RecurringBookingsAssignmentTableRow';
import {
  getRecurringBookings,
  getMatchingIdRecurrentBookings,
  getUniqueBookingsById,
  getStartTimeOrderedBookings,
} from '../../../utils/bookingFunctions';

const BookingsAssignmentTable = ({
  bookingData,
  originalValueFunctions,
  originalBookingDetails,
}) => {
  const recurringBookings = getRecurringBookings(bookingData);

  const uniqueBookings = getUniqueBookingsById(bookingData);

  const timeOrderedUniqueBookings = getStartTimeOrderedBookings(uniqueBookings);

  return (
    <TableContainer className="mui-table booking-assignment-table-container">
      <Table aria-label="Bookings Assignment table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Number Of Days</TableCell>
            <TableCell>Material</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Slot</TableCell>
            <TableCell>First Destination</TableCell>
            <TableCell>Vehicle (VRM)</TableCell>
            <TableCell>Driver</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeOrderedUniqueBookings.map((booking, index) => {
            const bookingIsRecurring = booking.recurrent;
            const key = `${booking.id}-${index}`;

            const editRowActive = bookingIsRecurring
              ? booking.recurrent_id in originalBookingDetails[1]
              : booking.id in originalBookingDetails[0];

            // pass an object of records in if recurring, otherwise just a single booking
            const processedBookingData = bookingIsRecurring
              ? getMatchingIdRecurrentBookings(
                  booking.recurrent_id,
                  recurringBookings
                )
              : booking;

            const inTransit = booking.in_transit;

            return bookingIsRecurring ? (
              <RecurringBookingsAssignmentTableRow
                editRowActive={editRowActive}
                recurringBookings={processedBookingData}
                key={key}
                bookingCount={processedBookingData.length}
                completeBooking={bookingData}
                originalValueFunctions={originalValueFunctions}
                originalBookingDetails={originalBookingDetails}
                inTransit={inTransit}
              />
            ) : (
              <SingleBookingsAssignmentTableRow
                editRowActive={editRowActive}
                booking={processedBookingData}
                key={key}
                bookingCount={1}
                originalValueFunctions={originalValueFunctions}
                originalBookingDetails={originalBookingDetails}
                inTransit={inTransit}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BookingsAssignmentTable;
