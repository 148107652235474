import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import LoadingCircle from '../../../shared/LoadingCircle';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';

const OrganisationName = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onTouched',
  });
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const organisationDetails = currentUserStore.organisation;
  const dispatch = useDispatch();
  const organisationName = useRef({});
  organisationName.current = watch('organisationName', '');

  const onSubmit = (data) => {
    const { subRouteProgress } = viewStore;

    if (formValidated) {
      setFormSubmitting(true);
      dispatch({
        type: 'SET_CURRENT_USER_ORGANISATION_DETAILS',
        payload: {
          ...organisationDetails,
          name: data.organisationName,
        },
      });
      dispatch({
        type: 'SET_SUB_ROUTE_PROGRESS',
        payload: subRouteProgress + 1,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const errorsLength = Object.keys(errors).length;
    if (errorsLength === 0 && organisationName.current.length > 1) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h6" component="h4" gutterBottom>
            What is the name of your Organisation?
          </Typography>

          <TextField
            autoFocus
            inputRef={register({
              required: true,
              max: 20,
              min: 2,
              pattern: /([A-Z0-9._%+-])\w+/i,
            })}
            name="organisationName"
            label="eg: Grid Smarter Cities"
            type="text"
            variant="outlined"
          />

          <FormErrorMessage errors={errors.organisationName} />

          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
};
export default OrganisationName;
