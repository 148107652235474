import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const PreRoute = ({ children, route }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const viewStore = useSelector((state) => state.viewStore);

  function updateWindowTitle() {
    document.title = `${route.title} | Kerb Construction`;
  }
  function persistRouteToStore() {
    dispatch({
      type: 'SET_CURRENT_ROUTE',
      payload: location.pathname,
    });
  }

  function resetSubRouteProgress() {
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
  }

  useEffect(() => {
    const persistedRoute = viewStore.currentRoute;

    updateWindowTitle();
    persistRouteToStore();

    if (persistedRoute !== location.pathname) resetSubRouteProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return children;
};

export default PreRoute;
