import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { callGetSites } from '../../api/kerb-construction';
import LoadingCircle from '../../shared/LoadingCircle';
import useCallApi from '../../hooks/useCallApi';
import SiteUsersPanel from './components/SiteUsersPanel';

const SitesView = () => {
  const [selectedSiteObj, setSelectedSiteObj] = useState({});
  const dispatch = useDispatch();
  const [loading, response] = useCallApi(callGetSites);
  const history = useHistory();

  const handleLinkButtonPress = (buttonType) => {
    history.push(buttonType);
  };

  const handleSelectSite = (selectedSite) => {
    setSelectedSiteObj(selectedSite);

    dispatch({
      type: 'SET_SELECTED_SITE_DETAILS',
      payload: { ...selectedSite },
    });
  };

  return (
    <main className="sites-view">
      <aside className="sites-view__sidebar">
        <div className="sites-view__sidebar-inner">
          <header>
            <h3>Sites</h3>
          </header>
          <div className="sites-view__scrolling-container">
            {!loading ? (
              response.map((site) => {
                return (
                  <button
                    onClick={() => handleSelectSite(site)}
                    type="button"
                    key={site.id}
                    className={`button site-button ${
                      selectedSiteObj.id === site.id ? '--selected' : ''
                    }`}
                  >
                    {site.name}
                  </button>
                );
              })
            ) : (
              <LoadingCircle />
            )}
          </div>
        </div>
      </aside>
      <div className="sites-view__site-panel">
        <div className="sites-view__header-container">
          <header className="sites-view__header">
            <div className="container">
              {selectedSiteObj.id ? (
                <>
                  <h3>{selectedSiteObj.name}</h3>
                  <div className="sites-view__link-button-container">
                    <button
                      type="button"
                      onClick={
                        () => handleLinkButtonPress('/operationoverview')
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                      className="button --success sites-view__select-view-button"
                    >
                      View Board
                    </button>

                    <button
                      type="button"
                      onClick={() => handleLinkButtonPress('/bookingschedule')}
                      className="button --success sites-view__select-view-button"
                    >
                      View Schedule
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h3>Site</h3>
                  <p>Please select the site that you would like to view</p>
                </>
              )}
            </div>
          </header>
        </div>
        {selectedSiteObj.id && <SiteUsersPanel siteId={selectedSiteObj.id} />}
      </div>
    </main>
  );
};

export default SitesView;
