import React from 'react';

const MetroTruckRecurring = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.768"
      height="24.997"
      viewBox="0 0 30.768 24.997"
      className={className}
      alt="Recurring Booking"
    >
      <path
        id="Subtraction_8"
        data-name="Subtraction 8"
        d="M23625-3406a3.848,3.848,0,0,1-3.844-3.843,3.777,3.777,0,0,1,.518-1.927h-10.646a3.828,3.828,0,0,1,.516,1.927,3.85,3.85,0,0,1-3.848,3.843,3.848,3.848,0,0,1-3.844-3.843,3.827,3.827,0,0,1,.516-1.927h-2.437l-1.928-1.922v-15.384a1.927,1.927,0,0,1,1.928-1.922h17.3a1.927,1.927,0,0,1,1.926,1.922v3.843h5.766l3.848,7.692v5.771h-2.437a3.834,3.834,0,0,1,.518,1.927A3.85,3.85,0,0,1,23625-3406Zm-17.475-14h0l-2.5,2.5,2.5,2.5v-1.88h7.5v-3.748h-1.252v2.5h-6.25V-3420Zm13.631-3.3h0v5.764h6.869l-2.883-5.764h-3.986Zm-14.885-2.319h0v3.748h1.252v-2.5h6.25v1.874l2.5-2.5-2.5-2.5v1.874h-7.5Z"
        transform="translate(-23599.998 3430.997)"
      />
    </svg>
  );
};
export default MetroTruckRecurring;
