import React from 'react';
import VehicleVrmForm from './forms/VehicleVrmForm';
import VehicleSuccessForm from './forms/VehicleSuccessForm';
import ManageVehiclesTable from './tables/ManageVehiclesTable';
import SubRouteHandler from '../../router/SubRouteHandler';

const addNewVehicleProgressRoutes = [
  { component: ManageVehiclesTable },
  { component: VehicleVrmForm },
  { component: VehicleSuccessForm },
];

function ManageUsersView() {
  return (
    <main className="manage-vehicles-view">
      <SubRouteHandler subroutes={addNewVehicleProgressRoutes} />
    </main>
  );
}

export default ManageUsersView;
