import React from 'react';
import editImage from '../../../images/edit.svg';

const ConfirmationButtons = ({ editState, setEditState }) => {
  return (
    <>
      {editState ? (
        <div className="add-organisation-view__confirmation-button-container">
          <button
            type="submit"
            onClick={(ev) => {
              ev.preventDefault();
              setEditState(false);
            }}
            className="button button --button-save"
          >
            Save
          </button>
          <button
            type="submit"
            onClick={() => {
              setEditState(false);
            }}
            className="button button --button-cancel"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="add-organisation-view__confirmation-button-container">
          <button
            type="submit"
            onClick={(ev) => {
              ev.preventDefault();
              setEditState(true);
            }}
            className="button button --button-edit"
          >
            Edit
            <img src={editImage} alt="edit" />
          </button>
        </div>
      )}
    </>
  );
};

export default ConfirmationButtons;
