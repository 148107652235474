/* eslint-disable no-console */
import jwtDecode from 'jwt-decode';
import Amplify from 'aws-amplify';
import { haulierawsconfig, awsconfig } from '../aws-exports';
import packageJson from '../../package.json';

export function getJWTTokenDecoded(idToken) {
  return jwtDecode(idToken);
}
export function getCognitoTokenHasExpired(tokenExp) {
  const expiryTime = tokenExp * 1000;
  const resolution = expiryTime - Date.now();
  return resolution >= 0;
}
export function getPasswordValidationState(password) {
  const regexpUppercase = /([A-Z])/;
  const regexpLowercase = /([a-z])/;
  const regexpNumber = /([0-9])/;
  // eslint-disable-next-line no-useless-escape
  const regexpSymbol = /(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])/;

  return {
    passwordLength: password.length > 8,
    passwordUppercase: regexpUppercase.test(password),
    passwordLowercase: regexpLowercase.test(password),
    passwordNumber: regexpNumber.test(password),
    passwordSymbol: regexpSymbol.test(password),
  };
}

export function scrollToTop() {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
}

export function userTypeIsAllowed(currentUserType) {
  const allowedUserTypes = [
    'Site-Organisation-Admin',
    'Site-Manager',
    'Haulier-Admin',
  ];

  return allowedUserTypes.find((type) => type === currentUserType);
}

export function getFlattenedResponseMessage(response) {
  let responseMessage = null;

  if (typeof response === 'string') return response;

  function iterate(obj) {
    Object.keys(obj).forEach((property) => {
      if (Object.prototype.hasOwnProperty.call(obj, property)) {
        if (typeof obj[property] === 'object') {
          iterate(obj[property]);
        } else if (typeof obj[property] === 'string') {
          responseMessage = obj[property];
        }
      }
    });
  }
  iterate(response);
  return responseMessage;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
export function setCookie(cname, cvalue) {
  document.cookie = `${cname}=${cvalue}`;
}

export function getCurrentUserIdFromStore() {
  const store = JSON.parse(localStorage.getItem('reduxStore'));
  const currentUserId = store.currentUserStore.details.userId;
  return currentUserId;
}
export function tokenCookieExists() {
  return !!getCookie(`idToken:${getCurrentUserIdFromStore()}`);
}
export function getCurrentUsernameFromStore() {
  const store = JSON.parse(localStorage.getItem('reduxStore'));
  const currentUsername = store.currentUserStore.details.username;
  return currentUsername;
}

export function expireCookie() {
  document.cookie = `idToken:${getCurrentUserIdFromStore()}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function clearLocalStorage() {
  expireCookie();
  setTimeout(() => {
    localStorage.clear();
  }, 100);
}

export function logUserOut() {
  clearLocalStorage();
  window.location.reload();
}

export function configureAmplify(isHaulier) {
  Amplify.configure(isHaulier ? haulierawsconfig : awsconfig);
  console.log(
    `%c[Amplify Session]: ${
      isHaulier ? 'Haulier' : 'Standard'
    } Configuration Set`,
    'color: lightblue'
  );
}

export function logPackageVersionDetails() {
  console.log(
    `%c[${packageJson.name}]: ${packageJson.version} ${process.env.NODE_ENV}`,
    'color: yellow; background: black'
  );
}

export function getSiteIdFromHaulierSiteId(siteId) {
  return siteId.split(':')[0];
}

export function isMobileUserAgent() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
export function isMobileWidth() {
  return window.innerWidth < 650;
}

export function getAlphabeticallySortedArray(arr, val) {
  arr.sort((a, b) => {
    const nameA = a[val].toUpperCase(); // ignore upper and lowercase
    const nameB = b[val].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
  return arr;
}

/**
 *  A function that finds the first entry of subRouteData
 *  that doesn't have bypass flag as an index to be used for subRoute Progress.
 * @param {Integer} subRouteProgress
 * @param {Array} subrouteDataArray
 * @return {integer} Index of subroute that doesn't have bypassflag.
 */

export function getPreviousSubRouteIndex(subRouteProgress, subrouteDataArray) {
  if (subRouteProgress === 1) return 0; // return 0 if progress is only 1 deep as we dont need to skip anything.
  // create new array from subrouteDataArray to index of users progress
  const splicedArray = subrouteDataArray.slice(0, subRouteProgress);
  // reverse the array so that we can iterate the correct direction.
  // and find the first subroute that does NOT have bypassPrevious flag.
  const firstPreviousAllowedSubroute = splicedArray
    .reverse()
    .find((subroute) => {
      return !subroute.bypassPrevious;
    });
  // fallback to return 0 if it doesn't find viable route.
  return firstPreviousAllowedSubroute
    ? firstPreviousAllowedSubroute.subRouteIndex
    : 0;
}

export function removePropertyFromObject(object, property) {
  const duplicateObject = JSON.parse(JSON.stringify(object)); // Creates a deep copy of the original object

  if (property in duplicateObject) {
    delete duplicateObject[property];
    return duplicateObject;
  }

  return duplicateObject;
}

export function addPropertyAndValueToObject(object, property, value) {
  const duplicateObject = JSON.parse(JSON.stringify(object)); // Creates a deep copy of the original object

  duplicateObject[property] = value;

  return duplicateObject;
}

/**
 *
 * @param {ARRAY} users returned from GET /users
 * @param {ARRAY} siteUsers users returned from GET /siteusers
 * @return {ARRAY}  unique users filtered by id
 */

export function getUniqueAvailableUsers(siteUsers, connectedUsers) {
  const connectedUsersIdArray = [
    ...new Set(connectedUsers.map((user) => user.user_id)),
  ];

  const uniqueUsersById = siteUsers.filter((user) =>
    connectedUsersIdArray.every((id) => id !== user.id)
  );

  return uniqueUsersById;
}

export function initGoogleAnalytics() {
  const scriptAsync = document.createElement('script');
  const script = document.createElement('script');

  scriptAsync.async = true;
  scriptAsync.src = 'https://www.googletagmanager.com/gtag/js?id=G-SSVMHV235L';

  script.text = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-SSVMHV235L');`;

  document.head.appendChild(scriptAsync);
  document.head.appendChild(script);
  console.log('[GA Initialised]');
}

export function initHotJar() {
  const script = document.createElement('script');

  script.text = `
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:2326830,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`;

  document.head.appendChild(script);
  console.log('[HJ Initialised]');
}
