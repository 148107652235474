import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callPatchBooking } from '../../../api/kerb-construction';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';

const ButtonConfirmBookingPatchRecord = () => {
  const [patchRequestInProgress, setPatchRequestInProgress] = useState(false);
  const editBookingStore = useSelector((state) => state.editBookingStore);

  const handleErrorResponse = useHandleErrorResponse();
  const dispatch = useDispatch();

  function handleConfirmBooking() {
    const { patchBookingParams, editBookingDetails } = editBookingStore;

    setPatchRequestInProgress(true);
    callPatchBooking(editBookingDetails.id, patchBookingParams)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then(() => {
        dispatch({
          type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
          payload: null,
        });
        dispatch({
          type: 'SET_SUB_ROUTE_PROGRESS',
          payload: 0,
        });
      })

      .catch((err) => {
        setPatchRequestInProgress(false);

        dispatch({
          type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
          payload: null,
        });
        handleErrorResponse(err);
      });
  }
  return (
    <button
      type="button"
      onClick={handleConfirmBooking}
      disabled={patchRequestInProgress}
      className="button main-header__button-confirm --success"
    >
      {patchRequestInProgress ? 'Updating' : 'Done'}
    </button>
  );
};

export default ButtonConfirmBookingPatchRecord;
