import React from 'react';
import tick from '../../../images/tick.svg';

const ProgressCircle = ({ complete, current }) => {
  const statusClass = complete ? 'progress-bar__circle--complete' : '';
  const currentClass = current ? 'progress-bar__circle--current' : '';

  return (
    <>
      <div className={`progress-bar__circle ${statusClass} ${currentClass}`}>
        {complete && <img src={tick} alt="complete" />}
      </div>
      <span className={statusClass} />
    </>
  );
};
const ProgressBar = ({ level, progressBarLength }) => {
  const indicators = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < progressBarLength; i++) {
    indicators.push(
      <ProgressCircle key={i} complete={i < level} current={i === level} />
    );
  }
  return (
    <div role="progressbar" className="progress-bar">
      {indicators}
    </div>
  );
};
export default ProgressBar;
