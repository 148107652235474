import React from 'react';

import RecurringBookingIcon from '../images/Components/MetroTruckRecurring';
import RecurringInTransitIcon from '../images/Components/MetroTruckRecurringInTransit';
import SingleBookingIcon from '../images/Components/MetroTruck';
import SingleInTransitIcon from '../images/Components/MetroTruckInTransit';
import UnplannedBookingIcon from '../images/Components/MetroTruckUnplanned';

const BookingIconKeys = ({
  showPlanned,
  showRecurring,
  showUnplanned,
  showInTransit,
}) => {
  return (
    <div className="booking-keys-container">
      {!!showPlanned && (
        <div className="booking-keys-container__key">
          <SingleBookingIcon className="booking-keys-container__key-icon" />
          <span>Individual Bookings</span>
        </div>
      )}

      {!!showRecurring && (
        <div className="booking-keys-container__key">
          <RecurringBookingIcon className="booking-keys-container__key-icon" />
          <span>Recurring Bookings</span>
        </div>
      )}

      {!!showUnplanned && (
        <div className="booking-keys-container__key">
          <UnplannedBookingIcon className="booking-keys-container__key-icon" />
          <span>Unplanned Bookings</span>
        </div>
      )}

      {!!showPlanned && !!showInTransit && (
        <div className="booking-keys-container__key">
          <SingleInTransitIcon className="booking-keys-container__key-icon --in-transit" />
          <span>Individual Bookings in Progress</span>
        </div>
      )}

      {!!showRecurring && !!showInTransit && (
        <div className="booking-keys-container__key">
          <RecurringInTransitIcon className="booking-keys-container__key-icon --in-transit" />
          <span>Recurring Bookings in Progress</span>
        </div>
      )}
    </div>
  );
};

export default BookingIconKeys;
