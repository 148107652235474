import React, { useState } from 'react';

import DatePicker from 'react-datepicker';

import isToday from 'date-fns/isToday';

const BookingScheduleDayPicker = ({ selectedDate, setSelectedDate }) => {
  const [todayIsSelected, setTodayIsSelected] = useState(true);

  const handleDateChange = (ev) => {
    const selectedDateString = ev;
    setTodayIsSelected(isToday(selectedDateString));
    setSelectedDate(selectedDateString);
  };

  const CustomDatePicker = React.forwardRef((props, ref) => {
    const { value, onClick } = props;
    return (
      <button
        ref={ref}
        type="button"
        className="button date-button"
        onClick={onClick}
      >
        {value}
      </button>
    );
  });

  return (
    <div className="react-datepicker-wrapper booking-schedule-view__day-picker-container">
      <DatePicker
        dateFormat="dd/MM/yyyy"
        todayButton="View Today"
        onChange={handleDateChange}
        selected={selectedDate}
        minDate={new Date()}
        placeholderText="Select a date from today"
        customInput={<CustomDatePicker ref={null} />}
      />

      {!todayIsSelected && (
        <button
          type="button"
          onClick={() => handleDateChange(new Date())}
          className="button view-today-button booking-schedule-view__view-today-button"
        >
          View Today
        </button>
      )}
    </div>
  );
};
export default BookingScheduleDayPicker;
