const userTypeTableInfo = {
  'Site-Organisation-Admin': {
    manageUserHeading:
      'Please select an Organisation Admin that you would like to manage',
    deleteUserHeading: 'Are you sure you want to delete this user?',
    tableHeadings: ['Name', 'Email'],
    tableDataProperties: ['name', 'contact_email'],
    addUserButtonText: 'Add Organisation Admin',
  },
  'Site-Manager': {
    manageUserHeading:
      'Please select a Site Manager that you would like to manage',
    deleteUserHeading: 'Are you sure you want to delete this user?',
    tableHeadings: ['Name', 'Email'],
    tableDataProperties: ['name', 'contact_email'],
    addUserButtonText: 'Add Site Manager',
  },
  'Site-Traffic-Marshal': {
    manageUserHeading:
      'Please select a Traffic Marshal that you would like to manage',
    deleteUserHeading: 'Are you sure you want to delete this user?',
    tableHeadings: ['Name', 'Number'],
    tableDataProperties: ['name', 'phone'],
    addUserButtonText: 'Add Traffic Marshal',
  },
  'Haulier-Admin': {
    manageUserHeading:
      'Please select a Haulier Admin that you would like to manage',
    deleteUserHeading: 'Are you sure you want to delete this user?',
    tableHeadings: ['Name', 'Email'],
    tableDataProperties: ['name', 'contact_email'],
    addUserButtonText: 'Add Haulier Admin',
  },
  'Haulier-Driver': {
    manageUserHeading: 'Please select a Driver that you would like to manage',
    deleteUserHeading: 'Are you sure you want to delete this user?',
    deleteUserSubHeading:
      'This Driver may have assigned bookings, deleting this driver will revert any bookings to unassigned and action will be required',
    tableHeadings: ['Name', 'Number'],
    tableDataProperties: ['name', 'phone'],
    addUserButtonText: 'Add Driver',
  },
};
export default userTypeTableInfo;
