import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { callGetDriverAvailabilityData } from '../../../../api/kerb-construction';
import { getAlphabeticallySortedArray } from '../../../../utils/functions';
import useCallApi from '../../../../hooks/useCallApi';
import LoadingCircle from '../../../../shared/LoadingCircle';
import warningImage from '../../../../images/warning.svg';
import ButtonConfirmBookingPatchRecord from '../../components/ButtonConfirmBookingPatchRecord';

const DriverAssignTable = () => {
  const editBookingStore = useSelector((state) => state.editBookingStore);

  const { editBookingDetails } = editBookingStore;

  const [currentlySelectedDriverId, setCurrentlySelectedDriverId] =
    useState(false);
  const dispatch = useDispatch();

  const bookingData = editBookingStore.editBookingDetails;
  const dueStart = bookingData.due_end;
  const dueEnd = bookingData.due_end;

  const [loading, response] = useCallApi(callGetDriverAvailabilityData, [
    dueStart,
    dueEnd,
  ]);

  const driverID = editBookingDetails.driver_id;
  const driverName = editBookingDetails.driver_name;

  const [originalDriverId, setOriginalDriverId] = useState(null);
  const [originalDriverName, setOriginalDriverName] = useState(null);

  useEffect(() => {
    setOriginalDriverId(driverID);
    setOriginalDriverName(driverName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDriverSelect(newDriverObj) {
    const driverIsCurrentlySelected =
      newDriverObj.id === currentlySelectedDriverId;
    const bookingIsRecurring = editBookingDetails.recurrent;

    if (!driverIsCurrentlySelected) {
      setCurrentlySelectedDriverId(newDriverObj.id);
      dispatch({
        type: 'SET_PATCH_BOOKING_PARAM',
        payload: { driver_id: newDriverObj.id },
        patch_all_recurrent: bookingIsRecurring, // this may need to change, if we decide to let the user select individual records to patch.
      });
      dispatch({
        type: 'SET_EDIT_BOOKING_DRIVER_NAME',
        payload: newDriverObj.name,
      });
      dispatch({
        type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
        payload: ButtonConfirmBookingPatchRecord,
      });
    } else {
      setCurrentlySelectedDriverId(null);
      dispatch({
        type: 'SET_PATCH_BOOKING_PARAM',
        payload: { driver_id: originalDriverId },
        patch_all_recurrent: bookingIsRecurring, // this may need to change, if we decide to let the user select individual records to patch.
      });
      dispatch({
        type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
        payload: null,
      });
      dispatch({
        type: 'SET_EDIT_BOOKING_DRIVER_NAME',
        payload: originalDriverName,
      });
    }
  }

  return !loading ? (
    <>
      <div className="assign-booking-view__assignment-table-header">
        <div className="warning-information-container">
          <img src={warningImage} alt="warning" />
          <p className="u-text-italic">
            = Already assigned to a booking booking on the same date
          </p>
        </div>
      </div>
      <div className="assign-booking-view__assignment-table-body">
        <TableContainer className="mui-table">
          <Table
            className="--assignment-table"
            stickyHeader
            aria-label="Driver Assignment table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getAlphabeticallySortedArray(response, 'name').map((driver) => {
                const buttonIsSelected =
                  driver.id === currentlySelectedDriverId;
                return (
                  <TableRow key={driver.id}>
                    <TableCell>{driver.name}</TableCell>
                    <TableCell>{driver.phone || 'No Number'}</TableCell>
                    <TableCell className="cell-with-button">
                      <button
                        type="button"
                        onClick={() => handleDriverSelect(driver)}
                        className={`button button-table ${
                          buttonIsSelected ? '--selected' : ''
                        }`}
                      >
                        {`${buttonIsSelected ? 'Unselect' : 'Select'}`}
                      </button>
                    </TableCell>
                    {driver.status !== 'available' && (
                      <TableCell className="assignment-warning-cell">
                        <img src={warningImage} alt="warning" />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  ) : (
    <LoadingCircle />
  );
};
export default DriverAssignTable;
