import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const SubRouteHandler = ({ subroutes }) => {
  const viewStore = useSelector((state) => state.viewStore);
  const { subRouteProgress } = viewStore;
  const dispatch = useDispatch();

  // create new array of objects, with subRouteIndexs and if they have bypassPrevious flag.
  function createSubRoutesDataArray() {
    return subroutes.map((subroute, index) => {
      let bypassPrevious = false;
      if (subroute.props && !!subroute.props.bypassPrevious) {
        bypassPrevious = true;
      }
      return {
        subRouteIndex: index,
        bypassPrevious,
      };
    });
  }
  useEffect(() => {
    const subroutesDataArray = createSubRoutesDataArray();
    dispatch({
      type: 'SET_CURRENT_SUBROUTES_DATA_ARRAY',
      payload: subroutesDataArray,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.createElement(subroutes[subRouteProgress].component, {
    ...subroutes[subRouteProgress].props,
  });
};
export default SubRouteHandler;
