import React from 'react';

const FormErrorMessage = ({ errors }) => {
  let errorMessage = '';
  if (errors) {
    switch (errors.type) {
      case 'pattern':
        errorMessage = 'Please match the required field';
        break;
      case 'required':
        errorMessage = 'This Field is mandatory';
        break;
      case 'validate':
        errorMessage = 'Passwords Do Not Match';
        break;
      case 'minLength':
        errorMessage = 'Value is too short';
        break;
      case 'maxLength':
        errorMessage = 'Value is too long';
        break;
      default:
        errorMessage = 'This Field is mandatory';
        break;
    }
    if (errors.message) errorMessage = errors.message;
  }

  return errors ? (
    <p className="form__error-message" role="alert">
      <strong>{errorMessage}</strong>
    </p>
  ) : null;
};
export default FormErrorMessage;
