import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getAlphabeticallySortedArray } from '../../../utils/functions';
import SearchForm from '../../../shared/SearchForm';
import ConnectedHauliersTableRow from './rows/ConnectedHaulierTableRow';

const ConnectedHauliersTable = ({
  connectedHauliers,
  connectedHauliersLoaded,
  setSelectedHaulier,
}) => {
  const [availableHauliers, setAvailableHauliers] = useState([]);
  const [connectedHaulierFilter, setConnectedHaulierFilter] = useState('');

  const handleConnectedChange = (event) => {
    setConnectedHaulierFilter(event.target.value);
  };

  const filterConnectedHauliers = (haulierList, filter) => {
    const sortedHaulierList = getAlphabeticallySortedArray(
      haulierList,
      'haulier_name'
    );

    return filter
      ? sortedHaulierList.filter((haulier) =>
          haulier.haulier_name.toLowerCase().includes(filter.toLowerCase())
        )
      : sortedHaulierList;
  };

  useEffect(() => {
    setAvailableHauliers(
      getAlphabeticallySortedArray(availableHauliers, 'haulier_name')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!connectedHauliersLoaded) {
    return (
      <div className="connect-haulier__table-container">
        <div className="connect-haulier__table-container-contents">
          <Typography
            variant="h6"
            component="h6"
            gutterBottom
            className="u-highlight-title "
          >
            Connected Hauliers
          </Typography>
          <LinearProgress />
        </div>
      </div>
    );
  }

  return (
    <div className="connect-haulier__table-container">
      <div className="connect-haulier__table-container-contents">
        <Typography
          variant="h6"
          component="h6"
          gutterBottom
          className="u-highlight-title "
        >
          Connected Hauliers
        </Typography>

        {connectedHauliers.length > 0 ? (
          <>
            <div className="connect-haulier__filter">
              <SearchForm
                label="Search Connected Hauliers"
                value={connectedHaulierFilter}
                onChange={handleConnectedChange}
              />
            </div>

            <TableContainer className="mui-table">
              <Table stickyHeader aria-label="haulier sites table">
                <TableHead>
                  <TableRow>
                    <TableCell>Organisation Name</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterConnectedHauliers(
                    connectedHauliers,
                    connectedHaulierFilter
                  ).map((haulier) => {
                    return (
                      <ConnectedHauliersTableRow
                        haulier={haulier}
                        key={haulier.haulier_id}
                        setSelectedHaulier={setSelectedHaulier}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          'You currently do not have any Hauliers connected to your site.'
        )}
      </div>
    </div>
  );
};

export default ConnectedHauliersTable;
