import React, { useState, useEffect } from 'react';
import TableContainer from '@material-ui/core/TableContainer';

import { useDispatch } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';

import SearchForm from '../../../shared/SearchForm';
import { callGetVehicles } from '../../../api/kerb-construction';
import { getAlphabeticallySortedArray } from '../../../utils/functions';
import ManageVehiclesTableRow from './ManageVehiclesTableRow';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';

const ManageVehiclesTable = () => {
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesLoaded, setVehiclesLoaded] = useState(false);

  const handleErrorResponse = useHandleErrorResponse();

  const loadVehicles = () => {
    setVehiclesLoaded(false);

    callGetVehicles()
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then((data) => {
        setVehicles(data);
        setVehiclesLoaded(true);
      })
      .catch((err) => {
        handleErrorResponse(err);
        setVehiclesLoaded(true);
      });
  };

  const dispatch = useDispatch();

  const [filterText, setFilterText] = useState('');

  const sortedFilteredVehicleData = () => {
    const sortedVehicleData = getAlphabeticallySortedArray(vehicles, 'vrm');

    return filterText
      ? sortedVehicleData.filter((vehicle) =>
          vehicle.vrm.toLowerCase().includes(filterText.toLowerCase())
        )
      : sortedVehicleData;
  };

  const handleFilterTextChange = (event) => {
    setFilterText(event.target.value);
  };

  const progressToAddVehicle = (event) => {
    event.preventDefault();

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 1,
    });
  };

  const tableHeading = () => {
    return (
      <TableRow>
        <TableCell className="manage-vehicles-view__table-heading">
          Vehicle Registration (VRM)
        </TableCell>
        <TableCell className="manage-vehicles-view__table-heading">
          Status
        </TableCell>
      </TableRow>
    );
  };

  const tableBody = () => {
    return sortedFilteredVehicleData().map((vehicle) => {
      return (
        <ManageVehiclesTableRow
          key={vehicle.vehicle_id}
          vehicle={vehicle}
          loadVehicles={loadVehicles}
        />
      );
    });
  };

  useEffect(() => {
    loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="manage-vehicles-view__manage-vehicles-container">
      <div className="container">
        <TableContainer className="mui-table">
          <header className="manage-vehicles-view__header">
            <Typography
              variant="h6"
              component="h6"
              gutterBottom
              className="u-highlight-title manager-vehicles__heading"
            >
              Please select a Vehicle that you would like to manage
            </Typography>

            <Button className="button --small" onClick={progressToAddVehicle}>
              Add Vehicle
            </Button>
          </header>

          <div className="filter-text-box">
            <SearchForm
              label="Search"
              value={filterText}
              onChange={handleFilterTextChange}
            />
          </div>

          <div className="manage-vehicles-view__table-container">
            <div className="manage-vehicles-view__table-container-contents">
              {vehiclesLoaded ? (
                <>
                  <Table
                    className="manage-vehicles-view__table"
                    stickyHeader
                    aria-label="manage vehicles table"
                  >
                    <TableHead>{tableHeading()}</TableHead>
                    <TableBody>{tableBody()}</TableBody>
                  </Table>
                </>
              ) : (
                <LinearProgress />
              )}
            </div>
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

export default ManageVehiclesTable;
