const initialState = {
  isLoggedIn: false,
  isHaulier: false,
  status: null,
  currentUserType: null,
  details: {
    username: null,
  },
  permissions: null,
  organisation: {
    address: {
      city: null,
      country_code: null,
      county: null,
      line_1: null,
      line_2: null,
      line_3: null,
      line_4: null,
      postcode: null,
    },
    contact_email: null,
    invoice_email: null,
    discount: null,
    id: null,
    name: null,
    phone: null,
  },
  tempPassword: null,
  userResettingPassword: false,
};

const currentUserStore = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER_LOGGED_IN':
      return {
        ...state,
        isLoggedIn: true,
      };
    case 'SET_CURRENT_USER_IS_HAULIER':
      return {
        ...state,
        isHaulier: true,
      };
    case 'SET_CURRENT_USER_IS_NOT_HAULIER':
      return {
        ...state,
        isHaulier: false,
      };
    case 'SET_CURRENT_USER_DETAILS':
      return {
        ...state,
        details: {
          ...action.payload,
        },
      };
    case 'SET_TEMP_PASSWORD':
      return {
        ...state,
        tempPassword: action.payload,
      };
    case 'SET_CURRENT_USER_GROUP':
      return {
        ...state,
        currentUserType: action.payload,
      };
    case 'SET_CURRENT_USER_ORGANISATION_DETAILS':
      return {
        ...state,
        organisation: {
          ...action.payload,
        },
      };
    case 'SET_CURRENT_USER_ORGANISATION_NAME':
      return {
        ...state,
        organisation: {
          name: action.payload,
        },
      };
    case 'SET_CURRENT_USER_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'SET_CURRENT_USER_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload,
      };
    case 'SET_USER_RESETTING_PASSWORD':
      return {
        ...state,
        userResettingPassword: action.payload,
      };

    default:
      return state;
  }
};

export default currentUserStore;
