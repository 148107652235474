// ../../views
import { Redirect } from 'react-router-dom';
import LandingViewConstruction from '../../views/Landing/LandingViewConstruction';
import LandingViewHaulier from '../../views/Landing/LandingViewHaulier';
import LoginView from '../../views/Login/LoginView';
import RegisterView from '../../views/Register/RegisterView';
import ConfirmationCodeView from '../../views/ConfirmationCode/ConfirmationCodeView';
import ManageUsersView from '../../views/ManageUsers/ManageUsersView';
import AddOrganisationView from '../../views/AddOrganisation/AddOrganisationView';
import DashboardView from '../../views/Dashboard/DashboardView';
import AssignBookingView from '../../views/BookingOverview/AssignBooking';
import ErrorPageView from '../../views/ErrorPage/ErrorPageView';
import ManageVehiclesView from '../../views/ManageVehicles/ManageVehiclesView';
import ConnectHauliersView from '../../views/ConnectHauliers/ConnectHauliersView';
import BookingOverviewView from '../../views/BookingOverview/BookingOverviewView';
import HaulierSitesView from '../../views/HaulierSites/HaulierSitesView';
import ResetPasswordView from '../../views/ResetPassword/ResetPasswordView';
import NewPasswordView from '../../views/NewPassword/NewPasswordView';
import HelpView from '../../views/Help/HelpView';
import HelpIcon from '../../images/Components/Help';

const routesHaulier = [
  {
    path: '/',
    title: 'Welcome',
    component: LandingViewConstruction,
    isPrivate: false,
    header: false,
  },
  {
    path: '/haulier',
    title: 'Welcome',
    component: LandingViewHaulier,
    isPrivate: false,
    header: false,
  },
  {
    path: '/login',
    title: 'Login',
    component: LoginView,
    isPrivate: false,
    header: false,
  },
  {
    path: '/register',
    title: 'Register',
    component: RegisterView,
    isPrivate: false,
    header: false,
  },
  {
    path: '/confirm',
    title: 'Confirmation Code',
    component: ConfirmationCodeView,
    isPrivate: false,
    header: false,
  },
  {
    path: '/newpassword',
    title: 'New Password',
    component: NewPasswordView,
    isPrivate: false,
  },
  {
    path: '/resetpassword',
    title: 'Reset Password',
    component: ResetPasswordView,
    isPrivate: false,
  },
  {
    path: '/error',
    title: 'Error',
    component: ErrorPageView,
    isPrivate: false,
  },

  {
    path: '/dashboard',
    title: 'Dashboard',
    component: DashboardView,
    isPrivate: true,
    header: {
      isDark: false,
      hasLogoutButton: true,
    },
    mobileMenu: true,
  },
  {
    path: '/',
    title: 'Notifications',
    isPrivate: true,
    mobileMenu: {
      onClick: 'toggle-notification',
    },
  },
  {
    path: '/addorganisation',
    title: 'Add Organisation',
    component: AddOrganisationView,
    isPrivate: true,
    header: false,
  },

  {
    path: '/managevehicles',
    title: 'Manage Vehicles',
    component: ManageVehiclesView,
    isPrivate: true,
    header: true,
    mobileMenu: true,
  },
  {
    path: '/manageusers',
    title: 'Manage Users',
    component: ManageUsersView,
    isPrivate: true,
    header: true,
    mobileMenu: true,
  },

  {
    path: '/connecthauliers',
    title: 'Manage Hauliers',
    component: ConnectHauliersView,
    isPrivate: true,
    header: true,
  },
  {
    path: '/hauliersites',
    title: 'Sites & Bookings',
    component: HaulierSitesView,
    isPrivate: true,
    header: true,
    mobileMenu: true,
  },
  {
    path: '/bookings',
    title: 'Bookings Overview',
    component: BookingOverviewView,
    isPrivate: true,
    header: true,
  },
  {
    path: '/assignbooking',
    title: 'Assign Booking',
    component: AssignBookingView,
    isPrivate: true,
    header: true,
  },

  {
    path: '/help',
    title: 'Help',
    component: HelpView,
    isPrivate: true,
    header: true,
    mobileMenu: true,
    mobileMenuIcon: HelpIcon,
  },
  {
    path: '*',
    component: Redirect,
    props: {
      to: '/error',
    },
    isPrivate: false,
  },
];

export default routesHaulier;
