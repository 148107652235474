import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ConnectHaulierHeaderButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleButtonPress = () => {
    history.push('/sites');
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
    dispatch({
      type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
      payload: null,
    });
  };

  return (
    <button
      type="button"
      className="button main-header__button-confirm"
      onClick={handleButtonPress}
    >
      Done
    </button>
  );
};

export default ConnectHaulierHeaderButton;
