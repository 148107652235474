import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const EditBookingButton = ({ cardModalData }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const editBookingDetails = () => {
    dispatch({
      type: 'SET_EDIT_BOOKING_DETAILS',
      payload: { ...cardModalData },
    });
    history.push('/editbooking');
  };

  return (
    <div className="booking-modal__edit-booking-button-container">
      <button
        className="button booking-modal__edit-booking-button"
        type="button"
        onClick={editBookingDetails}
      >
        Make Changes
      </button>
    </div>
  );
};

export default EditBookingButton;
