import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { StylesProvider } from '@material-ui/core/styles';
import App from './App';
import rootReducer from './store/index';
import {
  logPackageVersionDetails,
  initGoogleAnalytics,
  initHotJar,
} from './utils/functions';

const persistedState = localStorage.getItem('reduxStore')
  ? JSON.parse(localStorage.getItem('reduxStore'))
  : {};

const store = createStore(
  rootReducer,
  persistedState,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function persistStoreToSessionStorage() {
  store.subscribe(() => {
    localStorage.setItem('reduxStore', JSON.stringify(store.getState()));
  });
}

persistStoreToSessionStorage();
logPackageVersionDetails();

if (
  // only init analytics on production domain.
  process.env.NODE_ENV === 'production' &&
  window.location.href.includes('kerb-construction.com')
) {
  initGoogleAnalytics();
  initHotJar();
}

ReactDOM.render(
  <StylesProvider injectFirst>
    <Provider store={store}>
      <App />
    </Provider>
  </StylesProvider>,

  document.getElementById('root')
);
