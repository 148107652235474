import React from 'react';
import useBackButtonHandler from '../../hooks/useBackButtonHandler';
import BackIcon from '../../images/Components/BackIcon';

const MobileBackButton = () => {
  const redirectToPreviousPage = useBackButtonHandler();

  function handleButtonClick() {
    redirectToPreviousPage();
  }

  return (
    <button
      type="button"
      className="button main-header__header-button main-header__back-button"
      onClick={handleButtonClick}
    >
      <BackIcon />
    </button>
  );
};
export default MobileBackButton;
