const initialState = {
  subRouteProgress: 0,
  subRouteData: null,
  alert: {
    message: null,
    isError: false,
  },
  headerRightButtonComponent: null,
  hasNotification: false,
  notificationPanelOpen: false,
  confirmationCode: null,
  currentRoute: '',
};

const viewStore = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SUB_ROUTE_PROGRESS':
      return {
        ...state,
        subRouteProgress: action.payload,
      };
    case 'SET_CURRENT_SUBROUTES_DATA_ARRAY':
      return {
        ...state,
        subRouteData: action.payload,
      };
    case 'SET_HAS_NOTIFICATION':
      return {
        ...state,
        hasNotification: true,
      };
    case 'CLEAR_HAS_NOTIFICATION':
      return {
        ...state,
        hasNotification: false,
      };
    case 'SET_ALERT':
      return {
        ...state,
        alert: {
          message: action.payload,
          isError: false,
        },
      };
    case 'SET_ERROR_ALERT':
      return {
        ...state,
        alert: {
          message: action.payload,
          isError: true,
        },
      };
    case 'CLEAR_ALERT':
      return {
        ...state,
        alert: {
          message: null,
          isError: false,
        },
      };
    case 'SET_HEADER_RIGHT_BUTTON_COMPONENT':
      return {
        ...state,
        headerRightButtonComponent: action.payload,
      };

    case 'SET_NOTIFICATION_PANEL_OPEN':
      return {
        ...state,
        notificationPanelOpen: action.payload,
      };

    case 'SET_CONFIRMATION_CODE':
      return {
        ...state,
        confirmationCode: action.payload,
      };
    case 'SET_CURRENT_ROUTE':
      return {
        ...state,
        currentRoute: action.payload,
      };
    default:
      return state;
  }
};

export default viewStore;
