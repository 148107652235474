/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import close from '../../images/close.svg';
import {
  callGetUnassignedBookings,
  callGetRejectedBookings,
} from '../../api/kerb-construction';
import useCallApi from '../../hooks/useCallApi';
import NotificationsContainer from './NotificationsContainer';
import WebsocketWrapper from '../WebsocketWrapper';

const NotificationPanel = () => {
  const dispatch = useDispatch();
  const notificationPanel = useRef();
  const todaysDate = format(new Date(), 'yyyy-MM-dd');
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const haulierId = currentUserStore.organisation.id;

  const [unassignedBookingsLoading, unassignedBookings] = useCallApi(
    callGetUnassignedBookings,
    [todaysDate]
  );

  const [rejectedBookingsLoading, rejectedBookings] = useCallApi(
    callGetRejectedBookings
  );

  const handleClose = () => {
    dispatch({
      type: 'SET_NOTIFICATION_PANEL_OPEN',
      payload: false,
    });
  };

  return (
    <>
      <aside className="notification-panel" ref={notificationPanel}>
        <header>
          <h3>Notifications</h3>
          <button
            tabIndex={0}
            type="button"
            className="button button-close"
            onClick={handleClose}
          >
            <img src={close} alt="close button" />
          </button>
        </header>
        <WebsocketWrapper connectionType="haulier_id" connectionID={haulierId}>
          <NotificationsContainer
            rejectedBookingsLoading={rejectedBookingsLoading}
            rejectedBookings={rejectedBookings}
            unassignedBookingsLoading={unassignedBookingsLoading}
            unassignedBookings={unassignedBookings}
            handleClose={handleClose}
            notificationPanelRef={notificationPanel}
          />
        </WebsocketWrapper>
      </aside>
      <div className="notification-panel overlay" onClick={handleClose} />
    </>
  );
};
NotificationPanel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
};
export default NotificationPanel;
