import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import { callPatchBooking } from '../../../api/kerb-construction';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import penImage from '../../../images/pen.svg';
import Close from '../../../images/Components/Close';

const ButtonRecurringEditRow = ({
  booking,
  originalValueFunctions,
  originalRecurringBookingData,
  assignmentType,
  hasRecordChanged,
  formattedEndDate,
  bookingCount,
}) => {
  const dispatch = useDispatch();
  const handleErrorResponse = useHandleErrorResponse();

  const storeRecurringBooking = originalValueFunctions.storeRecurring;
  const removeRecurringBooking = originalValueFunctions.removeRecurring;

  const bookingId = booking.id;
  const bookingRecurrentID = booking.recurrent_id;

  const originalRecurringBookingDetails = originalRecurringBookingData;

  // Taken from example given in Material Drop Down Documentation
  // From Here

  const [showDropdownOptions, setShowDropdownOptions] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setShowDropdownOptions((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setShowDropdownOptions(false);
  };

  const prevOpen = useRef(showDropdownOptions);

  useEffect(() => {
    if (
      anchorRef.current &&
      prevOpen.current === true &&
      showDropdownOptions === false
    ) {
      anchorRef.current.focus();
    }

    prevOpen.current = showDropdownOptions;
  }, [showDropdownOptions]);

  // To Here

  function handleEditRow() {
    // Avoids overiding with another future change
    // e.g. if the driver is being changed but the VRM already has
    if (!(bookingRecurrentID in originalRecurringBookingDetails)) {
      storeRecurringBooking(booking);
    }

    dispatch({
      type: 'SET_EDIT_BOOKING_ID',
      payload: bookingId,
    });

    dispatch({
      type: 'SET_EDIT_BOOKING_ASSIGNMENT_TYPE',
      payload: assignmentType,
    });

    dispatch({
      type: 'SET_EDIT_BOOKING_DETAILS',
      payload: { ...booking },
    });

    dispatch({
      type: 'SET_EDIT_RECURRING_BOOKING_PARAMS',
      payload: {
        bookingCount,
        endDate: formattedEndDate,
      },
    });

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 1,
    });
  }

  function clearEditRow() {
    const originalDriverAndVehicleID = {
      vehicle_id:
        originalRecurringBookingDetails[bookingRecurrentID].vehicle_id,
      driver_id: originalRecurringBookingDetails[bookingRecurrentID].driver_id,
      patch_all_recurrent: true,
    };

    if (
      originalDriverAndVehicleID.vehicle_id === undefined ||
      assignmentType === 'driver'
    ) {
      delete originalDriverAndVehicleID.vehicle_id;
    }

    if (
      originalDriverAndVehicleID.driver_id === undefined ||
      assignmentType === 'vehicle'
    ) {
      delete originalDriverAndVehicleID.driver_id;
    }

    callPatchBooking(bookingId, originalDriverAndVehicleID)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then(() => {
        removeRecurringBooking(assignmentType, booking);

        dispatch({
          type: 'SET_EDIT_BOOKING_ID',
          payload: null,
        });
      })

      .catch((err) => {
        handleErrorResponse(err);
      });
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {showDropdownOptions ? (
          <>
            <button
              type="button"
              ref={anchorRef}
              aria-controls={showDropdownOptions ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              className="button button-edit-row --unselect"
            >
              <Close />
            </button>
            <div className="dropdown-menu --assign-booking">
              <button
                type="button"
                className="button dropdown-option"
                onClick={handleEditRow}
              >
                Change Selection
              </button>

              {hasRecordChanged && (
                <>
                  <button
                    type="button"
                    className="button dropdown-option"
                    onClick={clearEditRow}
                  >
                    Undo
                  </button>
                </>
              )}
            </div>
          </>
        ) : (
          <button
            type="button"
            className={`button button-edit-row button-edit-${assignmentType}`}
            onClick={handleToggle}
          >
            <img src={penImage} alt="edit booking" />
          </button>
        )}
      </div>
    </ClickAwayListener>
  );
};
export default ButtonRecurringEditRow;
