import React from 'react';
import MetroTruck from '../../../../images/Components/MetroTruck';
import MetroTruckUnplanned from '../../../../images/Components/MetroTruckUnplanned';
import MetroTruckRecurring from '../../../../images/Components/MetroTruckRecurring';
import MetroTruckInTransit from '../../../../images/Components/MetroTruckInTransit';
import MetroTruckRecurringInTransit from '../../../../images/Components/MetroTruckRecurringInTransit';

const BookingCardIcon = ({ cardData }) => {
  const inTransit = cardData.in_transit;
  const { type, recurrent } = cardData;

  const SetIcon = () => {
    if (type === 'PLANNED' && recurrent && !inTransit) {
      return <MetroTruckRecurring className="booking-card__icon" />;
    }

    if (type === 'PLANNED' && recurrent && inTransit) {
      return <MetroTruckRecurringInTransit className="booking-card__icon" />;
    }

    if (type === 'PLANNED' && !recurrent && !inTransit) {
      return <MetroTruck className="booking-card__icon" />;
    }

    if (type === 'PLANNED' && !recurrent && inTransit) {
      return <MetroTruckInTransit className="booking-card__icon" />;
    }

    return <MetroTruckUnplanned className="booking-card__icon" />;
  };

  return <SetIcon />;
};
export default BookingCardIcon;
