import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import UpcomingBookingRow from './UpcomingBookingRow';

import { getStartTimeOrderedBookings } from '../../../utils/bookingFunctions';

const UpcomingBookingsTable = ({ bookings }) => {
  const unplannedUpcomingRecords = bookings.filter((booking) => {
    return booking.type === 'UNPLANNED';
  });

  const recurrentUpcomingRecords = bookings.filter((booking) => {
    return booking.type === 'PLANNED' && booking.recurrent;
  });

  const singlePlannedUpcomingRecords = bookings.filter((booking) => {
    return booking.type === 'PLANNED' && !booking.recurrent;
  });

  const timeSortedBookings = getStartTimeOrderedBookings([
    ...unplannedUpcomingRecords,
    ...singlePlannedUpcomingRecords,
  ]);

  const presentedListOfBookings = [
    ...timeSortedBookings,
    ...recurrentUpcomingRecords,
  ];

  return (
    <TableContainer className="mui-table">
      <Table
        className="--upcoming-bookings-table"
        aria-label="Driver Assignment table"
      >
        <TableHead className="booking-schedule-view__upcoming-bookings-table-head">
          <TableRow>
            <TableCell className="booking-schedule-view__upcoming-bookings-table-heading">
              Type
            </TableCell>
            <TableCell className="booking-schedule-view__upcoming-bookings-table-heading">
              Material
            </TableCell>
            <TableCell className="booking-schedule-view__upcoming-bookings-table-heading">
              Company
            </TableCell>
            <TableCell className="booking-schedule-view__upcoming-bookings-table-heading">
              Site Access
            </TableCell>
            <TableCell className="booking-schedule-view__upcoming-bookings-table-heading">
              Slot
            </TableCell>
            <TableCell className="booking-schedule-view__upcoming-bookings-table-heading">
              First Destination
            </TableCell>
            <TableCell className="booking-schedule-view__upcoming-bookings-table-heading">
              Vehicle (VRM)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="booking-schedule-view__upcoming-table-body">
          {presentedListOfBookings.map((booking) => {
            return <UpcomingBookingRow key={booking.id} booking={booking} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UpcomingBookingsTable;
