import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { getAvailableSiteEntranceBookingSlotsArray } from '../../../utils/siteConfigFunctions';

const AvailableBookingSlotDropdown = ({
  bookingFormState,
  setBookingFormState,
}) => {
  const selectedBookingSlot = bookingFormState.bookingSlot || 'none';

  const setSelectedBookingSlot = (newBookingSlot) => {
    setBookingFormState({
      ...bookingFormState,
      bookingSlot: newBookingSlot,
    });
  };

  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const { siteEntrance } = bookingFormState;

  const availableSiteEntranceBookingSlots =
    getAvailableSiteEntranceBookingSlotsArray(
      selectedSiteStore.config,
      siteEntrance
    );

  useEffect(() => {
    setSelectedBookingSlot({ start: null, end: null, label: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBookingSlotSelect = (ev) => {
    const bookingSlotSelection = ev.target.value;

    if (bookingSlotSelection !== 'none') {
      setSelectedBookingSlot(bookingSlotSelection);
    } else {
      setSelectedBookingSlot('none');
    }
  };

  return (
    <FormControl required variant="outlined">
      {availableSiteEntranceBookingSlots ? (
        <Select
          value={selectedBookingSlot}
          onChange={handleBookingSlotSelect}
          name="bookingSlot"
          defaultValue="none"
        >
          <MenuItem key="none" value="none">
            Select a Booking Slot
          </MenuItem>

          {availableSiteEntranceBookingSlots.map((bookingSlot) => {
            return (
              <MenuItem key={bookingSlot.label} value={bookingSlot}>
                {bookingSlot.label}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <TextField
          name="bookingSlot"
          label="Booking Slot"
          variant="outlined"
          disabled
        />
      )}
    </FormControl>
  );
};
export default AvailableBookingSlotDropdown;
