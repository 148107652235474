import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SubRouteHandler from '../../router/SubRouteHandler';
import OperationOverviewSubroute from './subroutes/OperationOverviewSubroute';
import ConfirmDelete from '../../shared/ConfirmDelete/ConfirmDelete';

const OperationOverviewView = () => {
  const dispatch = useDispatch();
  const bookingModalStore = useSelector((state) => state.bookingModalStore);
  const { cardModalData } = bookingModalStore;

  const afterDeleteTidyUp = () => {
    dispatch({
      type: 'SET_MODAL_CLOSED',
    });
    dispatch({
      type: 'SET_MODAL_DATA',
      payload: {},
    });
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
  };

  const OperationOverviewSubroutes = [
    { component: OperationOverviewSubroute },
    {
      component: ConfirmDelete,
      props: {
        deletionType: 'delete-booking',
        entityToDelete: cardModalData,
        successfulDeleteCallBack: afterDeleteTidyUp,
      },
    },
  ];

  return <SubRouteHandler subroutes={OperationOverviewSubroutes} />;
};

export default OperationOverviewView;
