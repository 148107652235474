import React from 'react';

const MetroTruckInTransit = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.462"
      height="14.999"
      viewBox="0 0 30.462 14.999"
      className={className}
      alt="Single Booking"
    >
      <path
        id="Union_1"
        data-name="Union 1"
        d="M-1322.308-1542.808a2.3,2.3,0,0,1,.31-1.154h-6.388a2.3,2.3,0,0,1,.31,1.154,2.308,2.308,0,0,1-2.308,2.308,2.308,2.308,0,0,1-2.308-2.308,2.293,2.293,0,0,1,.31-1.154h-1.463l-1.154-1.154v-9.23a1.157,1.157,0,0,1,1.154-1.154h10.384a1.157,1.157,0,0,1,1.154,1.154v2.308h3.461l2.308,4.615v3.461H-1318a2.3,2.3,0,0,1,.31,1.154A2.308,2.308,0,0,1-1320-1540.5,2.308,2.308,0,0,1-1322.308-1542.808Zm0-4.615h4.123l-1.731-3.461h-2.392ZM-1341-1544.5v-1h5v1Zm-3-2v-1h7v1Zm-3-2v-1h9v1Zm0-2v-1h9v1Zm3-2v-1h7v1Zm3-2v-1h5v1Z"
        transform="translate(1347 1555.5)"
      />
    </svg>
  );
};
export default MetroTruckInTransit;
