import React, { useEffect, useState } from 'react';
import RejectedVehicleNotification from './Notifications/RejectedVehicleNotification';
import UnassignedBookingNotification from './Notifications/UnassignedBookingNotification';
import LoadingCircle from '../LoadingCircle';
import { getUniqueBookingsBySiteId } from '../../utils/bookingFunctions';

const NotificationsContainer = ({
  rejectedBookingsLoading,
  rejectedBookings,
  unassignedBookingsLoading,
  unassignedBookings,
  handleClose,
  notificationPanelRef,
  websocketData,
}) => {
  const [newRejectedNotifications, setNewRejectedNotifications] = useState([]);

  useEffect(() => {
    if (websocketData) {
      setNewRejectedNotifications([...newRejectedNotifications, websocketData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketData]);

  useEffect(() => {
    // add escape key listener
    document.addEventListener(
      'keydown',
      (ev) => {
        if (ev.keyCode === 27) handleClose(ev);
      },
      false
    );

    notificationPanelRef.current.focus();
    return () => {
      document.removeEventListener('keydown', () => {}, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !unassignedBookingsLoading && !rejectedBookingsLoading ? (
    <div className="notification-panel__inner">
      {newRejectedNotifications.map((rejectedBooking) => {
        return <RejectedVehicleNotification booking={rejectedBooking} />;
      })}
      {getUniqueBookingsBySiteId(unassignedBookings).map((notification) => {
        return (
          <UnassignedBookingNotification
            notification={notification}
            handleClose={handleClose}
            key={notification.id}
          />
        );
      })}
      {rejectedBookings.map((rejectedBooking) => {
        return <RejectedVehicleNotification booking={rejectedBooking} />;
      })}
    </div>
  ) : (
    <LoadingCircle />
  );
};

export default NotificationsContainer;
