import React from 'react';

const BookingCardFooter = ({ cardData }) => {
  const bookingStatus = cardData.status;
  const bookingVRM = cardData.vrm;
  const bookingDriver = cardData.driver_id;
  const bookingInTransit = cardData.in_transit;

  const footerMessage = () => {
    if (bookingStatus === 'new' && (!bookingVRM || !bookingDriver)) {
      return 'Booking in Progress';
    }
    if (bookingStatus !== 'new' && bookingInTransit) {
      return 'In Transit to this Location';
    }

    return '';
  };

  const classNameModifier = () => {
    if (bookingStatus === 'new' && (!bookingVRM || !bookingDriver)) {
      return '--in-progress';
    }
    if (bookingStatus !== 'new' && bookingInTransit) {
      return '--in-transit';
    }

    return '';
  };

  return (
    footerMessage() !== '' && (
      <div className={`booking-card__card-footer ${classNameModifier()}`}>
        <div className="booking-card__footer-text">{footerMessage()}</div>
      </div>
    )
  );
};
export default BookingCardFooter;
