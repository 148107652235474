import React from 'react';
import ErrorPageView from './views/ErrorPage/ErrorPageView';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.warn(`[Error]: ${error}, ${errorInfo}`);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return <ErrorPageView />;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}
export default ErrorBoundary;
