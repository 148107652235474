import React, { useState } from 'react';
import SubRouteHandler from '../../router/SubRouteHandler';
import ConnectHauliers from './subroutes/ConnectHauliers';
import ConfirmDelete from '../../shared/ConfirmDelete/ConfirmDelete';

const HauliersView = () => {
  const [selectedHaulier, setSelectedHaulier] = useState(null);
  const [refreshHauliersToggle, setRefreshHauliersToggle] = useState(false);
  const [hasChangeOccurred, setHasChangeOccurred] = useState(false);

  const refreshHauliersList = () => {
    setHasChangeOccurred(true);
    setRefreshHauliersToggle(!refreshHauliersToggle);
  };

  const confirmChangeHasBeenMade = () => {
    setHasChangeOccurred(true);
  };

  const subroutes = [
    {
      component: ConnectHauliers,
      props: {
        setSelectedHaulier,
        refreshHauliersToggle,
        hasChangeOccurred,
        refreshHauliersList,
      },
    },
    {
      component: ConfirmDelete,
      props: {
        deletionType: 'haulier-disconnect',
        entityToDelete: selectedHaulier,
        successfulDeleteCallBack: confirmChangeHasBeenMade,
      },
    },
  ];

  return <SubRouteHandler subroutes={subroutes} />;
};

export default HauliersView;
