import React from 'react';
import TextField from '@material-ui/core/TextField';

const SearchForm = ({ label, value, onChange }) => {
  return (
    <form className="search-form">
      <TextField
        variant="filled"
        label={label}
        value={value}
        onChange={onChange}
        InputProps={{ disableUnderline: true }}
      />
    </form>
  );
};

export default SearchForm;
