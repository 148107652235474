import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  callGetSiteUsers,
  callGetSiteHauliers,
} from '../../../api/kerb-construction';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import useGetUserType from '../../../hooks/useGetUserType';
import LoadingCircle from '../../../shared/LoadingCircle';
import AssignedUserTable from '../tables/AssignedUserTable';
import ConnectedHauliersTable from '../tables/ConnectedHauliersTable';

const SiteUsersPanel = ({ siteId }) => {
  const [siteUsers, setSiteUsers] = useState();
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [connectedHauliers, setConnectedHauliers] = useState();
  const [hauliersLoaded, setHauliersLoaded] = useState(false);
  const [siteHasHauliers, setSiteHasHauliers] = useState(false);
  const handleErrorResponse = useHandleErrorResponse();
  const currentUserType = useGetUserType();

  const isSiteManager = currentUserType === 'Site-Manager';

  useEffect(() => {
    setUsersLoaded(false);
    callGetSiteUsers(siteId)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then((data) => {
        setSiteUsers(data);
        setUsersLoaded(true);
      })
      .catch((err) => handleErrorResponse(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  useEffect(() => {
    setHauliersLoaded(false);
    callGetSiteHauliers('site_id', siteId)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then((data) => {
        setConnectedHauliers(data);
        setSiteHasHauliers(data.length > 0);
        setHauliersLoaded(true);
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  return (
    <div className="sites-view__users-container">
      <div className="container">
        {usersLoaded && hauliersLoaded ? (
          <>
            <header>
              <h3 className="sites-view__table-heading">
                Assigned Users ({siteUsers.length})
              </h3>
              <Link className="button --small" to="/connectusers">
                Manage Users
              </Link>
            </header>
            <AssignedUserTable siteUsers={siteUsers} />
            <div className="divider" />
            <header>
              <h3
                className={`sites-view__table-heading ${
                  !siteHasHauliers ? '--text-inactive' : ''
                }`}
              >
                Connected Hauliers ({connectedHauliers.length})
              </h3>
              {isSiteManager && (
                <Link className="button --small" to="/connecthauliers">
                  Manage Hauliers
                </Link>
              )}
            </header>
            {siteHasHauliers ? (
              <ConnectedHauliersTable connectedHauliers={connectedHauliers} />
            ) : (
              <p>
                You currently have no Hauliers connected to this site. Please
                add or invite Hauliers that you will be working with, using the
                “Manage Hauliers” button.
              </p>
            )}
          </>
        ) : (
          <LoadingCircle />
        )}
      </div>
    </div>
  );
};
export default SiteUsersPanel;
