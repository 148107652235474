import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AvailableTable from '../tables/AvailableTable';
import ConnectedTable from '../tables/ConnectedTable';

const userTypeEnum = {
  'Site-Manager': 'Site Manager',
  'Site-Traffic-Marshal': 'Site Traffic Marshal',
};

const ConnectUserTableView = ({ selectedUserType }) => {
  const [connectedUsers, setConnectedUsers] = useState(null);
  const [newUserConnected, setNewUserConnected] = useState(false);
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const userTypeString = userTypeEnum[selectedUserType];

  return (
    <div className="container container-flex">
      <header>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          className="u-highlight-title "
        >
          Please select a {userTypeString} that you would like to connect to{' '}
          {selectedSiteStore.name}
        </Typography>
      </header>

      <div className="connect-users-view__split-table-container">
        <div className="connect-users-view__table-container">
          <div className="connect-users-view__table-container-contents">
            <Typography variant="h3" component="h3" gutterBottom>
              Connected {userTypeString}s
            </Typography>

            <ConnectedTable
              setNewUserConnected={setNewUserConnected}
              newUserConnected={newUserConnected}
              setConnectedUsers={setConnectedUsers}
              selectedUserType={selectedUserType}
            />
          </div>
        </div>
        <div className="connect-users-view__table-container">
          <div className="connect-users-view__table-container-contents">
            <Typography variant="h3" component="h3" gutterBottom>
              Available {userTypeString}s
            </Typography>
            <AvailableTable
              setNewUserConnected={setNewUserConnected}
              newUserConnected={newUserConnected}
              connectedUsers={connectedUsers}
              selectedUserType={selectedUserType}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectUserTableView;
