import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HightPriorityImage from '../../../images/High_Priority.svg';

const UnassignedBookingNotification = ({ notification, handleClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleNotificationButtonClick = () => {
    handleClose();
    dispatch({
      type: 'SET_SELECTED_SITE_DETAILS',
      payload: {},
    });
    dispatch({
      type: 'SET_SELECTED_SITE_DETAILS',
      payload: {
        name: notification.site_name,
        id: notification.site_id,
      },
    });

    history.push(`/bookings`);
  };
  return (
    <div
      key={notification.id}
      className="notification-panel__notification-container"
    >
      <div className="notification-panel__high-priority-notification">
        <img src={HightPriorityImage} alt="High Priority Notification" />
      </div>
      <div className="notification-panel__notification-content">
        You have one or more bookings for {notification.site_name}, scheduled
        for today, that are still awaiting assignment of a Driver and/or Vehicle
        Registration
      </div>
      <button
        onClick={handleNotificationButtonClick}
        type="button"
        className="button --view-notification-button"
      >
        View
      </button>
    </div>
  );
};

export default UnassignedBookingNotification;
