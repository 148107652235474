import React, { useState } from 'react';
import ConfirmationButtons from './ConfirmationButtons';

const AddressConfirmationField = ({ data }) => {
  const [editState, setEditState] = useState(false);
  return (
    <div className="add-organisation-view__confirmation-fieldset">
      <span>Organisation Address:</span>
      {Object.entries(data).map((entry) => (
        <input
          data-input-address
          type="text"
          disabled={!editState}
          key={entry[0]}
          placeholder={entry[0]}
          defaultValue={entry[1]}
          className="add-organisation-view__confirmation-input"
        />
      ))}

      <ConfirmationButtons editState={editState} setEditState={setEditState} />
    </div>
  );
};

export default AddressConfirmationField;
