import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import routesHaulier from '../../router/routes/routesHaulier';
import routesConstruction from '../../router/routes/routesConstruction';
import { clearLocalStorage } from '../../utils/functions';
import { callSignOut } from '../../api/auth';
import MobileNavLink from './MobileNavLink';

const NavigationPanel = ({ toggleMenuOpen }) => {
  const [animateNav, setAnimateNav] = useState(false);
  const currentUserStore = useSelector((state) => state.currentUserStore);

  const { isHaulier, currentUserType } = currentUserStore;
  const history = useHistory();
  const dispatch = useDispatch();
  const userRoutes = isHaulier ? routesHaulier : routesConstruction;
  const mobileNavRoutes = userRoutes.filter((route) => route.mobileMenu);
  const visibleMobileRoutes = mobileNavRoutes.filter((route) =>
    'hiddenFrom' in route ? !route.hiddenFrom.includes(currentUserType) : true
  );

  function handleLogout() {
    clearLocalStorage();
    callSignOut().then(() => {
      history.push(isHaulier ? '/haulier' : '/');
      dispatch({
        type: 'RESET_STORE',
      });
    });
  }

  useEffect(() => {
    setAnimateNav(true);
  }, []);

  return (
    <CSSTransition timeout={0} in={animateNav} classNames="mobile-nav-slide">
      <nav className="mobile-nav">
        <div>
          <header>
            <h2>Menu</h2>
            <button
              id="closeNavMenuButton"
              className="button hamburger hamburger--collapse is-active"
              type="button"
              aria-label="Close Menu"
              aria-controls="navigation"
              onClick={toggleMenuOpen}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </header>
          <div className="mobile-nav__inner">
            <ul>
              {visibleMobileRoutes.map((route) => {
                return (
                  <MobileNavLink
                    route={route}
                    toggleMenuOpen={toggleMenuOpen}
                  />
                );
              })}
            </ul>
          </div>
        </div>

        <button
          type="button"
          onClick={() => handleLogout()}
          className="button --warning"
        >
          Logout
        </button>
      </nav>
    </CSSTransition>
  );
};

const MobileNav = () => {
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const dispatch = useDispatch();

  function toggleMenuOpen() {
    dispatch({
      type: 'SET_NOTIFICATION_PANEL_OPEN',
      payload: false,
    });
    setNavMenuOpen(!navMenuOpen);
  }

  return (
    <>
      <button
        id="openNavMenuButton"
        className={`button hamburger hamburger--collapse ${
          navMenuOpen ? 'is-active' : ''
        }`}
        type="button"
        aria-label="Open Menu"
        aria-controls="navigation"
        onClick={() => toggleMenuOpen()}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      {navMenuOpen && <NavigationPanel toggleMenuOpen={toggleMenuOpen} />}
    </>
  );
};

export default MobileNav;
