import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BookingOverview from './BookingOverview';
import AssignBooking from './AssignBooking';
import HeaderConfirmButton from './components/HeaderConfirmButton';
import SubRouteHandler from '../../router/SubRouteHandler';
import {
  addPropertyAndValueToObject,
  removePropertyFromObject,
  getSiteIdFromHaulierSiteId,
} from '../../utils/functions';
import { callGetBookings } from '../../api/kerb-construction';
import useHandleErrorResponse from '../../hooks/useHandleErrorResponse';
import BookingNotes from './BookingNotes';

function BookingOverviewView() {
  const dispatch = useDispatch();
  const viewStore = useSelector((state) => state.viewStore);
  const editBookingStore = useSelector((state) => state.editBookingStore);
  const { subRouteProgress } = viewStore;
  const handleErrorResponse = useHandleErrorResponse();
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const siteId = getSiteIdFromHaulierSiteId(selectedSiteStore.id);
  const [bookings, setBookings] = useState([]);
  const [bookingsLoaded, setBookingsLoaded] = useState(false);

  const updateBookingData = () => {
    setBookingsLoaded(false);
    callGetBookings(siteId)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then((data) => {
        // Bookings which have already began cannot be re-assigned
        setBookings(
          data.filter((booking) => {
            return 'recurrent' in booking || booking.status === 'new';
          })
        );

        setBookingsLoaded(true);
      })
      .catch((err) => handleErrorResponse(err));
  };

  const [originalPlannedBookings, setOriginalPlannedBookings] = useState({});

  const [originalRecurringBookings, setOriginalRecurringBookings] = useState(
    {}
  );

  const changedPlannedIDs = Object.keys(originalPlannedBookings);

  const changedRecurringIDs = Object.keys(originalRecurringBookings);

  const originalBookingDetails = [
    originalPlannedBookings,
    originalRecurringBookings,
  ];

  const storePlannedBooking = (plannedBooking) => {
    setOriginalPlannedBookings(
      addPropertyAndValueToObject(
        originalPlannedBookings,
        plannedBooking.id,
        plannedBooking
      )
    );
  };

  const removePlannedBooking = (type, plannedBooking) => {
    const bookingId = plannedBooking.id;
    const originalBooking = originalPlannedBookings[bookingId];

    // Checks if the other value is the same
    // If it is, booking can be removed from changed records
    if (
      type === 'vehicle' &&
      plannedBooking.driver_id === originalBooking.driver_id
    ) {
      setOriginalPlannedBookings(
        removePropertyFromObject(originalPlannedBookings, plannedBooking.id)
      );
    } else if (
      type === 'driver' &&
      plannedBooking.vrm === originalBooking.vrm
    ) {
      setOriginalPlannedBookings(
        removePropertyFromObject(originalPlannedBookings, plannedBooking.id)
      );
    }
    updateBookingData();
  };

  const storeRecurrentBooking = (recurrentBooking) => {
    setOriginalRecurringBookings(
      addPropertyAndValueToObject(
        originalRecurringBookings,
        recurrentBooking.recurrent_id,
        recurrentBooking
      )
    );
  };

  const removeRecurrentBooking = (type, recurrentBooking) => {
    const bookingRecurrentID = recurrentBooking.recurrent_id;
    const originalBooking = originalRecurringBookings[bookingRecurrentID];

    // Checks if the other value is the same
    // If it is, booking can be removed from changed records
    if (
      type === 'vehicle' &&
      recurrentBooking.driver_id === originalBooking.driver_id
    ) {
      setOriginalRecurringBookings(
        removePropertyFromObject(originalRecurringBookings, bookingRecurrentID)
      );
    } else if (
      type === 'driver' &&
      recurrentBooking.vrm === originalBooking.vrm
    ) {
      setOriginalRecurringBookings(
        removePropertyFromObject(originalRecurringBookings, bookingRecurrentID)
      );
    }

    updateBookingData();
  };

  // Storing all four functions in single object so child components can access
  const originalValueFunctions = {
    storePlanned: storePlannedBooking,
    removePlanned: removePlannedBooking,
    storeRecurring: storeRecurrentBooking,
    removeRecurring: removeRecurrentBooking,
  };

  function setHeaderButtonComponent() {
    if (subRouteProgress === 0) {
      if (changedPlannedIDs.length + changedRecurringIDs.length > 0) {
        dispatch({
          type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
          payload: HeaderConfirmButton,
        });
      } else {
        dispatch({
          type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
          payload: null,
        });
      }
    }

    if (subRouteProgress > 0) {
      if (editBookingStore.patchBookingParams === {}) {
        dispatch({
          type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
          payload: null,
        });
      } else {
        dispatch({
          type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
          payload: null,
        });
      }
    }
  }

  useEffect(() => {
    setHeaderButtonComponent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalPlannedBookings, originalRecurringBookings]);

  useEffect(() => {
    setHeaderButtonComponent();
    // update booking data only when user returns to BookingOverview
    if (subRouteProgress === 0) {
      updateBookingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subRouteProgress, siteId]);

  useEffect(() => {
    const confirmChanges = editBookingStore.confirmBookingAssignmentChanges;

    if (confirmChanges) {
      setOriginalPlannedBookings({});
      setOriginalRecurringBookings({});
    }

    dispatch({
      type: 'SET_CONFIRM_BOOKING_ASSIGNMENT_CHANGES',
      payload: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editBookingStore.confirmBookingAssignmentChanges]);

  const routes = [
    {
      component: BookingOverview,
      props: {
        originalValueFunctions,
        originalBookingDetails,
        bookings,
        bookingsLoaded,
      },
    },
    {
      component: AssignBooking,
      props: {
        originalValueFunctions,
        originalBookingDetails,
        bypassPrevious: true,
      },
    },
    { component: BookingNotes },
  ];

  return <SubRouteHandler subroutes={routes} />;
}

export default BookingOverviewView;
