import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import LoadingCircle from '../../../shared/LoadingCircle';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';

import inputRefConfig from '../../../shared/inputRef.config';

const OrganisationForsForm = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onTouched',
  });

  const dispatch = useDispatch();
  const forsId = useRef({});
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const organisationDetails = currentUserStore.organisation;

  forsId.current = watch('forsId', '');

  function handleSkip() {
    const { subRouteProgress } = viewStore;

    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: subRouteProgress + 1,
    });
  }

  const onSubmit = (data) => {
    const { subRouteProgress } = viewStore;

    if (formValidated) {
      setFormSubmitting(true);
      dispatch({
        type: 'SET_CURRENT_USER_ORGANISATION_DETAILS',
        payload: {
          ...organisationDetails,
          forsId: data.forsId,
        },
      });
      dispatch({
        type: 'SET_SUB_ROUTE_PROGRESS',
        payload: subRouteProgress + 1,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const errorsLength = Object.keys(errors).length;

    if (errorsLength === 0 && forsId.current.length > 2) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h6" component="h4" gutterBottom>
            Do you have a FORS ID?
          </Typography>
          <TextField
            autoFocus
            inputRef={register({ ...inputRefConfig.textInput })}
            name="forsId"
            label="FORS ID"
            type="text"
            variant="outlined"
          />

          <FormErrorMessage errors={errors.forsId} />

          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <div className="add-organisation-view__two-button-container">
              <Button onClick={handleSkip} variant="contained" color="primary">
                Skip
              </Button>
              <Button
                disabled={!formValidated}
                type="submit"
                variant="contained"
                color="primary"
              >
                Continue
              </Button>
            </div>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
};
export default OrganisationForsForm;
