import React from 'react';
import LoadingCircle from './LoadingCircle';

const ClickWaitButton = (props) => {
  const {
    clickFunction,
    className,
    buttonType,
    disabled,
    apiCalling,
    children,
  } = props;
  return (
    <>
      <button
        // eslint-disable-next-line react/button-has-type
        type={buttonType}
        className={`button button--click-wait ${className || ''}`}
        disabled={disabled || apiCalling}
        onClick={() => {
          clickFunction();
        }}
      >
        {!apiCalling ? children : <LoadingCircle />}
      </button>
    </>
  );
};

export default ClickWaitButton;
