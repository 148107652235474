import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import NewSingleBookingDetailsForm from './Forms/NewSingleBookingDetailsForm';
import NewRecurringBookingDetailsForm from './Forms/NewRecurringBookingDetailsForm';
import EditBookingDetailsForm from './Forms/EditBookingDetailsForm';

const BookingDetailsForm = ({ originalBookingDetails = {} }) => {
  const [bookingFormState, setBookingFormState] = useState(
    originalBookingDetails
  );

  const addBookingStore = useSelector((state) => state.addBookingStore);
  const { newBookingType } = addBookingStore;

  if (originalBookingDetails.id) {
    return (
      <EditBookingDetailsForm
        originalBookingDetails={originalBookingDetails}
        bookingFormState={bookingFormState}
        setBookingFormState={setBookingFormState}
      />
    );
  }

  if (newBookingType === 'single-booking') {
    return (
      <NewSingleBookingDetailsForm
        bookingFormState={bookingFormState}
        setBookingFormState={setBookingFormState}
      />
    );
  }

  if (newBookingType === 'recurring-booking') {
    return (
      <NewRecurringBookingDetailsForm
        bookingFormState={bookingFormState}
        setBookingFormState={setBookingFormState}
      />
    );
  }

  return null;
};

BookingDetailsForm.defaultProps = {
  originalBookingDetails: {},
};

export default BookingDetailsForm;
