const initialState = {
  id: null,
  config: {},
  lat: null,
  lon: null,
  name: null,
  organisation_id: null,
  timezone: null,
};

const selectedSiteStore = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SELECTED_SITE':
      return {
        ...state,
        id: action.payload,
      };
    case 'SET_SELECTED_SITE_DETAILS':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET_SELECTED_SITE_DETAILS':
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default selectedSiteStore;
