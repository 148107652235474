import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import LoadingCircle from '../../../shared/LoadingCircle';
import ResendConfirmationCode from './ResendConfirmationCode';
import ConfirmationCodeInput from './ConfirmationCodeInput';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';

const ConfirmationCodeForm = ({ onSubmit, formSubmitting }) => {
  const [formValidated, setFormValidated] = useState(false);
  const { handleSubmit } = useForm({
    mode: 'onTouched',
  });

  const currentUserStore = useSelector((state) => state.currentUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const { confirmationCode } = viewStore;
  const { username } = currentUserStore.details;

  useEffect(() => {
    if (confirmationCode && confirmationCode.length === 6) {
      setFormValidated(true);
    } else setFormValidated(false);
  }, [confirmationCode]);

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h5" component="h4" gutterBottom>
            Please check your email
          </Typography>
          <p>
            We&apos;ve sent a 6-digit confirmation code to <b>{username}</b>
            <br />
            It will expire shortly, so enter it soon.
          </p>
          <ConfirmationCodeInput />
          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          )}
          <p>
            Keep this window open while checking for your code. <br />
            Remember to check the spam folder!
          </p>
          <hr />
          <ResendConfirmationCode username={username} />
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
};
export default ConfirmationCodeForm;
