const initialState = {
  newUserType: null,
  newUserDetails: {},
  selectedUserDetails: {},
};

const addUserStore = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NEW_USER_TYPE':
      return {
        ...state,
        newUserType: action.payload,
      };
    case 'SET_NEW_USER_DETAILS':
      return {
        ...state,
        newUserDetails: { ...action.payload },
      };
    case 'SET_SELECTED_USER_DETAILS':
      return {
        ...state,
        selectedUserDetails: { ...action.payload },
      };
    default:
      return state;
  }
};

export default addUserStore;
