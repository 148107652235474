import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import LoadingCircle from '../../../shared/LoadingCircle';
import PasswordRequirementsList from '../../../shared/PasswordRequirementsList';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';
import inputRefConfig from '../../../shared/inputRef.config';

const NewPasswordForm = ({ onSubmit, formSubmitting }) => {
  const [formValidated, setFormValidated] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm({
    mode: 'onTouched',
  });
  const password = useRef({});
  const [typedPassword, setTypedPassword] = useState('');

  password.current = watch('password', '');

  function handlePasswordChange(ev) {
    setTypedPassword(ev.currentTarget.value);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // use password.current.length to determine that the last field has been filled.
    const errorsLength = Object.keys(errors).length;

    if (password.current.length > 8 && errorsLength === 0) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h5" component="h4" gutterBottom>
            Please enter a New Password
          </Typography>
          <PasswordRequirementsList typedPassword={typedPassword} />
          <TextField
            autoFocus
            inputRef={register({ ...inputRefConfig.passwordInput })}
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            onChange={(ev) => handlePasswordChange(ev)}
          />
          <FormErrorMessage errors={errors.password} />

          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
};
export default NewPasswordForm;
