import React from 'react';

const BookingCardDetails = ({ cardData }) => {
  const bookingVRM = cardData.vrm ? `VRM: ${cardData.vrm}` : 'No VRM Assigned';

  return (
    <div className="booking-card__card-details">
      <span className="booking-card__vrm">
        <b>{bookingVRM}</b>
      </span>
      <span className="booking-card__material-container">
        Material:{' '}
        <span className="booking-card__material-name">{cardData.material}</span>
      </span>
    </div>
  );
};
export default BookingCardDetails;
