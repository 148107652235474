import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import RecurringBookingIcon from '../../../images/Components/MetroTruckRecurring';
import RecurringInTransitIcon from '../../../images/Components/MetroTruckRecurringInTransit';
import SingleBookingIcon from '../../../images/Components/MetroTruck';
import SingleInTransitIcon from '../../../images/Components/MetroTruckInTransit';
import UnplannedBookingIcon from '../../../images/Components/MetroTruckUnplanned';

import { getBookingSlotStringFromDueStartAndDueEnd } from '../../../utils/timeFunctions';

const UpcomingBookingRow = ({ booking }) => {
  const formatBookingSlot = () => {
    if (booking.recurrent) return 'N/A';

    if (booking.type === 'UNPLANNED') return 'N/A';

    return getBookingSlotStringFromDueStartAndDueEnd(
      booking.due_start,
      booking.due_end
    );
  };

  const bookingTypeIcon = () => {
    if (booking.type === 'UNPLANNED')
      return (
        <UnplannedBookingIcon className="mui-table__booking-type-image --unplanned" />
      );

    if (booking.recurrent && !booking.in_progress)
      return (
        <RecurringBookingIcon className="mui-table__booking-type-image --recurring" />
      );

    if (booking.recurrent && booking.in_progress)
      return (
        <RecurringInTransitIcon className="mui-table__booking-type-image --in-transit  --recurring-in-transit" />
      );

    if (booking.in_progress)
      return (
        <SingleInTransitIcon className="mui-table__booking-type-image --in-transit --planned-in-transit" />
      );

    return (
      <SingleBookingIcon className="mui-table__booking-type-image --planned" />
    );
  };

  return (
    <TableRow key={booking.id}>
      <TableCell>{bookingTypeIcon()}</TableCell>
      <TableCell>{booking.material}</TableCell>
      <TableCell>{booking.haulier_name}</TableCell>
      <TableCell className="booking-schedule-view__upcoming-bookings-table-site-entrance">
        {booking.site_entrance}
      </TableCell>
      <TableCell>{formatBookingSlot()}</TableCell>
      <TableCell className="capitalized-cell">
        {booking.first_destination}
      </TableCell>
      <TableCell>{booking.vrm ? booking.vrm : 'No VRM Assigned'}</TableCell>
    </TableRow>
  );
};

export default UpcomingBookingRow;
