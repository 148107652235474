import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../../shared/FormErrorMessage';
import inputRefConfig from '../../shared/inputRef.config';
import { callPostSupportMessage } from '../../api/kerb-construction';
import useHandleErrorResponse from '../../hooks/useHandleErrorResponse';
import { getCurrentUsernameFromStore } from '../../utils/functions';
import ClickWaitButton from '../../shared/ClickWaitButton';
import packageJSON from '../../../package.json';

const GetHelpForm = () => {
  const { register, errors, handleSubmit } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const dispatch = useDispatch();
  const handleErrorResponse = useHandleErrorResponse();
  const [formValid, setFormValid] = useState(false);
  const [apiCalling, setApiCalling] = useState(false);
  const isFormValidated = (currentValue) => {
    const errorsLength = Object.keys(errors).length;

    if (
      errorsLength > 0 ||
      currentValue.length < 16 ||
      currentValue.length > 1000
    ) {
      return false;
    }

    return true;
  };

  const handleFormChange = (event) => {
    const formValue = event.target.value;
    const isFormValid = isFormValidated(formValue);
    setFormValid(isFormValid);
  };

  function onSubmit(data) {
    const userMessage = data.helpRequest;
    const appVersion = packageJSON.version;
    const currentUsername = getCurrentUsernameFromStore();
    const formattedMessage = `Web App Version: ${appVersion}, Username: ${currentUsername}, Message: [${userMessage}]`;
    setApiCalling(true);
    callPostSupportMessage(formattedMessage)
      .then((response) => {
        return response;
      })
      .then((res) => {
        if (res.status !== 204) {
          setApiCalling(false);
          throw res.json();
        }
        dispatch({
          type: 'SET_SUB_ROUTE_PROGRESS',
          payload: 1,
        });
      })
      .catch((err) => {
        setApiCalling(false);
        handleErrorResponse(err);
      });
  }

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <Typography variant="h5" component="h4" gutterBottom>
          Need Help?
        </Typography>

        <TextField
          inputRef={register({
            ...inputRefConfig.helpRequest,
          })}
          name="helpRequest"
          label="Your Help Request"
          variant="outlined"
          multiline
          rows={10}
          autoFocus
          onChange={handleFormChange}
        />
        <FormErrorMessage errors={errors.helpRequest} />

        <ClickWaitButton
          className="button__help-request --full-width"
          type="submit"
          disabled={!formValid}
          apiCalling={apiCalling}
          clickFunction={handleSubmit(onSubmit)}
        >
          Submit
        </ClickWaitButton>
      </div>
    </form>
  );
};
export default GetHelpForm;
