import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { userTypeConfig } from './addUserType.config';

const UserSuccessForm = () => {
  const addUserStore = useSelector((state) => state.addUserStore);
  const dispatch = useDispatch();
  const firstName = addUserStore.newUserDetails.name;
  const { newUserType } = addUserStore;
  const userTypeTitle = userTypeConfig.filter((userType) => {
    return userType.group === newUserType;
  })[0].title;

  function handleAdduser() {
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
  }
  return (
    <form className="form">
      <div className="form__form-inner">
        <Typography variant="h6" component="h4" gutterBottom>
          Success! You have added a new <b>{userTypeTitle}</b>!
        </Typography>
        <p>
          An Invitation has been sent to <b>{firstName}</b> to Instruct them on
          how to install and login to the app.
        </p>
        <p>What would you like to do next ?</p>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleAdduser}
        >
          Add New User
        </Button>
      </div>
    </form>
  );
};
export default UserSuccessForm;
