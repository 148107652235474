import React from 'react';
import { Link } from 'react-router-dom';

const LoginHelperButtons = () => {
  return (
    <div className="helper-text-container">
      <Link className="u-helper-text" to="/register">
        New User? Click here to register
      </Link>
      <Link className="u-helper-text" to="/resetpassword">
        Forgot your password?
      </Link>
    </div>
  );
};

export default LoginHelperButtons;
