import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { getuserTypeConfigFromCurrentUserPermissions } from './addUserType.config';
import UserTypeButton from '../components/UserTypeButton';

const UserTypeForm = () => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const currentUserPermissions = currentUserStore.permissions;
  const filteredUserTypeConfig = getuserTypeConfigFromCurrentUserPermissions(
    currentUserPermissions
  );

  return (
    <div className="container">
      <Typography
        className="u-text-center"
        variant="h5"
        component="h4"
        gutterBottom
      >
        Please select the role you would like to manage
      </Typography>

      <div className="manage-user-view__user-type-button-container">
        {filteredUserTypeConfig.map((userType) => {
          return React.createElement(UserTypeButton, {
            key: userType.group,
            userType,
          });
        })}
      </div>
    </div>
  );
};

export default UserTypeForm;
