import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useRefreshAmplifySession from '../hooks/useRefreshAmplifySession';
import { tokenCookieExists, expireCookie } from '../utils/functions';
import LoadingCircle from '../shared/LoadingCircle';

const AmplifySessionContainer = ({ children }) => {
  const [hasCookie, setHasCookie] = useState(tokenCookieExists());
  const [refreshAmplifySession, sessionRefreshing] = useRefreshAmplifySession();
  const location = useLocation();

  useEffect(() => {
    setHasCookie(tokenCookieExists());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionRefreshing]);

  useEffect(() => {
    // check if cookie exists on load, and during page transitions. refresh session if it doesnt exist.
    if (!hasCookie && !sessionRefreshing) refreshAmplifySession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    // delete cookie when user closes tab, so that we ensure they get a new cookie when loading app.
    window.addEventListener('beforeunload', expireCookie);
    return () => {
      window.removeEventListener('beforeunload', expireCookie);
    };
  }, []);

  return hasCookie && !sessionRefreshing ? (
    children
  ) : (
    <div className="amplify-session-container__loading-circle-container">
      <LoadingCircle />
    </div>
  );
};
export default AmplifySessionContainer;
