import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BookingDetailsSite from './forms/BookingDetailsSite';
import BookingDetailsTypeForm from './forms/BookingDetailsTypeForm';
import BookingDetailsForm from '../../shared/BookingForms/BookingDetailsForm';
import SubRouteHandler from '../../router/SubRouteHandler';
import LoadingCircle from '../../shared/LoadingCircle';

function AddBookingView() {
  const dispatch = useDispatch();
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const siteIsSelected = !!selectedSiteStore.id;
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const setBookingProgressRoutes = () => {
    if (siteIsSelected) {
      return [
        { component: BookingDetailsTypeForm },
        { component: BookingDetailsForm },
      ];
    }

    return [
      { component: BookingDetailsSite },
      { component: BookingDetailsTypeForm },
      { component: BookingDetailsForm },
    ];
  };

  useEffect(() => {
    setSelectedRoutes(setBookingProgressRoutes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="add-booking-view">
      {selectedRoutes.length > 0 ? (
        <SubRouteHandler subroutes={selectedRoutes} />
      ) : (
        <LoadingCircle />
      )}
    </main>
  );
}

export default AddBookingView;
