import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import LoadingCircle from '../../../shared/LoadingCircle';
import PasswordRequirementsList from '../../../shared/PasswordRequirementsList';
import inputRefConfig from '../../../shared/inputRef.config';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';

const RegisterForm = ({ formSubmitting, onSubmit }) => {
  const [formValidated, setFormValidated] = useState(false);
  const [typedPassword, setTypedPassword] = useState('');
  const { register, handleSubmit, watch, errors } = useForm({
    mode: 'onTouched',
  });
  const password = useRef({});
  const confirmPassword = useRef({});
  const username = useRef({});

  password.current = watch('password', '');
  confirmPassword.current = watch('confirmPassword', '');
  username.current = watch('username', '');

  const passwordsMatch = () => {
    return confirmPassword.current === password.current;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // use password.current.length to determine that the last field has been filled.
    const errorsLength = Object.keys(errors).length;

    if (confirmPassword.current.length > 8 && errorsLength === 0) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  function handlePasswordChange() {
    setTypedPassword(password.current);
  }

  return (
    <>
      <form
        className="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <div className="form__form-inner">
          <FormFieldAnimationContainer>
            <Typography variant="h5" component="h4" gutterBottom>
              Register
            </Typography>
            <TextField
              autoFocus
              inputRef={register(inputRefConfig.nameInput)}
              name="firstName"
              label="First Name*"
              variant="outlined"
            />
            <FormErrorMessage errors={errors.firstName} />

            <TextField
              inputRef={register(inputRefConfig.nameInput)}
              name="lastName"
              label="Last Name*"
              type="text"
              variant="outlined"
            />
            <FormErrorMessage errors={errors.lastName} />

            <TextField
              inputRef={register(inputRefConfig.emailInput)}
              name="username"
              label="name@example.com*"
              type="email"
              variant="outlined"
              value={username.current.toLowerCase()}
            />
            <FormErrorMessage errors={errors.username} />
            <PasswordRequirementsList typedPassword={typedPassword} />
            <TextField
              inputRef={register(inputRefConfig.passwordInput)}
              name="password"
              label="Password*"
              type="password"
              variant="outlined"
              onChange={() => handlePasswordChange()}
            />
            <FormErrorMessage errors={errors.password} />
            <TextField
              inputRef={register({
                ...inputRefConfig.passwordInput,
                validate: () => passwordsMatch(),
              })}
              name="confirmPassword"
              label="Confirm Password*"
              type="password"
              variant="outlined"
            />
            <FormErrorMessage errors={errors.confirmPassword} />

            <Link className="u-helper-text" to="/login">
              Already registered? Click here to Log In
            </Link>
            {formSubmitting ? (
              <LoadingCircle />
            ) : (
              <Button
                disabled={!formValidated}
                type="submit"
                variant="contained"
                color="primary"
              >
                Continue
              </Button>
            )}
          </FormFieldAnimationContainer>
        </div>
      </form>
    </>
  );
};
export default RegisterForm;
