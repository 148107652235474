/* eslint-disable camelcase */
import { Auth } from 'aws-amplify';

export function callSignIn(username, password) {
  return Auth.signIn(username, password);
}
export function callCompleteNewPassword(user, newPassword) {
  return Auth.completeNewPassword(
    user, // the Cognito User Object
    newPassword // the new password
  );
}
export function callCurrentSession() {
  return Auth.currentSession();
}
export function callCurrentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser();
}

export function callSignUp(data) {
  const { password, username } = data;

  return Auth.signUp({
    username,
    password,
    attributes: {
      address: '',
      // email, // optional
      // other custom attributes
    },
    validationData: [],
  });
}
export function callConfirmSignup(username, code) {
  return Auth.confirmSignUp(username, code, {
    // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    forceAliasCreation: true,
  });
}
export function callResendSignUp(username) {
  return Auth.resendSignUp(username);
}

export function callForgotPassword(username) {
  return Auth.forgotPassword(username);
}

export function callForgotPasswordSubmit(username, code, new_password) {
  // Collect confirmation code and new password, then
  return Auth.forgotPasswordSubmit(username, code, new_password);
}

export function callSignOut() {
  return Auth.signOut();
}
