import React from 'react';

const LateCue = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      className={className}
    >
      <path
        id="Icon_material-watch-later"
        data-name="Icon material-watch-later"
        d="M10.5,3A7.5,7.5,0,1,0,18,10.5,7.522,7.522,0,0,0,10.5,3Zm3.15,10.65-3.9-2.4V6.75h1.125v3.9l3.375,2.025Z"
        transform="translate(-3 -3)"
      />
    </svg>
  );
};
export default LateCue;
