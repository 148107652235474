const isSelectedDateValid = (bookingFormData) => {
  return (
    'selectedDate' in bookingFormData && bookingFormData.selectedDate !== null
  );
};

const areSelectedDatesValid = (bookingFormData) => {
  return (
    'recurrentDates' in bookingFormData &&
    bookingFormData.recurrentDates.length > 0
  );
};

const isMaterialValid = (bookingFormData) => {
  return 'material' in bookingFormData && bookingFormData.material !== '';
};

const isSelectedHaulierValid = (bookingFormData) => {
  return (
    'selectedHaulier' in bookingFormData &&
    bookingFormData.selectedHaulier !== null
  );
};

const isSiteEntranceValid = (bookingFormData) => {
  return (
    'siteEntrance' in bookingFormData && bookingFormData.siteEntrance !== ''
  );
};

const isFirstDestinationValid = (bookingFormData) => {
  return (
    'firstDestination' in bookingFormData &&
    bookingFormData.firstDestination !== ''
  );
};

const isBookingSlotValid = (bookingFormData) => {
  return (
    'bookingSlot' in bookingFormData && bookingFormData.bookingSlot !== 'none'
  );
};

const isNewSinglePlannedFormValid = (bookingFormData) => {
  return (
    isSelectedDateValid(bookingFormData) &&
    isMaterialValid(bookingFormData) &&
    isSelectedHaulierValid(bookingFormData) &&
    isSiteEntranceValid(bookingFormData) &&
    isFirstDestinationValid(bookingFormData) &&
    isBookingSlotValid(bookingFormData)
  );
};

const isRecurringPlannedFormValid = (bookingFormData) => {
  return (
    areSelectedDatesValid(bookingFormData) &&
    isMaterialValid(bookingFormData) &&
    isSelectedHaulierValid(bookingFormData) &&
    isSiteEntranceValid(bookingFormData) &&
    isFirstDestinationValid(bookingFormData)
  );
};

const isEditSinglePlannedFormValid = (bookingFormData) => {
  return (
    isSiteEntranceValid(bookingFormData) &&
    isFirstDestinationValid(bookingFormData)
  );
};

const isBookingFormValid = (type, bookingFormData) => {
  switch (type) {
    case 'new__single':
      return isNewSinglePlannedFormValid(bookingFormData);
    case 'new__recurrent':
      return isRecurringPlannedFormValid(bookingFormData);
    case 'edit__single':
      return isEditSinglePlannedFormValid(bookingFormData);
    default:
      return false;
  }
};

export default isBookingFormValid;
