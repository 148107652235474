import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import BookingsAssignmentTable from './tables/BookingsAssignmentTable';

import { getFilteredAssignableBookings } from '../../utils/bookingFunctions';

import BookingIconKeys from '../../shared/BookingIconKeys';

function BookingOverview({
  originalValueFunctions,
  originalBookingDetails,
  bookings,
  bookingsLoaded,
}) {
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);

  return (
    <main className="table-view">
      <div className="container">
        <Typography
          variant="h6"
          component="h6"
          gutterBottom
          className="u-highlight-title"
        >
          Bookings For {selectedSiteStore.name}.
        </Typography>

        <BookingIconKeys showPlanned showRecurring showInTransit />

        <div className="hr" />
        {bookingsLoaded ? (
          <BookingsAssignmentTable
            bookingData={getFilteredAssignableBookings(bookings)}
            originalValueFunctions={originalValueFunctions}
            originalBookingDetails={originalBookingDetails}
          />
        ) : (
          <LinearProgress />
        )}
      </div>
    </main>
  );
}

export default BookingOverview;
