import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import { callHaulierAddVehicle } from '../../../api/kerb-construction';
import LoadingCircle from '../../../shared/LoadingCircle';
import inputRefConfig from '../../../shared/inputRef.config';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';

const VehicleVrm = () => {
  const [formValidated, setFormValidated] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const haulierId = currentUserStore.organisation.id;
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, errors } = useForm({
    mode: 'onTouched',
  });
  const vehicleVRM = useRef(null);
  vehicleVRM.current = watch('vehiclevrm', '');
  const handleErrorResponse = useHandleErrorResponse();

  const onSubmit = (data) => {
    const newVehicleVRM = data.vehiclevrm;
    const { subRouteProgress } = viewStore;

    setFormSubmitting(true);
    callHaulierAddVehicle(haulierId, newVehicleVRM)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then(() => {
        dispatch({
          type: 'SET_SUB_ROUTE_PROGRESS',
          payload: subRouteProgress + 1,
        });

        setFormSubmitting(false);
      })
      .catch((err) => {
        handleErrorResponse(err);
        setFormSubmitting(false);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const errorsLength = Object.keys(errors).length;
    if (errorsLength === 0 && vehicleVRM.current.length > 2) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h5" component="h4" gutterBottom>
            Please enter the Vehicle&apos;s Details
          </Typography>
          <TextField
            autoFocus
            inputRef={register(inputRefConfig.VRMInput)}
            name="vehiclevrm"
            label="VRM"
            type="text"
            variant="outlined"
          />
          <FormErrorMessage errors={errors.vehiclevrm} />
          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Add Vehicle
            </Button>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
};

export default VehicleVrm;
