import React from 'react';
import { useHistory } from 'react-router-dom';
import HelpIcon from '../../images/Components/Help';

const HelpButton = () => {
  const history = useHistory();
  const helpButtonClassName =
    'button main-header__header-button main-header__button-help';
  function handleHelpClick() {
    history.push('/help');
  }

  return (
    <button
      type="button"
      className={helpButtonClassName}
      onClick={handleHelpClick}
    >
      <HelpIcon />
    </button>
  );
};
export default HelpButton;
