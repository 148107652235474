import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const GetHelpConfirmation = () => {
  const history = useHistory();

  const handleButtonPress = () => {
    history.push('/dashboard');
  };

  return (
    <form className="form">
      <div className="form__form-inner">
        <Typography variant="h6" component="h4" gutterBottom>
          Help is on the way!
        </Typography>

        <p>Thank you for submitting your help request.</p>

        <p>We aim to get back to you within 2 working days.</p>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleButtonPress}
        >
          Home
        </Button>
      </div>
    </form>
  );
};
export default GetHelpConfirmation;
