import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getAlphabeticallySortedArray } from '../../../utils/functions';

const ConnectedHauliersTable = ({ connectedHauliers }) => {
  const sortedHauliers = getAlphabeticallySortedArray(
    connectedHauliers,
    'haulier_name'
  );

  return (
    <TableContainer className="mui-table">
      <Table
        className="--table-without-buttons"
        stickyHeader
        aria-label="Connected Haulier table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Organisation Name</TableCell>
            <TableCell align="left">Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedHauliers.map((haulier) => {
            const postCode = haulier.haulier_address
              ? haulier.haulier_address.postcode
              : 'N/A';
            return (
              <TableRow key={haulier.id}>
                <TableCell scope="site">{haulier.haulier_name}</TableCell>
                <TableCell align="left">{postCode}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConnectedHauliersTable;
