import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import useIsMobileDevice from '../../hooks/useIsMobileDevice';

import SingleRowBookingTable from './tables/SingleRowBookingTable';
import AssignmentTable from './tables/AssignTables/AssignmentTable';
import FooterButton from './components/FooterButton';

const AssignBooking = ({ originalValueFunctions }) => {
  const dispatch = useDispatch();
  const isMobileDevice = useIsMobileDevice();
  const editBookingStore = useSelector((state) => state.editBookingStore);
  const { bookingAssignmentType } = editBookingStore;
  const viewStore = useSelector((state) => state.viewStore);
  const { headerRightButtonComponent } = viewStore;

  const SaveChangesButton = () => {
    return headerRightButtonComponent
      ? React.createElement(headerRightButtonComponent)
      : '';
  };

  useEffect(() => {
    // Clears any store driver or vrm details after closing the page
    // This prevents current stored data wrongfully being displayed in future
    // assignments

    return () => {
      dispatch({
        type: 'SET_EDIT_BOOKING_VRM',
        payload: null,
      });
      dispatch({
        type: 'SET_EDIT_BOOKING_DRIVER_NAME',
        payload: null,
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="assign-booking-view">
      <div className="assign-booking-view__booking-summary">
        <div className="container">
          <Typography
            variant="h6"
            component="h6"
            gutterBottom
            className="u-highlight-title"
          >
            Please select the {bookingAssignmentType} that you would like to
            assign to this booking:
          </Typography>
          <SingleRowBookingTable
            booking={editBookingStore.editBookingDetails}
          />
        </div>
      </div>
      <AssignmentTable
        assignmentType={bookingAssignmentType}
        originalValueFunctions={originalValueFunctions}
      />

      {isMobileDevice && headerRightButtonComponent && (
        <FooterButton Button={SaveChangesButton} />
      )}
    </main>
  );
};

export default AssignBooking;
