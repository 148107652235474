const initialState = {
  editBookingDetails: {
    active: null,
    completed: null,
    creator_id: null,
    date_created: null,
    date_modified: null,
    due_end: null,
    due_start: null,
    first_destination: null,
    haulier_id: null,
    haulier_name: null,
    id: null,
    in_transit: false,
    location: { lat: null, lon: null },
    material: null,
    modifier_id: null,
    name: null,
    site_id: null,
    site_name: null,
    status: null,
    status_detail: null,
    timestamp: null,
    type: null,
    vrm: null,
    driver_id: null,
    driver_name: null,
  },
  editRecurringBookingParams: {},
  patchBookingParams: {},
  bookingAssignmentType: null,
  bookingIdSelectedForEdit: null,
  confirmBookingAssignmentChanges: false,
};

const editBookingStore = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EDIT_BOOKING_DETAILS':
      return {
        ...state,
        editBookingDetails: {
          ...state.editBookingDetails,
          ...action.payload,
        },
      };
    case 'SET_PATCH_BOOKING_PARAM':
      return {
        ...state,
        patchBookingParams: {
          ...state.patchBookingParams,
          ...action.payload,
        },
      };

    case 'RESET_PATCH_BOOKING_PARAM':
      return {
        ...state,
        patchBookingParams: {},
      };

    case 'SET_EDIT_BOOKING_ID':
      return {
        ...state,
        bookingIdSelectedForEdit: action.payload,
      };

    case 'SET_EDIT_BOOKING_ASSIGNMENT_TYPE':
      return {
        ...state,
        bookingAssignmentType: action.payload,
      };
    case 'SET_EDIT_BOOKING_VRM':
      return {
        ...state,
        editBookingDetails: {
          ...state.editBookingDetails,
          vrm: action.payload,
        },
      };
    case 'SET_EDIT_BOOKING_DRIVER_NAME':
      return {
        ...state,
        editBookingDetails: {
          ...state.editBookingDetails,
          driver_name: action.payload,
        },
      };
    case 'SET_EDIT_RECURRING_BOOKING_PARAMS':
      return {
        ...state,
        editRecurringBookingParams: action.payload,
      };
    case 'SET_CONFIRM_BOOKING_ASSIGNMENT_CHANGES':
      return {
        ...state,
        confirmBookingAssignmentChanges: action.payload,
      };
    default:
      return state;
  }
};

export default editBookingStore;
