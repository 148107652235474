import React from 'react';
import PlannedIcon from '../../../../images/Components/MetroTruck';
import UnplannedIcon from '../../../../images/Components/MetroTruckUnplanned';
import RecurrentIcon from '../../../../images/Components/MetroTruckRecurring';

const BookingModalHeader = ({ cardModalData }) => {
  const isRecurrent = cardModalData.recurrent;
  const isPlanned =
    cardModalData.type === 'PLANNED' && !cardModalData.recurrent;

  const getJobType = () => {
    if (isPlanned) {
      return <>Planned</>;
    }
    if (isRecurrent) {
      return <>Recurring</>;
    }
    return <>Unplanned</>;
  };

  const getJobTypeIcon = () => {
    if (isPlanned) {
      return (
        <>
          <PlannedIcon className="booking-modal__header-icon" />
        </>
      );
    }
    if (isRecurrent) {
      return (
        <>
          <RecurrentIcon className="booking-modal__header-icon" />
        </>
      );
    }
    return (
      <>
        <UnplannedIcon className="booking-modal__header-icon" />
      </>
    );
  };

  return (
    <header className="booking-modal__header">
      <h3 className="booking-modal__header-VRM">
        {cardModalData.vrm ? `VRM: ${cardModalData.vrm}` : 'No VRM Assigned'}
      </h3>
      <span>
        <div className="booking-modal__header-job-type">
          <span className="booking-modal__header-job-type-text">
            Job Type: {getJobType()}
          </span>
          {getJobTypeIcon()}
        </div>

        <h3 className="booking-modal__header-status">
          Status: {cardModalData.status}
        </h3>
      </span>
    </header>
  );
};

export default BookingModalHeader;
