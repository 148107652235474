import React, { useState, useEffect } from 'react';
import { format, parseISO, sub } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, TextField, Button } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import AvailableSiteEntrances from '../components/AvailableSiteEntranceFieldWithButtons';
import AvailableFirstDestinations from '../components/AvailableFirstDestinationWithButtons';
import LoadingCircle from '../../LoadingCircle';
import { callPatchBooking } from '../../../api/kerb-construction';
import isBookingFormValid from '../functions/bookingFormFunctions';
import useConditionalCallApi from '../../../hooks/useConditionalCallApi';

const EditBookingDetailsForm = ({
  originalBookingDetails,
  bookingFormState,
  setBookingFormState,
}) => {
  const { handleSubmit } = useForm();
  const { material } = originalBookingDetails;
  const haulierName = originalBookingDetails.haulier_name;
  const bookingId = originalBookingDetails.id;
  const dueStart = parseISO(originalBookingDetails.due_start);
  const dueStartMinus24Hours = sub(dueStart, {
    days: 1,
  });
  const bookingSlot = `${format(
    parseISO(originalBookingDetails.due_start),
    'HH:mm'
  )} - ${format(parseISO(originalBookingDetails.due_end), 'HH:mm')}`;

  const originalSiteEntrance = originalBookingDetails.site_entrance;
  const originalFirstDestination = originalBookingDetails.first_destination;

  const datesTextFieldValue = () => {
    return format(
      new Date(originalBookingDetails.due_start),
      'EEE do MMMM yyyy'
    );
  };

  const [formValidated, setIsFormValid] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [bookingParameters, setBookingParameters] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  const successfulResponseCallback = (response) => {
    const updatedBookingData = response;
    setFormSubmitting(false);
    dispatch({
      type: 'SET_MODAL_DATA',
      payload: updatedBookingData,
    });
    history.push('/operationoverview');
  };

  const catchErrorCallback = () => {
    setFormSubmitting(false);
  };

  const [formLoading] = useConditionalCallApi(
    callPatchBooking,
    [bookingId, bookingParameters],
    formSubmitting,
    successfulResponseCallback,
    catchErrorCallback
  );

  function onSubmit() {
    setBookingParameters({
      site_entrance: bookingFormState.siteEntrance,
      first_destination: bookingFormState.firstDestination,
    });

    setFormSubmitting(true);
  }

  useEffect(() => {
    setBookingFormState({
      siteEntrance: originalSiteEntrance,
      firstDestination: originalFirstDestination,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsFormValid(isBookingFormValid('edit__single', bookingFormState));
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <Typography variant="h5" component="h4" gutterBottom>
          Please enter the Booking Details
        </Typography>
        <div
          style={{
            margin: '10px 0',
            fontSize: '14px',
            fontStyle: 'italic',
            color: '#FCBC00',
          }}
        >
          <div style={{ marginBottom: '10px' }}>
            Changes must be submitted 24 hours before the deliveries due start.
          </div>
          <div>
            Changes submitted at {format(dueStart, 'HH:mm')} on{' '}
            {format(dueStartMinus24Hours, 'dd/MM/yyyy')} or after will not be
            successful.
          </div>
        </div>

        <div className="recurrent-booking-date-selection">
          <TextField
            multiline
            autoFocus
            spellCheck={false}
            name="dates"
            label="Date"
            variant="outlined"
            disabled
            value={datesTextFieldValue()}
          />

          <EventIcon className="event-icon" />
        </div>
        <TextField
          multiline
          name="material"
          label="Material"
          variant="outlined"
          value={material}
          disabled
        />
        <TextField
          multiline
          name="haulier"
          label="Haulier"
          variant="outlined"
          disabled
          value={haulierName}
        />

        <AvailableSiteEntrances
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
          originalSiteEntrance={originalSiteEntrance}
        />

        <AvailableFirstDestinations
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
          originalSiteEntrance={originalSiteEntrance}
          originalFirstDestination={originalFirstDestination}
        />

        {!originalBookingDetails.recurrent && (
          <TextField
            multiline
            name="booking-slot"
            label="Booking Slot"
            variant="outlined"
            value={bookingSlot}
            disabled
          />
        )}

        {!formLoading ? (
          <Button
            disabled={!formValidated}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save Changes
          </Button>
        ) : (
          <LoadingCircle />
        )}
      </div>
    </form>
  );
};
export default EditBookingDetailsForm;
