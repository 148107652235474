import { useSelector } from 'react-redux';

const useGetUserType = () => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { currentUserType } = currentUserStore;

  return currentUserType;
};

export default useGetUserType;
