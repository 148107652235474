import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

const ViewAnimationContainer = ({ children }) => {
  const [animateView, setAnimateView] = useState(false);

  useEffect(() => {
    setAnimateView(true);
  }, []);

  return (
    <CSSTransition
      in={animateView}
      classNames="view-fade"
      timeout={200}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
};

export default ViewAnimationContainer;
