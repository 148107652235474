import React, { useState } from 'react';
import ConfirmationButtons from './ConfirmationButtons';

const OrganisationEmailConfirmationField = ({ data }) => {
  const [editState, setEditState] = useState(false);

  return (
    <div className="add-organisation-view__confirmation-fieldset">
      <span>Organisation Email:</span>
      <input
        data-input-organisationemail
        type="email"
        disabled={!editState}
        key={data}
        placeholder="organisation_email"
        defaultValue={data}
        className="add-organisation-view__confirmation-input"
      />

      <ConfirmationButtons editState={editState} setEditState={setEditState} />
    </div>
  );
};
export default OrganisationEmailConfirmationField;
