import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getJWTTokenDecoded,
  userTypeIsAllowed,
  setCookie,
} from '../utils/functions';

const useHandleUserSignInResponse = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (user) => {
    const idToken = user.signInUserSession.idToken.jwtToken;
    const decodedJWTToken = getJWTTokenDecoded(idToken);

    const currentUserType = decodedJWTToken['cognito:groups']
      ? decodedJWTToken['cognito:groups'][0]
      : null;
    const userId = decodedJWTToken.sub;

    if (userTypeIsAllowed(currentUserType)) {
      setCookie(`idToken:${userId}`, idToken);
      dispatch({
        type: 'SET_CURRENT_USER_LOGGED_IN',
      });
      dispatch({
        type: 'SET_CURRENT_USER_DETAILS',
        payload: {
          username: decodedJWTToken.email,
          userId,
        },
      });
      dispatch({
        type: 'SET_CURRENT_USER_GROUP',
        payload: currentUserType,
      });
      dispatch({
        type: 'SET_TOKEN_EXPIRY',
        payload: decodedJWTToken.exp,
      });
      if (currentUserType === 'Haulier-Admin') {
        dispatch({
          type: 'SET_CURRENT_USER_IS_HAULIER',
        });
      }

      history.push('/dashboard');
    } else {
      history.push('/error');
    }
  };
};

export default useHandleUserSignInResponse;
