import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Close from '../../../../images/Components/Close';
import BookingModalHeader from './BookingModalHeader';
import BookingModalDetails from './BookingModalDetails';

const BookingModal = ({ cardModalData }) => {
  const dispatch = useDispatch();
  const modalWrapper = useRef(null);
  const isPlanned = cardModalData.type === 'PLANNED';
  const isRecurrent = cardModalData.recurrent;

  const handleModalClose = (ev) => {
    ev.stopPropagation();
    if (ev.currentTarget !== modalWrapper.current) {
      dispatch({
        type: 'SET_MODAL_CLOSED',
      });
      dispatch({
        type: 'SET_MODAL_DATA',
        payload: {},
      });
    }
  };

  const classNameModifier = () => {
    if (isPlanned) {
      if (isRecurrent) {
        return ' --recurrent';
      }

      return ' --planned';
    }

    return '';
  };

  useEffect(() => {
    document.querySelector('.overlay').addEventListener(
      'click',
      (ev) => {
        handleModalClose(ev);
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    modalWrapper.current.focus();
  }, []);

  useEffect(() => {
    // add escape key listener
    document.addEventListener(
      'keydown',
      (ev) => {
        if (ev.keyCode === 27) handleModalClose(ev);
      },
      false
    );
    return () => {
      document.removeEventListener('keydown', () => {}, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`booking-modal${classNameModifier()}`}>
      <div
        role="dialog"
        aria-modal="true"
        className="booking-modal__inner"
        ref={modalWrapper}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
      >
        <button
          type="button"
          className="button booking-modal__close"
          onClick={(ev) => handleModalClose(ev)}
        >
          <Close
            className={`booking-modal__close-icon ${classNameModifier()}`}
          />
        </button>

        <BookingModalHeader cardModalData={cardModalData} />

        <BookingModalDetails cardModalData={cardModalData} />
      </div>

      <div className="booking-modal overlay" />
    </div>
  );
};

export default BookingModal;
