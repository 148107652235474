import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const ButtonDoneAssignUsers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <button
      type="button"
      onClick={() => {
        history.push('/sites');
        dispatch({
          type: 'SET_SUB_ROUTE_PROGRESS',
          payload: 0,
        });
        dispatch({
          type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
          payload: null,
        });
      }}
      className="button main-header__button-confirm"
    >
      Done
    </button>
  );
};

export default ButtonDoneAssignUsers;
