import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UnauthorisedPageView = () => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { isLoggedIn } = currentUserStore;
  const history = useHistory();

  return (
    <main>
      <form className="form">
        <div className="form-inner">
          <Typography variant="h5" component="h4" gutterBottom>
            Sorry, you are not authorised to access this page.
          </Typography>

          <button
            type="button"
            className="button --full-width"
            onClick={() => {
              history.push(isLoggedIn ? '/dashboard' : '/');
            }}
          >
            Return to {isLoggedIn ? 'Dashboard' : 'Home Page'}
          </button>
        </div>
      </form>
    </main>
  );
};
export default UnauthorisedPageView;
