import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import userTypeDashboardConfigs from './dashboard.config';

const DashboardView = () => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { currentUserType } = currentUserStore;
  const dispatch = useDispatch();
  const currentUserTypeDashboardConfig = userTypeDashboardConfigs.filter(
    (dashboardConfig) => {
      return dashboardConfig.userType === currentUserType;
    }
  )[0];

  useEffect(() => {
    // reset store values if user lands back on dashboard.
    dispatch({
      type: 'RESET_SELECTED_SITE_DETAILS',
    });
    dispatch({
      type: 'SET_HEADER_RIGHT_BUTTON_COMPONENT',
      payload: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="dashboard-view">
      <div className="dashboard-view__dashboard-container">
        {currentUserTypeDashboardConfig.availableButtons.map(
          (currentUserDashboardButton) => {
            return (
              <Link
                to={currentUserDashboardButton.linkHref}
                key={currentUserDashboardButton.title}
                className="dashboard-view__dashboard-box-container"
              >
                <div className="dashboard-view__dashboard-box">
                  <img
                    src={currentUserDashboardButton.img}
                    alt={currentUserDashboardButton.title}
                  />
                </div>
                <button type="button" className="button dashboard-button">
                  {currentUserDashboardButton.title}
                </button>
              </Link>
            );
          }
        )}
      </div>
    </main>
  );
};

export default DashboardView;
