import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import LoadingCircle from '../../../shared/LoadingCircle';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';
import inputRefConfig from '../../../shared/inputRef.config';

function OrganisationEmail() {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [invoiceSameAsContactChecked, setInvoiceSameAsContactChecked] =
    useState(true);

  const { register, handleSubmit, errors } = useForm({
    mode: 'onTouched',
  });

  const dispatch = useDispatch();

  const [organisationEmail, setOrganisationEmail] = useState('');
  const [organisationInvoiceEmail, setOrganisationInvoiceEmail] = useState('');

  const currentUserStore = useSelector((state) => state.currentUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const organisationDetails = currentUserStore.organisation;

  const onSubmit = (data) => {
    const { subRouteProgress } = viewStore;

    if (formValidated) {
      const organisationInvoiceEmailValue = invoiceSameAsContactChecked
        ? data.organisationEmail
        : data.organisationInvoiceEmail;

      setFormSubmitting(true);
      dispatch({
        type: 'SET_CURRENT_USER_ORGANISATION_DETAILS',
        payload: {
          ...organisationDetails,
          contact_email: data.organisationEmail,
          invoice_email: organisationInvoiceEmailValue,
        },
      });
      dispatch({
        type: 'SET_SUB_ROUTE_PROGRESS',
        payload: subRouteProgress + 1,
      });
    }
  };
  const toggleCheckboxState = () => {
    const currentState = invoiceSameAsContactChecked;
    setInvoiceSameAsContactChecked(!currentState);
    // clear organisationInvoiceEmail errors on toggle
    errors.organisationInvoiceEmail = undefined;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const errorsLength = Object.keys(errors).length;
    if (errorsLength === 0 && organisationEmail.length > 1) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h6" component="h4" gutterBottom>
            Enter the contact and invoice email addresses for your Organisation
          </Typography>
          <TextField
            autoFocus
            value={organisationEmail.toLowerCase()}
            inputRef={register(inputRefConfig.emailInput)}
            name="organisationEmail"
            label="Contact Email"
            type="text"
            variant="outlined"
            onChange={(e) => setOrganisationEmail(e.target.value)}
          />

          <FormErrorMessage errors={errors.organisationEmail} />

          <span>Same as Invoice email address</span>
          <Checkbox
            color="default"
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            checked={invoiceSameAsContactChecked}
            onChange={toggleCheckboxState}
          />
          <>
            {!invoiceSameAsContactChecked && (
              <TextField
                value={organisationInvoiceEmail.toLowerCase()}
                inputRef={register(inputRefConfig.emailInput)}
                name="organisationInvoiceEmail"
                label="Invoice Email"
                type="text"
                variant="outlined"
                onChange={(e) => setOrganisationInvoiceEmail(e.target.value)}
              />
            )}

            <FormErrorMessage errors={errors.organisationInvoiceEmail} />
          </>
          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
}
export default OrganisationEmail;
