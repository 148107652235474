import React, { useState } from 'react';
import ConfirmationButtons from './ConfirmationButtons';

const InvoiceEmailConfirmationField = ({ data }) => {
  const [editState, setEditState] = useState(false);

  return (
    <div className="add-organisation-view__confirmation-fieldset">
      <span>Invoice Email:</span>
      <input
        data-input-invoiceemail
        type="email"
        disabled={!editState}
        key={data}
        placeholder="organisation_invoice_email"
        defaultValue={data}
        className="add-organisation-view__confirmation-input"
      />

      <ConfirmationButtons editState={editState} setEditState={setEditState} />
    </div>
  );
};
export default InvoiceEmailConfirmationField;
