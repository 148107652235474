import React from 'react';
import { callGetBookingActivityLog } from '../../../../../api/kerb-construction';
import LoadingCircle from '../../../../../shared/LoadingCircle';
import useCallApi from '../../../../../hooks/useCallApi';

const BookingActivityLog = ({ bookingId }) => {
  const [loading, response] = useCallApi(callGetBookingActivityLog, [
    bookingId,
  ]);

  return (
    <div className="booking-modal__dropdown-content">
      {!loading ? (
        response.map((activityLog) => {
          return (
            <span
              key={activityLog.date_created}
              className="activity-log-content"
            >
              {`${activityLog.event_detail}`}
            </span>
          );
        })
      ) : (
        <LoadingCircle />
      )}
    </div>
  );
};

export default BookingActivityLog;
