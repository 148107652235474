import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import LoadingCircle from '../../../shared/LoadingCircle';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';
import CountryPhoneNumberInput from '../../../shared/CountryPhoneNumber/CountryPhoneNumberInput';
import inputRefConfig from '../../../shared/inputRef.config';

const OrganisationNumber = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onTouched',
  });

  const dispatch = useDispatch();
  const number = useRef({});
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const organisationDetails = currentUserStore.organisation;

  number.current = watch('number', '');

  const onSubmit = (data) => {
    const { subRouteProgress } = viewStore;
    const combinedNumber = `${data.countryCode}${data.number}`;

    if (formValidated) {
      setFormSubmitting(true);
      dispatch({
        type: 'SET_CURRENT_USER_ORGANISATION_DETAILS',
        payload: {
          ...organisationDetails,
          phone: combinedNumber,
        },
      });
      dispatch({
        type: 'SET_SUB_ROUTE_PROGRESS',
        payload: subRouteProgress + 1,
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const errorsLength = Object.keys(errors).length;

    if (errorsLength === 0 && number.current.length > 2) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h6" component="h4" gutterBottom>
            What is the phone number for your Business?
          </Typography>
          <CountryPhoneNumberInput
            autoFocus
            inputRef={register({ ...inputRefConfig.numberInput })}
          />
          <FormErrorMessage errors={errors.number} />

          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
};
export default OrganisationNumber;
