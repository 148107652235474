import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CompletedBookingRow from './CompletedBookingRow';

const CompletedBookingsTable = ({ bookings }) => {
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);

  return (
    <>
      <Typography
        variant="h6"
        component="h6"
        gutterBottom
        className="u-highlight-title booking-schedule-view__heading"
      >
        History for {selectedSiteStore.name}.
      </Typography>

      <TableContainer className="mui-table">
        <Table
          className="--completed-bookings-table"
          aria-label="Driver Assignment table"
        >
          <TableHead className="booking-schedule-view__completed-bookings-table-head">
            <TableRow>
              <TableCell className="booking-schedule-view__table-heading">
                Type
              </TableCell>
              <TableCell className="booking-schedule-view__table-heading">
                Material
              </TableCell>
              <TableCell className="booking-schedule-view__table-heading">
                Company
              </TableCell>
              <TableCell className="booking-schedule-view__table-heading">
                Vehicle (VRM)
              </TableCell>
              <TableCell className="booking-schedule-view__table-heading">
                Completed At
              </TableCell>
              <TableCell className="booking-schedule-view__table-heading">
                Rejected At
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="booking-schedule-view__table-body">
            {bookings.map((booking) => {
              return <CompletedBookingRow key={booking.id} booking={booking} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default CompletedBookingsTable;
