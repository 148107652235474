import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import isToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';
import isSameDay from 'date-fns/isSameDay';

const BookingDateSelector = () => {
  const bookingModalStore = useSelector((state) => state.bookingModalStore);
  const { cardModalData, bookingModalOpen } = bookingModalStore;

  const todaysDate = new Date();
  const defaultDate = bookingModalOpen
    ? parseISO(cardModalData.due_start)
    : todaysDate;

  const [startDate, setStartDate] = useState(defaultDate);
  const [todayIsSelected, setTodayIsSelected] = useState(true);

  const dispatch = useDispatch();

  const handleDateChange = (ev) => {
    const selectedDateString = ev;
    setStartDate(selectedDateString);
    setTodayIsSelected(isToday(selectedDateString));

    dispatch({
      type: 'SET_SELECTED_SCHEDULE_DATE_STRING',
      payload: selectedDateString,
    });
  };
  const CustomDatePicker = React.forwardRef((props, ref) => {
    const { value, onClick } = props;
    return (
      <button
        ref={ref}
        type="button"
        className="button date-button"
        onClick={onClick}
      >
        {value}
      </button>
    );
  });

  useEffect(() => {
    // set selectedDateString on component load
    // If modal open, set the date to its due_start
    // Otherwise, today's date

    if (bookingModalOpen) {
      const dueStart = cardModalData.due_start;
      const parsedBookingModalDate = parseISO(dueStart);

      if (!isSameDay(todaysDate, parsedBookingModalDate)) {
        dispatch({
          type: 'SET_SELECTED_SCHEDULE_DATE_STRING',
          payload: parsedBookingModalDate,
        });
      }
    } else {
      dispatch({
        type: 'SET_SELECTED_SCHEDULE_DATE_STRING',
        payload: todaysDate,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        todayButton="View Today"
        onChange={handleDateChange}
        selected={startDate}
        minDate={new Date()}
        placeholderText="Select a date from today"
        customInput={<CustomDatePicker ref={null} />}
      />

      {!todayIsSelected && (
        <button
          type="button"
          onClick={() => handleDateChange(new Date())}
          className="button view-today-button"
        >
          View Today
        </button>
      )}
    </>
  );
};

export default BookingDateSelector;
