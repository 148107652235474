import React from 'react';
import { useSelector } from 'react-redux';
import BookingDetailsForm from '../../shared/BookingForms/BookingDetailsForm';

function EditBookingView() {
  const editBookingStore = useSelector((state) => state.editBookingStore);
  const { editBookingDetails } = editBookingStore;
  return (
    <main className="edit-booking-view">
      <BookingDetailsForm originalBookingDetails={editBookingDetails} />
    </main>
  );
}

export default EditBookingView;
