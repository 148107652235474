import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import NotificationPanel from '../NotificationPanel/NotificationPanel';
import NotificationButton from '../NotificationPanel/NotificationButton';
import HomeImage from '../../images/home.svg';
import { clearLocalStorage } from '../../utils/functions';
import useIsMobileDevice from '../../hooks/useIsMobileDevice';
import useBackButtonHandler from '../../hooks/useBackButtonHandler';
import HeaderMobile from './HeaderMobile';
import HelpButton from './HelpButton';
import useGetUserType from '../../hooks/useGetUserType';
import { callSignOut } from '../../api/auth';

const HeaderRightButton = ({ headerRightButtonComponent }) => {
  const redirectToPreviousPage = useBackButtonHandler();

  return headerRightButtonComponent ? (
    React.createElement(headerRightButtonComponent)
  ) : (
    <button
      type="button"
      onClick={redirectToPreviousPage}
      className="button main-header__button-logout"
    >
      Back
    </button>
  );
};

const HeaderDesktop = ({
  pageTitle,
  darkHeader,
  plannedBookingButton,
  hasLogoutButton,
}) => {
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const currentUserType = useGetUserType();
  const viewStore = useSelector((state) => state.viewStore);
  const organisationName = currentUserStore.organisation.name;
  const { headerRightButtonComponent, notificationPanelOpen } = viewStore;
  const { isHaulier } = currentUserStore;
  const siteIsSelected = !!selectedSiteStore.id;
  const history = useHistory();
  const dispatch = useDispatch();

  function handleLogout() {
    clearLocalStorage();
    callSignOut().then(() => {
      history.push(isHaulier ? '/haulier' : '/');
      dispatch({
        type: 'RESET_STORE',
      });
    });
  }

  return (
    <header className={`main-header ${darkHeader ? 'dark-header' : ''}`}>
      <div className="main-header__header-left-side">
        {!window.location.href.endsWith('/#/dashboard') && (
          <Link to="/dashboard" className="button-with-img">
            <img src={HomeImage} alt="home" />
          </Link>
        )}

        <h1 className="header-title">
          <b>{pageTitle}</b> - {organisationName}
        </h1>
      </div>

      <div className="main-header__header-right-side">
        {plannedBookingButton &&
          siteIsSelected &&
          currentUserType !== 'Site-Organisation-Admin' && (
            <Link
              className="button main-header__header-button"
              to="/addbooking"
              disabled={!siteIsSelected}
            >
              Add a Booking
            </Link>
          )}
        <HelpButton />
        {isHaulier && <NotificationButton />}

        {hasLogoutButton ? (
          <button
            type="button"
            onClick={handleLogout}
            className="button main-header__button-logout"
          >
            Logout
          </button>
        ) : (
          <HeaderRightButton
            headerRightButtonComponent={headerRightButtonComponent}
          />
        )}
      </div>
      {notificationPanelOpen && <NotificationPanel />}
    </header>
  );
};
const Header = ({
  pageTitle,
  darkHeader,
  plannedBookingButton,
  hasLogoutButton,
}) => {
  const isMobileDevice = useIsMobileDevice();

  return (
    <>
      {isMobileDevice ? (
        <HeaderMobile pageTitle={pageTitle} />
      ) : (
        <HeaderDesktop
          pageTitle={pageTitle}
          darkHeader={darkHeader}
          plannedBookingButton={plannedBookingButton}
          hasLogoutButton={hasLogoutButton}
        />
      )}
    </>
  );
};

export default Header;
