import React, { useState, useEffect } from 'react';
import { getPasswordValidationState } from '../utils/functions';

const passwordValidationInitialState = {
  passwordLength: false,
  passwordUppercase: false,
  passwordLowercase: false,
  passwordNumber: false,
  passwordSymbol: false,
};
const PasswordRequirementsList = ({ typedPassword }) => {
  const [passwordState, setPasswordState] = useState(
    passwordValidationInitialState
  );

  const passwordFieldNames = {
    passwordLength: 'At least 8 Characters',
    passwordUppercase: 'At least 1 upper case letter',
    passwordLowercase: 'At least 1 lower case letter',
    passwordNumber: 'At least 1 number',
    passwordSymbol: 'At least 1 symbol',
  };

  useEffect(() => {
    setPasswordState(getPasswordValidationState(typedPassword));
  }, [typedPassword]);

  return (
    <div className="password-requirements-container">
      <h4>Password Requirements:</h4>
      <ul>
        {Object.entries(passwordState).map(([key, value]) => {
          return (
            <li
              key={key}
              name={key}
              className={value ? '--valid' : '--invalid'}
            >
              {passwordFieldNames[key]}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PasswordRequirementsList;
