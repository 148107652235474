import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const TableHeadings = ({ deletionType }) => {
  switch (deletionType) {
    case 'haulier-disconnect':
      return (
        <TableRow>
          <TableCell className="confirm-delete__table-heading">
            Haulier
          </TableCell>
          <TableCell className="confirm-delete__table-heading">
            Address
          </TableCell>
        </TableRow>
      );
    case 'delete-booking':
      return (
        <TableRow>
          <TableCell className="confirm-delete__table-heading">
            Due Date
          </TableCell>
          <TableCell className="confirm-delete__table-heading">
            Material
          </TableCell>
          <TableCell className="confirm-delete__table-heading">
            Haulier
          </TableCell>
        </TableRow>
      );
    default:
      return '';
  }
};

export default TableHeadings;
