import React from 'react';

const FormFieldAnimationContainer = ({ children }) => {
  return children.map((child, index) => {
    const key = `${index}-keyed`;

    return <div key={key}>{React.cloneElement(child, { ...child.props })}</div>;
  });
};

export default FormFieldAnimationContainer;
