import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ResetPasswordForm from './forms/ResetPasswordForm';
import { callForgotPassword } from '../../api/auth';
import useHandleErrorResponse from '../../hooks/useHandleErrorResponse';
import { configureAmplify } from '../../utils/functions';

const ResetPasswordView = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isHaulier } = currentUserStore;
  const handleErrorResponse = useHandleErrorResponse();

  const onSubmit = useCallback((data) => {
    const username = data.username.toLowerCase();
    const newPassword = data.password;

    setFormSubmitting(true);
    configureAmplify(isHaulier);
    callForgotPassword(username)
      .then(() => {
        dispatch({
          type: 'SET_TEMP_PASSWORD',
          payload: newPassword,
        });
        dispatch({
          type: 'SET_CURRENT_USER_DETAILS',
          payload: { username },
        });
        dispatch({
          type: 'SET_USER_RESETTING_PASSWORD',
          payload: true,
        });
        history.push('/confirm');
      })
      .catch((err) => {
        handleErrorResponse(err);
        setFormSubmitting(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main>
      <ResetPasswordForm onSubmit={onSubmit} formSubmitting={formSubmitting} />
    </main>
  );
};
export default ResetPasswordView;
