import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NotificationBellImage from './NotificationBellImage';

const NotificationButton = () => {
  const viewStore = useSelector((state) => state.viewStore);
  const { hasNotification, notificationPanelOpen } = viewStore;
  const dispatch = useDispatch();
  const notificationButtonClassName = `button main-header__header-button main-header__button-notification ${
    notificationPanelOpen ? '--active' : ''
  }`;

  function handleNotificationClick() {
    dispatch({
      type: 'SET_NOTIFICATION_PANEL_OPEN',
      payload: !notificationPanelOpen,
    });
    dispatch({
      type: 'CLEAR_HAS_NOTIFICATION',
    });
  }
  return (
    <button
      type="button"
      className={notificationButtonClassName}
      onClick={handleNotificationClick}
    >
      <NotificationBellImage />
      {hasNotification && <span className="notification-dot" />}
    </button>
  );
};
export default NotificationButton;
