import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SubRouteHandler from '../../router/SubRouteHandler';
import ConnectUserTypeForm from './forms/ConnectUserTypeForm';
import ConnectUserTableView from './components/ConnectUserTableView';

const ConnectUsersView = () => {
  const [selectedUserType, setSelectedUserType] = useState(null);
  const dispatch = useDispatch();

  const addOrganisationProgressRoutes = [
    { component: ConnectUserTypeForm, props: { setSelectedUserType } },
    {
      component: ConnectUserTableView,
      props: { selectedUserType },
    },
  ];

  useEffect(() => {
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="connect-users-view">
      <SubRouteHandler subroutes={addOrganisationProgressRoutes} />
    </main>
  );
};

export default ConnectUsersView;
