import React from 'react';
import TextField from '@material-ui/core/TextField';

const SharedInputField = ({ ...props }) => {
  const { name, label, type, variant, inputRef, defaultValue, autoFocus } =
    props;

  return (
    <TextField
      autoFocus={autoFocus}
      inputRef={inputRef}
      name={name}
      label={label}
      type={type}
      variant={variant}
      defaultValue={defaultValue}
    />
  );
};

export default SharedInputField;
