import React from 'react';
import { format, parseISO } from 'date-fns';
import HightPriorityImage from '../../../images/High_Priority.svg';

const RejectedVehicleNotification = ({ booking }) => {
  const bookingSiteName = booking.site_name;
  const bookingMaterial = booking.material;
  const bookingVRM = booking.vrm;
  const bookingTimestamp = booking.date_modified;
  const formattedTimestamp = format(parseISO(bookingTimestamp), 'HH:mm');

  return (
    <div className="notification-panel__notification-container">
      <div className="notification-panel__high-priority-notification">
        <img src={HightPriorityImage} alt="High Priority Notification" />
      </div>
      <div className="notification-panel__notification-content">
        {`${bookingVRM} carrying ${bookingMaterial} to ${bookingSiteName} has been REJECTED by a site
        marshal at ${formattedTimestamp}.`}
      </div>
    </div>
  );
};

export default RejectedVehicleNotification;
