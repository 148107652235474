import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HelpForm from './HelpForm';
import HelpConfirmation from './HelpConfirmation';
import SubRouteHandler from '../../router/SubRouteHandler';

function HelpView() {
  const dispatch = useDispatch();
  const subroutes = [{ component: HelpForm }, { component: HelpConfirmation }];

  useEffect(() => {
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="help-view">
      <SubRouteHandler subroutes={subroutes} />
    </main>
  );
}

export default HelpView;
