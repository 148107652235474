import { useState } from 'react';
import { useSelector } from 'react-redux';
import { callCurrentSession } from '../api/auth';
import {
  configureAmplify,
  setCookie,
  getCurrentUserIdFromStore,
} from '../utils/functions';
import useHandleErrorResponse from './useHandleErrorResponse';

// a hook that exposes the refreshAmplifySession() function.
function useRefreshAmplifySession() {
  const [sessionRefreshing, setSessionRefreshing] = useState(false);
  const handleError = useHandleErrorResponse();
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { isHaulier } = currentUserStore;

  function refreshAmplifySession() {
    setSessionRefreshing(true);
    // configure amplify to ensure Amplify always has a session.
    configureAmplify(isHaulier);
    callCurrentSession()
      .then((res) => {
        setSessionRefreshing(false);
        setCookie(
          `idToken:${getCurrentUserIdFromStore()}`,
          res.idToken.jwtToken
        );

        // eslint-disable-next-line no-console
        console.log('%c[Amplify Session]: Token Refreshed', 'color: lightblue');
      })
      .catch((err) => {
        setSessionRefreshing(false);
        handleError(err);
      });
  }

  return [refreshAmplifySession, sessionRefreshing];
}

export default useRefreshAmplifySession;
