import React from 'react';
import TextField from '@material-ui/core/TextField';

const MaterialField = ({ bookingFormState, setBookingFormState }) => {
  const material = bookingFormState.material || '';

  const setMaterial = (ev) => {
    const newMaterialValue = ev.target.value;

    setBookingFormState({
      ...bookingFormState,
      material: newMaterialValue,
    });
  };

  return (
    <TextField
      name="material"
      label="Material*"
      variant="outlined"
      value={material}
      onChange={setMaterial}
    />
  );
};

export default MaterialField;
