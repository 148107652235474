import { useState, useEffect } from 'react';
import useHandleErrorResponse from './useHandleErrorResponse';
/**
 * A Custom Hook that calls an api from a component and exposes an array containing 'loading and response'
 * @param {Function} apiCall - apiCall fetch method.
 * @param {Array} argsArray  - args to be called with apiCall. Defaults to empty array.
 * @param {Object} options - object with the properties: dependencies, successCallback, caughtErrorCallback and condition.
 * @return {Array} [Loading: boolean, Response: Any]
 */
const useCallApi = (apiCall, argsArray = [], options = {}) => {
  const {
    dependencies = [],
    successCallback = () => {},
    caughtErrorCallBack = () => {},
    condition = true,
  } = options;

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(condition);
  const handleErrorResponse = useHandleErrorResponse();

  useEffect(() => {
    if (condition) {
      setLoading(true);
      apiCall(...argsArray)
        .then((res) => {
          if (!res.ok) {
            throw res.json();
          }
          if (res.status === 204) {
            return {};
          }
          return res.json();
        })
        .then((responseData) => {
          setResponse(responseData);
          setLoading(false);
          successCallback();
        })
        .catch((err) => {
          handleErrorResponse(err);
          setLoading(false);
          caughtErrorCallBack();
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, condition]);

  return [loading, response];
};

export default useCallApi;
