import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { add, parseISO, isAfter } from 'date-fns';
import EditBookingButton from './Buttons/EditBookingButton';
import DeleteBookingButton from './Buttons/DeleteBookingButton';
import Edit from '../../../../../images/Components/Edit';

const DetailsButtonContainer = ({
  cardModalData,
  selectedForEdit,
  setSelectedForEdit,
}) => {
  const nowPlusTwentyFourHours = add(new Date(), {
    hours: 24,
  });

  const isBookingTwentyFourHoursAway = isAfter(
    parseISO(cardModalData.due_start),
    nowPlusTwentyFourHours
  );

  return selectedForEdit ? (
    <ClickAwayListener
      onClickAway={() => {
        setSelectedForEdit(false);
      }}
    >
      <div className="booking-modal__edit-booking-button-container">
        {isBookingTwentyFourHoursAway && (
          <EditBookingButton cardModalData={cardModalData} />
        )}

        <DeleteBookingButton cardModalData={cardModalData} />
      </div>
    </ClickAwayListener>
  ) : (
    <div className="booking-modal__edit-booking-button-container">
      <button
        className="button booking-modal__edit-booking-button --edit"
        type="button"
        onClick={() => {
          setSelectedForEdit(true);
        }}
      >
        <Edit className="booking-modal__edit-booking-button-icon" />
        Edit
      </button>
    </div>
  );
};

export default DetailsButtonContainer;
