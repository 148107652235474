import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { format, add } from 'date-fns';
import LoadingCircle from '../../LoadingCircle';
import MaterialField from '../components/MaterialField';
import SingleDatePicker from '../components/SingleDatePicker';
import AvailableHauliersDropDown from '../components/AvailableHauliersDropDown';
import AvailableSiteEntrances from '../components/AvailableSiteEntranceFieldWithButtons';
import AvailableFirstDestinations from '../components/AvailableFirstDestinationWithButtons';
import BookingSlotsDropDown from '../components/AvailableBookingSlotDropdown';
import isBookingFormValid from '../functions/bookingFormFunctions';
import useConditionalCallApi from '../../../hooks/useConditionalCallApi';
import { callCreateBooking } from '../../../api/kerb-construction';

const NewSingleBookingDetailsForm = ({
  bookingFormState,
  setBookingFormState,
}) => {
  const dateNow = new Date();
  const dateOneDayFromNow = add(dateNow, {
    days: 1,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  // Toggle which is used to trigger the api
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [bookingParameters, setBookingParameters] = useState({});

  const selectedSiteStore = useSelector((state) => state.selectedSiteStore);
  const siteID = selectedSiteStore.id;

  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();

  const successfulResponseCallback = () => {
    history.push('/operationoverview');
    dispatch({
      type: 'SET_SUB_ROUTE_PROGRESS',
      payload: 0,
    });
  };

  const [formLoading] = useConditionalCallApi(
    callCreateBooking,
    [bookingParameters],
    formSubmitting,
    successfulResponseCallback
  );

  const submitFormData = () => {
    const {
      selectedDate,
      bookingSlot,
      firstDestination,
      siteEntrance,
      material,
      selectedHaulier,
    } = bookingFormState;
    const formattedBookingDate = format(selectedDate, 'yyyy-MM-dd');

    const formattedDueStartDate = `${formattedBookingDate} ${bookingSlot.start}`;
    const formattedDueEndDate = `${formattedBookingDate} ${bookingSlot.end}`;

    setBookingParameters({
      due_start: formattedDueStartDate,
      due_end: formattedDueEndDate,
      material,
      haulier_id: selectedHaulier.haulier_id,
      site_id: siteID,
      first_destination: firstDestination,
      site_entrance: siteEntrance,
      type: 'PLANNED',
    });

    // This change triggers the API call
    setFormSubmitting(true);
  };

  useEffect(() => {
    setBookingFormState({
      ...bookingFormState,
      selectedDate: dateOneDayFromNow,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsFormValid(isBookingFormValid('new__single', bookingFormState));
  });

  return (
    <form
      className="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(submitFormData)}
    >
      <div className="form__form-inner">
        <Typography variant="h5" component="h4" gutterBottom>
          Please enter the Booking Details
        </Typography>

        <SingleDatePicker
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
        />

        <MaterialField
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
        />

        <AvailableHauliersDropDown
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
        />

        <AvailableSiteEntrances
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
        />

        {bookingFormState.siteEntrance && (
          <AvailableFirstDestinations
            bookingFormState={bookingFormState}
            setBookingFormState={setBookingFormState}
          />
        )}

        <BookingSlotsDropDown
          bookingFormState={bookingFormState}
          setBookingFormState={setBookingFormState}
        />
        {!formLoading ? (
          <Button
            disabled={!isFormValid}
            type="submit"
            variant="contained"
            color="primary"
          >
            Add Booking
          </Button>
        ) : (
          <LoadingCircle />
        )}
      </div>
    </form>
  );
};
export default NewSingleBookingDetailsForm;
