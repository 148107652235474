import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import LoadingCircle from '../../../shared/LoadingCircle';
import PasswordRequirementsList from '../../../shared/PasswordRequirementsList';
import inputRefConfig from '../../../shared/inputRef.config';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';

const ResetPasswordForm = ({ onSubmit, formSubmitting }) => {
  const [formValidated, setFormValidated] = useState(false);
  const [typedPassword, setTypedPassword] = useState('');
  const { register, handleSubmit, watch, errors } = useForm({
    mode: 'onTouched',
  });

  const password = useRef({});
  const username = useRef({});
  password.current = watch('password', '');
  username.current = watch('username', '');

  function handlePasswordChange() {
    setTypedPassword(password.current);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // use password.current.length to determine that the last field has been filled.
    const errorsLength = Object.keys(errors).length;

    if (password.current.length > 8 && errorsLength === 0) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h5" component="h4" gutterBottom>
            Reset Your Password
          </Typography>
          <TextField
            autoFocus
            inputRef={register(inputRefConfig.emailInput)}
            name="username"
            label="name@example.com"
            type="email"
            variant="outlined"
            value={username.current}
          />
          <FormErrorMessage errors={errors.username} />

          <PasswordRequirementsList typedPassword={typedPassword} />
          <TextField
            inputRef={register(inputRefConfig.passwordInput)}
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            onChange={() => handlePasswordChange()}
          />
          <FormErrorMessage errors={errors.password} />
          <Link className="u-helper-text" to="/login">
            Back to Login Form
          </Link>
          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
};
export default ResetPasswordForm;
