import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoginForm from './forms/LoginForm';
import { callSignIn } from '../../api/auth';
import { configureAmplify, getJWTTokenDecoded } from '../../utils/functions';
import useHandleUserSignInResponse from '../../hooks/useHandleUserSignInResponse';
import useHandleErrorResponse from '../../hooks/useHandleErrorResponse';

const LoginView = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleErrorResponse = useHandleErrorResponse();
  const handleUserSigninResponse = useHandleUserSignInResponse();

  const onSubmit = (data) => {
    const formDataUsername = data.username.toLowerCase();
    const formDataPassword = data.password;
    const { isHaulier } = currentUserStore;

    setFormSubmitting(true);
    configureAmplify(isHaulier);
    dispatch({
      type: 'CLEAR_ALERT',
    });
    callSignIn(formDataUsername, formDataPassword)
      .then((user) => {
        if (user.signInUserSession) {
          const idToken = user.signInUserSession.idToken.jwtToken;
          const decodedJWTToken = getJWTTokenDecoded(idToken);

          const currentUserType = decodedJWTToken['cognito:groups']
            ? decodedJWTToken['cognito:groups'][0]
            : null;

          if (currentUserType === 'Site-Traffic-Marshal') {
            // eslint-disable-next-line no-throw-literal
            throw 'Log In Failed.';
          }
          handleUserSigninResponse(user);
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch({
            type: 'SET_CURRENT_USER_DETAILS',
            payload: { username: formDataUsername },
          });
          dispatch({
            type: 'SET_TEMP_PASSWORD',
            payload: formDataPassword,
          });
          history.push('/newpassword');
        }
      })
      .catch((res) => {
        if (
          res.code === 'NotAuthorizedException' ||
          res.code === 'UserNotFoundException'
        ) {
          handleErrorResponse('Log In Failed.');
        } else {
          handleErrorResponse(res);
        }

        setFormSubmitting(false);
      });
  };

  return (
    <main>
      <LoginForm onSubmit={onSubmit} formSubmitting={formSubmitting} />
    </main>
  );
};
export default LoginView;
