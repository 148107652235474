import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useForm } from 'react-hook-form';
import { callAddUser } from '../../../api/kerb-construction';
import LoadingCircle from '../../../shared/LoadingCircle';
import { userTypeConfig } from './addUserType.config';
import FormErrorMessage from '../../../shared/FormErrorMessage';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import FormFieldAnimationContainer from '../../../containers/FormFieldAnimationContainer';

const generator = require('generate-password');

const UserDetailsForm = () => {
  const [formValidated, setFormValidated] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const addUserStore = useSelector((state) => state.addUserStore);
  const viewStore = useSelector((state) => state.viewStore);
  const dispatch = useDispatch();
  const { newUserType } = addUserStore;
  const { register, handleSubmit, errors } = useForm({
    mode: 'onTouched',
  });
  const selectedUserType = userTypeConfig.filter((userType) => {
    return userType.group === newUserType;
  })[0];
  const handleErrorResponse = useHandleErrorResponse();
  const { subRouteProgress } = viewStore;

  const onSubmit = (newUserData) => {
    setFormSubmitting(true);
    const userName = `${newUserData.firstName} ${newUserData.lastName}`;
    const userType = selectedUserType.group;
    const generatedPasswordString = generator.generate({
      length: 11,
      numbers: true,
      uppercase: true,
      lowercase: true,
      symbols: false,
      strict: true,
    });
    const temporaryPassword = `${generatedPasswordString}!`;
    const number = newUserData.number
      ? `${newUserData.countryCode}${newUserData.number}`
      : null;
    const { email } = newUserData;

    callAddUser(userName, userType, temporaryPassword, email, number)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then((data) => {
        dispatch({
          type: 'CLEAR_ALERT',
        });
        dispatch({
          type: 'SET_NEW_USER_DETAILS',
          payload: data,
        });
        dispatch({
          type: 'SET_SUB_ROUTE_PROGRESS',
          payload: selectedUserType.addToSite
            ? subRouteProgress + 1
            : subRouteProgress + 2,
        });

        setFormSubmitting(false);
      })
      .catch((err) => {
        handleErrorResponse(err);
        setFormSubmitting(false);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const errorsLength = Object.keys(errors).length;
    if (errorsLength === 0) {
      setFormValidated(true);
    } else if (errorsLength > 0) setFormValidated(false);
  });

  return (
    <form
      className="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
    >
      <div className="form__form-inner">
        <FormFieldAnimationContainer>
          <Typography variant="h5" component="h4" gutterBottom>
            Please enter the details of the user you would like to add to your
            organisation
          </Typography>

          <button
            type="button"
            className="button --square-image-button --selected"
          >
            {selectedUserType.title}
            <img src={selectedUserType.img} alt={selectedUserType.title} />
          </button>
          <div>
            {selectedUserType.fields.map((field) => {
              return (
                <div key={field.props.name}>
                  {React.createElement(field.component, {
                    key: field.props.name,
                    inputRef: register({ ...field.inputRefConfig }),
                    ...field.props,
                  })}

                  <FormErrorMessage errors={errors[field.props.name]} />
                </div>
              );
            })}
          </div>

          {formSubmitting ? (
            <LoadingCircle />
          ) : (
            <Button
              disabled={!formValidated}
              type="submit"
              variant="contained"
              color="primary"
            >
              Add User
            </Button>
          )}
        </FormFieldAnimationContainer>
      </div>
    </form>
  );
};

export default UserDetailsForm;
