import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { callPostBookingNote } from '../../../api/kerb-construction';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import ClickWaitButton from '../../../shared/ClickWaitButton';
import useIsMobileDevice from '../../../hooks/useIsMobileDevice';
import AddIcon from '../../../images/Components/AddIcon';

const BookingNoteForm = ({
  bookingID,
  sendingNote,
  setSendingNote,
  incrementReloadTrigger,
}) => {
  const [note, setNote] = useState('');

  const isFormValid = note.length > 0;

  const isMobileDevice = useIsMobileDevice();

  const buttonContents = () => {
    return isMobileDevice ? (
      <AddIcon className="assign-booking-view__add-note-mobile-icon" />
    ) : (
      'Add Note'
    );
  };

  const onChange = (e) => {
    setNote(e.target.value);
  };

  const handleErrorResponse = useHandleErrorResponse();

  const onSubmit = () => {
    setSendingNote(true);
    callPostBookingNote(bookingID, note)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then(() => {
        setSendingNote(false);
        setNote('');
        incrementReloadTrigger();
      })
      .catch((err) => {
        setSendingNote(false);
        handleErrorResponse(err);
      });
  };

  return (
    <form className="assign-booking-view__notes-form" onSubmit={onSubmit}>
      <TextField
        className="assign-booking-view__notes-form-input"
        name="Note"
        label="Add a note"
        variant="outlined"
        value={note}
        onChange={onChange}
        size="small"
      />

      <ClickWaitButton
        type="submit"
        apiCalling={sendingNote}
        className="assign-booking-view__notes-form-button"
        clickFunction={onSubmit}
        disabled={!isFormValid}
      >
        {buttonContents()}
      </ClickWaitButton>
    </form>
  );
};
export default BookingNoteForm;
