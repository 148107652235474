import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import OrganisationNameForm from './forms/OrganisationNameForm';
import OrganisationAddressForm from './forms/OrganisationAddressForm';
import OrganisationEmailForm from './forms/OrganisationEmailForm';
import OrganisationConfirmForm from './forms/OrganisationConfirmForm';
import OrganisationNumberForm from './forms/OrganisationNumberForm';
import OrganisationForsForm from './forms/OrganisationForsForm';
import OrganisationSuccessForm from './forms/OrganisationSuccessForm';
import ProgressBar from './components/ProgressBar';
import { scrollToTop } from '../../utils/functions';
import SubRouteHandler from '../../router/SubRouteHandler';

const AddOrganisationView = () => {
  const viewStore = useSelector((state) => state.viewStore);
  const { subRouteProgress } = viewStore;
  const currentUserStore = useSelector((state) => state.currentUserStore);
  const { isHaulier } = currentUserStore;

  const addOrganisationProgressRoutes = isHaulier
    ? [
        { component: OrganisationNameForm },
        { component: OrganisationAddressForm },
        { component: OrganisationEmailForm },
        { component: OrganisationNumberForm },
        { component: OrganisationForsForm },
        { component: OrganisationConfirmForm },
        { component: OrganisationSuccessForm },
      ]
    : [
        { component: OrganisationNameForm },
        { component: OrganisationAddressForm },
        { component: OrganisationEmailForm },
        { component: OrganisationNumberForm },
        { component: OrganisationConfirmForm },
        { component: OrganisationSuccessForm },
      ];

  const progressBarLength = addOrganisationProgressRoutes.length - 2;

  useEffect(() => {
    scrollToTop();
  }, [subRouteProgress]);

  return (
    <main className="add-organisation-view">
      <ProgressBar
        level={subRouteProgress}
        progressBarLength={progressBarLength}
      />
      <SubRouteHandler subroutes={addOrganisationProgressRoutes} />
    </main>
  );
};

export default AddOrganisationView;
