import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callGetUsersByGroup } from '../../../api/kerb-construction';
import useHandleErrorResponse from '../../../hooks/useHandleErrorResponse';
import UserTable from '../Tables/UserTable';

const ManageUsersContainer = () => {
  const addUserStore = useSelector((state) => state.addUserStore);
  const handleErrorResponse = useHandleErrorResponse();
  const userType = addUserStore.newUserType;

  const [users, setUsers] = useState([]);
  const [usersLoaded, setUsersLoaded] = useState(false);

  const loadUsers = () => {
    setUsersLoaded(false);

    callGetUsersByGroup(userType)
      .then((response) => {
        if (!response.ok) {
          throw response.json();
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data);
        setUsersLoaded(true);
      })
      .catch((err) => {
        handleErrorResponse(err);
        setUsersLoaded(true);
      });
  };

  const manageUserData = {
    userType,
    userData: users,
    usersLoaded,
    loadUsers,
  };

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="manage-users">
      <div className="container">
        <UserTable manageUserData={manageUserData} />
      </div>
    </div>
  );
};

export default ManageUsersContainer;
