import { format, isBefore } from 'date-fns';
import parseISO from 'date-fns/parseISO';

export function getEndOfDayTimeStampFromTimeString(timeString) {
  timeString.setUTCHours(0, 0, 0, 0);
  return Date.parse(timeString) / 1000;
}
export function getHHMMSSFormattedDateFromTimeString(timeString) {
  // will return format such as: "16:20:12"
  const formattedTimeString = new Date(timeString).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return formattedTimeString.replace(/GMT.*/g, '');
}

export function getHHMMFormattedDateFromTimeString(timeString) {
  // will return format such as: "16:20:12"
  const formattedTimeString = new Date(timeString).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  return formattedTimeString.replace(/GMT.*/g, '');
}

export function getBookingSlotStringFromDueStartAndDueEnd(dueStart, dueEnd) {
  // will return format such as:  "10:00 - 12:00"
  const formattedDueStart = parseISO(dueStart);
  const formattedDueEnd = parseISO(dueEnd);

  return `${format(formattedDueStart, 'HH:mm')} - ${format(
    formattedDueEnd,
    'HH:mm'
  )}`;
}

export function getYYYYmmDDHHMMFormattedDateFromTimeString(timeString) {
  // will return format such as:  "20-08-26 16:20"
  const parsedIsoTimeString = parseISO(timeString);
  return format(parsedIsoTimeString, 'yyyy-MM-dd HH:mm');
}

export const isBookingLate = (dueEnd) => {
  const currentDate = new Date();
  const endDate = parseISO(dueEnd);
  return isBefore(endDate, currentDate);
};
